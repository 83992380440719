import {Injectable} from '@angular/core';
import {StdApiService} from './std-api-service';
import {APP_MODE, LoginService} from '../login/login.service';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {LoggerService} from '../core/logger.service';
import 'firebase/storage';
import {AppUser} from '../model/AppUser';
import {Event, ICountryStatisticsMap} from '../model/Event';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  filter,
  first,
  firstValueFrom,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  take,
  tap
} from 'rxjs';
import {SectionContent, SectionUser} from '../model/content/SectionContent';
import {UtilsService} from '../core/utils.service';
import {
  Action,
  AngularFirestore,
  DocumentChange,
  DocumentChangeAction,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryFn,
  QuerySnapshot,
  SetOptions
} from '@angular/fire/compat/firestore';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {
  BANNER_TYPE,
  Constants,
  DIRECTION_COPY_CONTENT,
  FOLLOW_ME_MODE,
  FOLLOW_ME_USER_PARAMS,
  ISectionUserMeetingState,
  IUserMediaDevicesState,
  SECTION_EVENT_PHASE,
  TEMPLATE_TYPE,
  TIMELINE_MODE,
  USER_TASK_FIELDS
} from '../core/constants';
import {HttpClient} from '@angular/common/http';
import {WelcomeScreen} from '../model/content/WelcomeScreen';
import {IQuestionnaireContentMap, QuestionnaireContent} from '../model/content/QuestionnaireContent';
import {RegistrationSettings} from '../model/event-mode/RegistrationSettings';
import {ConferenceUser, ConferenceUserRole} from '../model/event-mode/ConferenceUser';
import {InstantSettings, VirtualConferenceSettings} from '../model/event-mode/InstantSettings';
import {ModularContent} from '../model/content/ModularContent';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AbstractContent, CONTENT_PATH_TYPE, IContentEntityLink} from '../model/content/AbstractContent';
import {Notes} from '../model/event-mode/RowDatesObject';
import {cloneDeep, isEmpty, merge, union, pick, uniqBy} from 'lodash';
import {ITaskDocumentSpeaker, UserTaskDocumentObject} from '../model/content/TaskDocument';
import {ChatMessage} from '../model/content/ChatMessage';
import firebase from 'firebase/compat/app';
import {CommonService} from '../core/common.service';
import {
  IFollowMeAction,
  IFollowMeCommand,
  IFollowMeConferenceGrid,
  IFollowMeMeetingSpeaker,
  IFollowMeMeetingViewMode,
  IFollowMeMeetingViewModeMap,
  IFollowMePoolAction,
  IFollowMeQAAction,
  IFollowMeScrollTo,
  IFollowMeZoomTo
} from './follow-me.service';
import {PINS_MODE} from '../model/content/SocialSettings';
import {IcsTemplateApiService} from './ics-template-api.service';
import {MailTemplateApiService} from './mail-template-api.service';
import {UserApiService} from './user-api.service';
import {IFeedbackValue, ISectionFeedbackUserValue} from './feedback.service';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {ConferenceRoomInvite} from '../event-mode/event-mode/conference/models/conference-participant';
import {QAMessage} from '../model/content/QAMessage';
import {IRoom} from '../modules/meeting-frame/services/daily-co.constants';
import {WhoCanHear} from '../model/content/meetingSettings';
import {
  CONTAINER_ITEM_TYPE,
  CONTENT_CONTAINER_COLLECTIONS,
  ContentContainer,
  ContentContainerItem
} from '../model/content/ContentContainer';
import {ANONYMOUS_ANSWERS_MODE, ExtQuiz, Quiz} from '../modules/content-container/components/quiz/quiz-model/quiz';
import {ITEM_MARKER, IURLToBASE64Scheme, TUser} from '../modules/content-container/shared/container-interface';
import {QueueModel} from '../model/content/queue-model';
import {ITaskFile, UserTaskObject} from '../modules/content-container/components/task/task-model/task';
import {IRegistrationQuestionnaireAnswers} from '../model/EventQuestion';
import {IUpdateChangesParams} from './content-version-control.service';
import {RecordingStartRequest, UserAvatar} from '../model/recording';
import {IQuizTemplate, SUMMARY_SAVE_TYPE} from '../modules/content-container/components/quiz/quiz-components/shared/quiz-quiestion-types';
import {DRM_MODE} from '../core/drm.service';
import FieldValue = firebase.firestore.FieldValue;
import FieldPath = firebase.firestore.FieldPath;
import {StorageDataService} from './storage-data.service';

interface IWriteCache {
  ref: DocumentReference;
  obj: any;
  options?: SetOptions;
  action?: 'set' | 'delete';
}

interface ICurrentAction {
  current_action: {
    projector?: {
      [timelineId: string]: any
    },
    projector_action?: {
      [contentId: string]: any
    },
    [key: string]: any
  };
}

export interface IInvitedUser {
  userId: string;
  email: string;
  fullName: string;
  userName?: string;
  picture?: string;
  assistantId?: string;
}

export interface IInviteUserEmail {
  email: string;
  roles?: ConferenceUserRole[];
}

export enum PLACE_TYPE {
  PICTURE = 'picture',
  TEXT = 'text',
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  MODULE_TITLE = 'module-title',
  MODULE_SUBTITLE = 'module-subtitle'
}

export interface ISVGMetadata {
  userId: string;
  userName: string;
  ownerId: string;
  moduleId: string;
  svgId: string;
  orderIndex: number;
  picture?: string;
  pinXY?: string;
  pinMode: PINS_MODE;
  reference: string;
  pinText?: string;
  pinNumber?: number;
  placeType: PLACE_TYPE;
}

export enum REF_METADATA {
  SVG = 'svg_metadata',
  SVG_PERSONAL = 'svg_metadata_personal',
  PIN = 'pin_metadata',
  PIN_PERSONAL = 'pin_metadata_personal'
}

export interface IPinData {
  pinMode: PINS_MODE;
  reference: REF_METADATA;
  userId?: string;
  userName?: string;
  pinText?: string;
  pinNumber?: number;
  id?: string;
}

export interface ISectionUpdateTask {
  sectionId: string;
  fieldName: string;
  value: any;
}

export interface IPlayBackAction {
  state: string;
  time: number;
  sectionId: string;
  contentId: string;
  moduleId: string;
}

export interface IDocumentPathParams {
  eventId: string;
  sectionId: string;
  contentId: string;
  containerId: string;
}

export interface IExtDocumentPathParams extends IDocumentPathParams {
  clientId: String;
  location: string;
  dirty: boolean;
}

export interface IRelocateParams {
  eventId: string;
  parentId: string;
  orderIndex: number;
}

export interface IStorageCollectionPath {
  collectionName: string;
  storageDataFiledName: string;
  pathSuffix: string;
  pathDataFieldNameSuffix: string;
}

export interface IQuizConstraint {
  [quizId: string]: {
    [questionId: string]: {
      contents: {
        [contentId: string]: boolean
      }
    }
  };
}

export interface ICopyTask {
  contentId: string;
  parentId: string;
  eventId: string;
  itemsIdList: string[];
}

export interface IExtCopyTask extends ICopyTask {
  contentTitle: string;
  educationMode: boolean;
  shiftToTop: boolean;
  orderIndex: number;
  dirty: boolean;
}

export enum CHANGE_LOG_VALUE_TYPE {
  ADD = 'add',
  DELETE = 'delete',
  RELOCATE = 'relocate',
  EDITED = 'edited'
}

export interface IChangeLogValue {
  sourceId: string;
  sourceType: string;
  changeType: CHANGE_LOG_VALUE_TYPE;
  title: string;
  parentId?: string;
  sourceIsDraft?: boolean;
  draftWithoutOriginal?: boolean;
}

@Injectable()
export class EventModeApiService extends StdApiService {
  protected API_URL: string;
  private connectionState = [];
  private _onDisconnectSet = false;
  private _maxTimelineSectionsOrderIndex = new BehaviorSubject<number>(1);
  public moveTypeFinished$ = new Subject();

  constructor(private http: HttpClient,
              private afDB: AngularFireDatabase,
              protected aFirestore: AngularFirestore,
              protected storage: AngularFireStorage,
              private aff: AngularFireFunctions,
              protected store: Store<fromRoot.State>,
              protected logger: LoggerService,
              protected auth: LoginService,
              protected common: CommonService,
              private icsTemplateApiService: IcsTemplateApiService,
              private mailTemplateApiService: MailTemplateApiService,
              protected utils: UtilsService,
              private userApiService: UserApiService,
              private loginService: LoginService,
              private storageDataService: StorageDataService
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/eventModeApi/v3/';
  }

  getDBPath() {
    switch (this.loginService.applicationMode) {
      case APP_MODE.TIMELINE:
        return Event.DB_TIMELINE_PATH;
      case APP_MODE.EDUCATION:
        return Event.DB_MODULE_TIMELINE_PATH;
      case APP_MODE.NOTES:
        return Event.DB_NOTES_PATH;
      case APP_MODE.QUESTION_CARDS:
        return Event.DB_QUESTION_CARDS;
    }
  }

  getSectionsDBPath() {
    switch (this.loginService.applicationMode) {
      case APP_MODE.TIMELINE:
      case APP_MODE.EDUCATION:
        return SectionContent.DB_PATH;
      case APP_MODE.NOTES:
        return SectionContent.DB_NOTES_PATH;
    }
  }

  getContentPath(content: AbstractContent, saveAsDraft: boolean) {
    if (!content.eventId) {
      throw Error('event id cannot be null');
    }
    if (!content.parentId) {
      return this.afs.collection(this.getDBPath())
        .doc(content.eventId)
        .collection(!saveAsDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT);
    } else {
      return this.afs.collection(this.getDBPath())
        .doc(content.eventId)
        .collection(this.getSectionsDBPath())
        .doc(content.parentId)
        .collection(!saveAsDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT);
    }
  }

  setMaxTimelineSectionsOrderIndex(value: number) {
    if (!!value && value > this._maxTimelineSectionsOrderIndex.getValue()) {
      this._maxTimelineSectionsOrderIndex.next(value);
    }
  }

  generateNewDocumentId() {
    return this.aFirestore.createId();
  }

  getInstantSettings(eventId: string): Observable<InstantSettings> {
    return this.afs
      .collection('instant_settings')
      .doc<InstantSettings>(eventId)
      .valueChanges()
      .pipe(
        this.log('getInstantSettings'),
        map(res => this.mapObject(res, (it) => new InstantSettings(it))),
        catchError(err => this.firestoreCatchError(err, 'getInstantSettings'))
      );
  }

  getInstantSettingsPromise(eventId: string): Promise<InstantSettings> {
    return firstValueFrom(this.afs
      .collection('instant_settings')
      .doc<InstantSettings>(eventId)
      .get()
      .pipe(
        this.log('getInstantSettingsPromise'),
        map((res: DocumentSnapshot<InstantSettings>) => this.mapObject(res.data(), (it) => new InstantSettings(it))),
        catchError(err => this.firestoreCatchError(err, 'getInstantSettingsPromise'))
      ));
  }

  getUsersOnline(eventId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_online')
      .stateChanges(['added', 'modified', 'removed'])
      .pipe(
        this.log('getUsersOnline'),
        catchError(err => this.firestoreCatchError(err, 'getUsersOnline'))
      );
  }

  getUsersOnlineChange(eventId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_online')
      .valueChanges()
      .pipe(
        this.log('getUsersOnlineChange'),
        catchError(err => this.firestoreCatchError(err, 'getUsersOnlineChange'))
      );
  }

  getUsersOnlinePromise(eventId: string): Promise<ConferenceUser[]> {
    const pathOrRef = `conference/${eventId}/users_online`;
    return firstValueFrom(this.afs.collection<ConferenceUser>(pathOrRef)
      .get()
      .pipe(
        this.log('getUsersOnlinePromise'),
        map((res: QuerySnapshot<ConferenceUser>) => this.mapArray(res.docs, (it) => new ConferenceUser({userId: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getUsersOnlinePromise'))
      ));
  }

  getEventUsersRolePromise(eventId: string, userRole: ConferenceUserRole): Promise<ConferenceUser[]> {
    const pathOrRef = `conference/${eventId}/users_online`;
    return firstValueFrom(this.afs.collection<ConferenceUser>(pathOrRef, ref =>
      ref.where('roles', 'array-contains', userRole))
      .get()
      .pipe(
        this.log('getEventUsersRolePromise'),
        map((res: QuerySnapshot<ConferenceUser>) => this.mapArray(res.docs, (it) => new ConferenceUser({userId: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getEventUsersRolePromise'))
      ));
  }

  isViewerUserPromise(eventId: string, email: string): Promise<ConferenceUser> {
    const pathOrRef = `conference/${eventId}/users_online`;
    return firstValueFrom(this.afs.collection<ConferenceUser>(pathOrRef, ref =>
      ref.where('roles', 'array-contains', 'viewer')
        .where('email', '==', email).limit(1))
      .get()
      .pipe(
        this.log('isViewerUserPromise'),
        map((res: QuerySnapshot<ConferenceUser>) => this.mapObject(res.docs[0], (it) => new ConferenceUser({userId: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'isViewerUserPromise'))
      ));
  }

  getUserOnlineByUserId(eventId: string, userId: string): Promise<ConferenceUser> {
    const pathOrRef = `conference/${eventId}/users_online`;
    return firstValueFrom(this.afs.collection<ConferenceUser>(pathOrRef)
      .doc(userId)
      .get()
      .pipe(
        this.log('getUserOnlineByUserId'),
        map((res: DocumentSnapshot<ConferenceUser>) => new ConferenceUser({userId: res.id, ...res.data()})),
        catchError(err => this.firestoreCatchError(err, 'getUserOnlineByUserId'))
      ));
  }

  getUsersOnlineDevices(eventId: string): Observable<any[]> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_online_devices')
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getUsersOnlineDevices'),
        tap((res: any[]) => res),
        catchError(err => this.firestoreCatchError(err, 'getUsersOnlineDevices'))
      );
  }

  loadUsersOnlineShort(eventId: string): Observable<any> {
    const currentTime = new Date().getTime() - 300000;
    const client_id = this.auth.client_id$.getValue();
    const pathOrRef = `client_data/${client_id}/conference/${eventId}/users/short_ping`;
    return this.afDB.list(pathOrRef, ref => ref.orderByChild('pingTime').startAt(currentTime))
      .stateChanges(['child_added', 'child_changed', 'child_removed']);
  }

  loadUserInformation(eventId: string, userId: string, takeOne = false): Observable<ConferenceUser> {
    if (takeOne) {
      return this.afs
        .collection('conference')
        .doc(eventId)
        .collection('users_online')
        .doc(userId)
        .get()
        .pipe(
          this.log('loadUserInformation'),
          map(res => this.mapObject(res, it => {
            return !isEmpty(it.data()) ? new ConferenceUser({userId: it.id, ...it.data()}) : null;
          })),
        );
    } else {
      return this.afs
        .collection('conference')
        .doc(eventId)
        .collection('users_online')
        .doc(userId)
        .valueChanges({idField: 'userId'})
        .pipe(
          this.log('loadUserInformation'),
          map(res => this.mapObject(res, it => {
            return !isEmpty(it) ? new ConferenceUser(it) : null;
          })),
          catchError(err => this.firestoreCatchError(err, 'loadUserInformation'))
        );
    }
  }

  getWelcomeScreen(eventId: string): Observable<WelcomeScreen> {
    return this.afs
      .collection('welcome_screen')
      .doc<WelcomeScreen>(eventId)
      .valueChanges()
      .pipe(
        this.log('getWelcomeScreen'),
        map(res => this.mapObject(res, (it) => new WelcomeScreen(it))),
        catchError(err => this.firestoreCatchError(err, 'getWelcomeScreen'))
      );
  }

  getWelcomeScreenPromise(eventId: string): Promise<WelcomeScreen> {
    return firstValueFrom(this.afs
      .collection('welcome_screen')
      .doc<WelcomeScreen>(eventId)
      .get()
      .pipe(
        this.log('getWelcomeScreenPromise'),
        map((res: DocumentSnapshot<WelcomeScreen>) => this.mapObject(res.data(), (it) => new WelcomeScreen(it))),
        catchError(err => this.firestoreCatchError(err, 'getWelcomeScreenPromise'))
      ));
  }

  getEventAdditional(eventId: string): Promise<any> {
    return firstValueFrom(this.afs.collection('event_additional')
      .doc(eventId)
      .get()
      .pipe(
        this.log('getEventAdditional'),
        map((res: DocumentSnapshot<any>) => res.data()),
        catchError(err => this.firestoreCatchError(err, 'getEventAdditional'))
      ));
  }

  checkEventPresenter(eventId: string, userId: string): any {
    return firstValueFrom(this.afs.collection('event_presenters')
      .doc(eventId)
      .get()
      .pipe(
        this.log('checkEventPresenter'),
        map((res: DocumentSnapshot<any>) => res.data() ? res.data()[userId] : null),
        catchError(err => this.firestoreCatchError(err, 'checkEventPresenter'))
      ));
  }

  checkPastEventPromise(eventId: string, userId: string): Promise<any> {
    return firstValueFrom(this.afs.collection('user_events')
      .doc(userId)
      .get()
      .pipe(
        this.log('checkPastEventPromise'),
        map((res: DocumentSnapshot<any>) => res.data() ? res.data()[eventId] : null),
        catchError(err => this.firestoreCatchError(err, 'checkPastEventPromise'))
      ));
  }

  // todo need remove listener from here
  listenConnectionState(eventId: string, currentUser: AppUser) {
    const userId = currentUser.userId;
    const userMail = currentUser.email;
    const deviceId = currentUser.userKey;
    const client_id = this.auth.client_id$.getValue();
    const userMailRef = this.afDB.database.ref(`client_data/${client_id}/conference/${eventId}/users/online/${userId}/email`);
    const deviceRef = this.afDB.database
      .ref(`client_data/${client_id}/conference/${eventId}/users/online_devices/${userId}/devices/${deviceId}`);
    this.connectionState.push({ref: deviceRef});
    const connectedRef = this.afDB.database.ref('.info/connected');
    // todo need refactoring after we make user list in firebase (we can move set email action into FCF)
    const vm = this;
    connectedRef.on('value', function(snap) {
      if (snap.val()) {
        const connectInfo = {platform: window.navigator['platform'], userAgent: window.navigator['userAgent'], userOnline: true};
        const disconnectInfo = {platform: window.navigator['platform'], userAgent: window.navigator['userAgent'], userOnline: false};
        if (vm._onDisconnectSet) {
          deviceRef.set(connectInfo);
          userMailRef.set(userMail);
        }
        deviceRef.onDisconnect().set(disconnectInfo);
        userMailRef.onDisconnect().set(userMail);
        vm._onDisconnectSet = true;
      }
    });
  }

  offListenConnectionState() {
    const vm = this;
    this.connectionState.forEach(function (storeObj) {
      // console.log('off listeners', storeObj.ref.path);
      vm.afDB.database.ref(storeObj.ref.path).off();
      storeObj.ref.onDisconnect().cancel();
    });
    this.connectionState = [];
    this._onDisconnectSet = false;
  }

  deleteImage(eventId: string, timelineId: string): any {
    return this.deleteObjectFromStorage(eventId, `${this.getDBPath()}/${eventId}/clipboard/${timelineId}.png`);
  }

  uploadQuestionImage(eventId: string, timelineId: string, qiestionId: string, base64: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/question/${timelineId}/${qiestionId}.png`);
    const b64Ind = base64.indexOf(';base64,');
    base64 = base64.substring(b64Ind + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: 'image/png'});
  }

  deleteQuestionImage(eventId: string, timelineId: string, qiestionId: string): any {
    return this.deleteObjectFromStorage(eventId, `${this.getDBPath()}/${eventId}/question/${timelineId}/${qiestionId}.png`);
  }

  deleteQuestionWordCloudTemplate(eventId: string, timelineId: string, qiestionId: string): any {
    return this.deleteObjectFromStorage(eventId, `${this.getDBPath()}/${eventId}/question/${timelineId}/word_cloud_template/${qiestionId}.png`);
  }

  /**
   * Use for check exist image in FB storage. If exist then return Promise else throw exception.
   * @param {number} eventId
   * @param {number} timelineId
   * @param {string} qiestionId
   * @returns {any}
   */
  getQuestionDownloadURL(eventId: string, timelineId: string, qiestionId: string): Promise<any> {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/question/${timelineId}/${qiestionId}.png`);
    return reference.getDownloadURL();
  }

  getQuestionTemplateDownloadURL(eventId: string, timelineId: string, questionId: string): any {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/question/${timelineId}/word_cloud_template/${questionId}.png`);
    return reference.getDownloadURL();
  }

  getModuleImageDownloadURL(eventId: string, timelineId: string, imageId: string): Promise<any> {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/image/${timelineId}/${imageId}.png`);
    return reference.getDownloadURL();
  }

  getModulePDFDownloadURL(eventId: string, timelineId: string, imageId: string): Promise<any> {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/pdf/${timelineId}/${imageId}.pdf`);
    return reference.getDownloadURL();
  }

  getModuleZIFDownloadURL(eventId: string, timelineId: string, imageId: string): Promise<any> {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/zif/${timelineId}/${imageId}.zif`);
    return reference.getDownloadURL();
  }

  getTaskDocumentPresenterDownloadURL(eventId: number, timelineId: number, objectName: string): Promise<any> {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/content/${timelineId}/presenter/${objectName}`);
    return reference.getDownloadURL();
  }

  uploadEventLogo(eventId: string, base64: string, clientId?: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/logo/${eventId}.png`, clientId);
    const b64Ind = base64.indexOf(';base64,');
    base64 = base64.substring(b64Ind + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: 'image/png'});
  }

  uploadEventBanner(eventId: number, base64: string, bannerType: BANNER_TYPE, clientId?: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/${bannerType}/${bannerType}.png`, clientId);
    const b64Ind = base64.indexOf(';base64,');
    base64 = base64.substring(b64Ind + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: 'image/png'});
  }

  updateEventLogoUrl(eventId: string, logoUrl: string, clientId?: string) {
    const obj = {logo: logoUrl};
    return this.getAfs(clientId).collection(Event.DB_PATH)
      .doc(eventId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  updateEventVerticalBanner(eventId: string, url: string, clientId?: string) {
    const obj = {
      verticalBanner: url ? url : FieldValue.delete()
    };
    return this.getAfs(clientId).collection('events')
      .doc(eventId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  updateEventBackground(eventId: string, url: string, clientId?: string) {
    const obj = {
      background: url ? url : FieldValue.delete()
    };
    return this.getAfs(clientId).collection('events')
      .doc(eventId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  updateEventMobileBackground(eventId: string, url: string, clientId?: string) {
    const obj = {
      mobileBackground: url ? url : FieldValue.delete()
    };
    return this.getAfs(clientId).collection('events')
      .doc(eventId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  updateEventHorizontalBanner(eventId: string, url: string, clientId?: string) {
    const obj = {
      horizontalBanner: url ? url : FieldValue.delete()
    };
    return this.getAfs(clientId).collection('events')
      .doc(eventId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  deleteEventLogo(eventId: string): any {
    return this.deleteObjectFromStorage(eventId, `${this.getDBPath()}/${eventId}/logo/${eventId}.png`);
  }

  deleteEventBanner(eventId: string, bannerType: BANNER_TYPE): any {
    return this.deleteObjectFromStorage(eventId, `${this.getDBPath()}/${eventId}/${bannerType}/${bannerType}.png`);
  }

  uploadWelcomeScreen(eventId: string, base64: string, mobile: boolean) {
    const path = mobile ? `${this.getDBPath()}/${eventId}/welcome_screen/${eventId}M.png` : `${this.getDBPath()}/${eventId}/welcome_screen/${eventId}.png`;
    const reference = this.getStorageRef(path);
    const b64Ind = base64.indexOf(';base64,');
    base64 = base64.substring(b64Ind + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: 'image/png'});
  }

  deleteWelcomeScreen(eventId: string, mobile: boolean): any {
    const path = mobile ? `${this.getDBPath()}/${eventId}/welcome_screen/${eventId}M.png` : `${this.getDBPath()}/${eventId}/welcome_screen/${eventId}.png`;
    return this.deleteObjectFromStorage(eventId, path);
  }
  /**
   * Use for check exist image in FB storage. If exist then return Promise else throw exception.
   * @param {number} eventId
   * @returns {any}
   */
  getEventDownloadURL(eventId: string): any {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/logo/${eventId}.png`);
    return reference.getDownloadURL();
  }

  getEventBannerDownloadURL(eventId: number, bannerType: BANNER_TYPE): any {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/${bannerType}/${bannerType}.png`);
    return reference.getDownloadURL();
  }

  /**
   * Use for check exist image in FB storage. If exist then return Promise else throw exception.
   * @param {number} eventId
   * @param {number} timelineId
   * @returns {any}
   */
  getClipboardDownloadURL(eventId: string, timelineId: string): any {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/clipboard/${timelineId}.png`);
    return reference.getDownloadURL();
  }

  /**
   * Use for check exist image in FB storage. If exist then return Promise else throw exception.
   * @param {number} eventId
   * @param mobile - prefix "M" for mobile file name.
   * @returns {any}
   */
  getWelcomeScreenDownloadURL(eventId: string, mobile: boolean): any {
    const path = mobile ? `${this.getDBPath()}/${eventId}/welcome_screen/${eventId}M.png` : `${this.getDBPath()}/${eventId}/welcome_screen/${eventId}.png`;
    const reference = this.getStorageRef(path);
    return reference.getDownloadURL();
  }

  getWordCloudTemplateURL(path: string): any {
    const reference = this.getStorageRef(`word_cloud_templates/${path}`);
    return reference.getDownloadURL();
  }

  updateUserOnlineStatus(eventId: string, oldLink: string, userKey: string, online: boolean, connectionInf: any) {
    const uid = this.auth.getAuthUser().uid;
    const obj = {devices: {}};
    obj.devices[userKey] = connectionInf;
    obj.devices[userKey].userOnline = online;

    return this.aFirestore.firestore.runTransaction(transaction => {
      const eventRef = this.afs.collection('conference')
        .doc(eventId)
        .collection('users_online_devices')
        .doc(uid);
        transaction.set(eventRef.ref, obj, {merge: true});

        if (oldLink) {
          firstValueFrom(this.afs.collection('conference', ref =>
            ref.where('shortLink', '==', oldLink)
              .where('deleted', '==', 0)
              .limit(1))
            .get()
            .pipe(catchError(err => this.firestoreCatchError(err, 'updateUserOnlineStatus'))))
            .then((snapshot) => {
              const old = snapshot.docs.length ? snapshot.docs[0].data() : null;
              if (old) {
                const oldRef = this.afs.collection('conference')
                  .doc(old.eventId)
                  .collection('users_online_devices')
                  .doc(uid);
                obj.devices[userKey].userOnline = false;
                transaction.set(oldRef.ref, obj, {merge: true});
              }
            });
        }

      return Promise.resolve(eventId);
    });
  }

  sendUserOnlinePing(eventId: number, userId: string, userKey: string) {
    const client_id = this.auth.client_id$.getValue();
    const pathOrRef = `client_data/${client_id}/conference/${eventId}/users`;
    const obj = {};
    const time = (new Date()).getTime();
    obj[`ping/${userId}/${userKey}`] = time;
    obj[`short_ping/${userId}/pingTime`] = time;
    this.afDB.object(pathOrRef).update(obj);
  }

  loadUsersOnlinePingList(eventId: string) {
    const client_id = this.auth.client_id$.getValue();
    const pathOrRef = `client_data/${client_id}/conference/${eventId}/users/ping`;
    return firstValueFrom(this.afDB.object(pathOrRef).valueChanges());
  }

  loadUsersOnlinePing(eventId: string) {
    return new Observable(observer => {
      const client_id = this.auth.client_id$.getValue();
      const pathOrRef = `client_data/${client_id}/conference/${eventId}/users/ping`;
      const userPingList = {};
      this.afDB.list(pathOrRef).stateChanges(['child_added', 'child_changed', 'child_removed']).subscribe(action => {
        const uId = action.payload.key;
        const devices = action.payload.val();
        if (action.type === 'child_added') {
          userPingList[uId] = devices;
        } else if (action.type === 'child_changed') {
          userPingList[uId] = devices;
        } else if (action.type === 'child_removed') {
          delete userPingList[uId];
        }
        observer.next(userPingList);
      });
    });
  }

  saveUserSection(eventId: string, timelineId: string, params: any): any {
    const save = (userId, userEmail?) => {
      const obj = {
        sections: {}
      };
      if (userEmail) {
        obj['email'] = userEmail;
      }
      if (params && typeof params.joinTime === 'number') {
        obj.sections[timelineId] = params.joinTime;
      } else {
        obj.sections[timelineId] = FieldValue.delete();
      }

      return this.afs.collection('conference')
        .doc(eventId)
        .collection('users_online')
        .doc(userId)
        .set(obj, {merge: true})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    };

    const email = params && params.email ? params.email : this.auth.getAuthUser().email;
    const uId = params && params.userId ? params.userId : null;
    return firstValueFrom(this.afs.collection('conference')
      .doc(eventId)
      .collection('users_online', ref => ref.where((uId ? 'userId' : 'email'), '==', (uId ? uId : email)))
      .get()
      .pipe(catchError(err => this.firestoreCatchError(err, 'saveUserSection'))))
      .then(snapshot => {
        const users = snapshot.docs;
        if (!isEmpty(users)) {
          let p = Promise.resolve(true);
          for (const doc of (users || [])) {
            const user = doc.data();
            const userId = user.userId ? user.userId : doc.id;
            p = p.then(() => save(userId));
          }
          return p;
        } else {
          return firstValueFrom(this.userApiService.getUserByEmail(email)).then((user) => {
            if (user) {
              return save(!params.userId ? user.userId : params.userId, email);
            } else {
              return Promise.resolve(true);
            }
          });
        }
      });
  }

  saveUserFollowMeSettings(eventId: string, param: FOLLOW_ME_USER_PARAMS, sectionId: string) {
    if (!sectionId) {
      const obj = !isEmpty(param) ? {followMeSettings: {followEvent: param}} :
        {followMeSettings: FieldValue.delete()};
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('users_online')
        .doc(this.auth.getAuthUser().uid)
        .set(obj, {merge: true})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      const obj = !isEmpty(param) ?
        {followMeSettings: {followSections: {[sectionId]: param}}} :
        {followMeSettings: {followSections: {[sectionId]: FieldValue.delete()}}};
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('users_online')
        .doc(this.auth.getAuthUser().uid)
        .set(obj, {merge: true})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }
  /**
   * Restrict user access to section
   */
  setSectionUser(eventId: string, sectionId: string, userId: string, value: boolean): Promise<any> {
    const queryStr = `?eventId=${eventId}&sectionId=${sectionId}&userId=${userId}&value=${value}`;
    return this.http.post(this.API_URL + 'saveSectionUser' + queryStr, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  allowReceiveICS(eventId: string, value: boolean): Promise<any> {
    const queryStr = `?eventId=${eventId}&value=${value}`;
    return this.http.post(this.API_URL + 'allowReceiveICS' + queryStr, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getUserData(eventId: string): Observable<any> {
    const userId = this.auth.getAuthUser().uid;
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('user_data')
      .doc(userId)
      .valueChanges()
      .pipe(
        this.log('getUserData'),
        catchError(err => this.firestoreCatchError(err, 'getUserData'))
      );
  }

  getUsersDataPromise(eventId: string): Promise<any[]> {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('user_data')
      .get()
      .pipe(
        this.log('getUsersDataPromise'),
        map((res: QuerySnapshot<any[]>) => this.mapArray(res.docs, (it) => new Object({userId: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getUsersDataPromise'))
      ));
  }

  getSectionUsersDataPromise(eventId: string, sectionId: string): Promise<any[]> {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('user_data', q => q.where('sections', 'array-contains', sectionId))
      .get()
      .pipe(
        this.log('getUsersOnlinePromise'),
        map((res: QuerySnapshot<any[]>) => this.mapArray(res.docs, (it) => new Object({userId: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getUsersOnlinePromise'))
      ));
  }

  clearUserDataBySection(eventId: string, sectionId: string) {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('user_data', q => q.where('sections', 'array-contains', sectionId))
      .get())
      .then(usersData => {
        const cache: IWriteCache[] = [];
        for (const doc of usersData.docs) {
          const ref = this.afs.collection(this.getDBPath())
            .doc(eventId)
            .collection('user_data')
            .doc(doc.id)
            .ref;
          const obj = {
            sections: FieldValue.arrayRemove(sectionId)
          };
          cache.push({ref: ref, obj: obj});
        }
        return this.commitCache(cache);
      });
  }

  joinInvitedToSection(eventId: string, timelineId: string, params: any): any {
    const email = params && params.email ? params.email : this.auth.getAuthUser().email;
    return firstValueFrom(this.afs.collection('conference')
      .doc(eventId)
      .collection('users_invited', ref => ref.where('email', '==', email))
      .get()
      .pipe(catchError(err => this.firestoreCatchError(err, 'joinInvitedToSection'))))
      .then(snapshot => {
        const users = snapshot.docs;
        for (const doc of (users || [])) {
          const user = doc.data();
          if (!user.userId) {
            break;
          }
          let obj = {sections: {}};
          if (params && typeof params.joinTime === 'number') {
            obj.sections[timelineId] = params.joinTime;
          } else {
            obj.sections[timelineId] = FieldValue.delete();
          }
          obj = merge(user, obj);
          this.afs.collection('conference')
            .doc(eventId)
            .collection('users_online')
            .doc(user.userId)
            .set(obj, {merge: true})
            .catch((err) => this.throwFirestoreError(err));
        }
      });
  }

  setFeatureLine(eventId: string, timelineId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('actions')
      .doc('featureline')
      .set({featureline: timelineId}, {merge: true})
      .then(() => Promise.resolve(true))
      .catch((err) => this.throwFirestoreError(err));
  }

  getFutureLine(eventId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('actions')
      .doc('featureline')
      .valueChanges()
      .pipe(
        this.log('getFutureLine'),
        map(res => res ? res['featureline'] : null),
        catchError(err => this.firestoreCatchError(err, 'getFutureLine'))
      );
  }

  getFutureLinePromise(eventId: string) {
    return firstValueFrom(this.afs.collection('conference')
      .doc(eventId)
      .collection('actions')
      .doc('featureline')
      .valueChanges()
      .pipe(
        this.log('getFutureLinePromise'),
        map(res => res ? res['featureline'] : null),
        catchError(err => this.firestoreCatchError(err, 'getFutureLinePromise'))
      ));
  }

  setFeatureLineChangeTimer(eventId: string, changeTimerValue?: number) {
    const obj: ICurrentAction = {
      current_action: {
      }
    };
    if (changeTimerValue) {
      obj.current_action['changeTimerValue'] = changeTimerValue;
    } else {
      obj.current_action['changeTimerValue'] = FieldValue.delete();
    }
    return this.afs.collection('conference')
      .doc(eventId)
      .set(obj, {merge: true})
      .then(() => Promise.resolve(true))
      .catch((err) => this.throwFirestoreError(err));
  }

  getFutureLineChangeTimer(eventId: string) {
    return this.afs.collection('conference')
      .doc<any>(eventId)
      .valueChanges()
      .pipe(
        this.log('getFutureLineChangeTimer'),
        map((res: any) => res && res.current_action ? res.current_action.changeTimerValue : null),
        catchError(err => this.firestoreCatchError(err, 'getFutureLineChangeTimer'))
      );
  }

  addSectionContent(eventId: string, section: SectionContent) {
    const currentTime = new Date().getTime();
    section.createTime = currentTime;
    section.updateTime = currentTime;
    if (!section.orderIndex) {
      section.orderIndex = this._maxTimelineSectionsOrderIndex.getValue() + 1000000;
      this.setMaxTimelineSectionsOrderIndex(section.orderIndex + 1000000);
    }
    if (!section.parentId && section.title === 'root') {
      section.orderIndex = 1;
    }
    section.eventId = eventId;
    section.userId = this.auth.getAuthUser().uid;
    section.creator = {
      userId: this.currentAppUser.userId,
      picture: !isEmpty(this.currentAppUser.picture) ? this.currentAppUser.picture : null,
      fullName: !isEmpty(this.currentAppUser.fullName) ? this.currentAppUser.fullName : null,
      department: !isEmpty(this.currentAppUser.department) ? this.currentAppUser.department : null
    };
    return this.aFirestore.firestore.runTransaction(transaction => {
      const id = !section.fixedSectionType ? this.aFirestore.createId() : section.id;
      if (section.id) {
        delete section['id'];
      }
      if (!id) {
        throw Error('Section id cannot be null');
      }
      const ref = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection<SectionContent>(this.getSectionsDBPath())
        .doc(id)
        .ref;
      return this.uploadDeleteSectionBackground(new SectionContent(merge(section, {id: id}))).then(async (content) => {
        transaction.set(ref, content.toObject(), {merge: true});
        const speakersRef = this.afs.collection('event_sections_speakers').doc(eventId).ref;
        const obj = {};
        if (content.users) {
          const users: SectionUser[] = content.users;
          for (const user of users) {
            if (user.speaker) {
              if (isEmpty(obj[id])) {
                obj[id] = {};
              }
              obj[id][user.userId] = true;
            }
          }
          transaction.set(speakersRef, obj, {merge: true});
        }
        await this.saveChangeLogValue(content.eventId, {
          sourceId: content.id,
          sourceType: Constants.CONTENT_TYPE_SECTION,
          changeType: CHANGE_LOG_VALUE_TYPE.ADD,
          title: content.title
        });
        return Promise.resolve(id);
      }).catch((err) => this.throwFirestoreError(err));
    });
  }

  addSimpleSectionContent(eventId: string, content: SectionContent): Promise<string> {
    const currentTime = new Date().getTime();
    content.createTime = currentTime;
    content.updateTime = currentTime;
    if (!content.orderIndex) {
      content.orderIndex = currentTime + 1000000;
    }
    if (!content.parentId && content.title === 'root') {
      content.orderIndex = 1;
    }
    const obj = content instanceof SectionContent ? content.toObject() : content;
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection<SectionContent>(this.getSectionsDBPath())
      .add(obj)
      .then(value => {
        return value.id;
      })
      .catch((err) => this.throwFirestoreError(err));
  }

  addAnyContent(eventId: string, content: AbstractContent, saveAsDraft?: boolean) {
    const setCreatorAndTime = () => {
      content.userId = this.auth.getAuthUser().uid;
      content.creator = {
        userId: this.currentAppUser.userId,
        picture: !isEmpty(this.currentAppUser.picture) ? this.currentAppUser.picture : null,
        fullName: !isEmpty(this.currentAppUser.fullName) ? this.currentAppUser.fullName : null,
        department: !isEmpty(this.currentAppUser.department) ? this.currentAppUser.department : null
      };
      content.createTime = currentTime;
    };

    const currentTime = new Date().getTime();
    // todo check this param. maybe need extract to parameter
    if (!content.anonymously) {
      if (this.loginService.educationMode) {
        if (!content.creator || (saveAsDraft && !content.id)) {
          setCreatorAndTime();
        }
      } else {
        setCreatorAndTime();
      }
    } else if (content.anonymously) {
      content.userId = UtilsService.md5u(this.loginService.getAppUser());
    }
    delete content.anonymously;
    content.eventId = eventId;
    content.updateTime = currentTime;

    if (!content.orderIndex) {
      content.orderIndex = currentTime + 1000000;
    }

    if (content.id) {
      return this.getContentPath(content, saveAsDraft)
        .doc(content.id)
        .set(content.toObject())
        .then(() => content.id)
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.getContentPath(content, saveAsDraft)
        .add(content.toObject())
        .then(value => value.id)
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  addBreakContent(eventId: string, content: any) {
    return this.addAnyContent(eventId, content);
  }

  addModularContent(eventId: string, content: ModularContent) {
    return this.addAnyContent(eventId, content);
  }

  updateEventInstantSettings(eventId: string, instantSettings: InstantSettings, clientId?: string): Promise<void> {
    return this.getAfs(clientId).collection(InstantSettings.DB_PATH)
      .doc(eventId)
      .set(instantSettings.toObject(), {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  setRoundTime5MinutesDisable(eventId: string, disabled: boolean): any {
    const obj = {
      manageTimeRound5Min: disabled
    };
    return this.afs.collection(InstantSettings.DB_PATH)
      .doc(eventId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  addTaskDocumentContent(eventId: string, content: any) {
    return this.addAnyContent(eventId, content);
  }

  getQuestionnaireAnswers(eventId: string, sectionId: string, timelineId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('contents')
      .doc(timelineId)
      .collection('answers')
      .valueChanges({idField: 'userId'})
      .pipe(
        this.log('getQuestionnaireAnswers'),
        catchError(err => this.firestoreCatchError(err, 'getQuestionnaireAnswers'))
      );
  }

  getQuestionnaireAnswersPromise(eventId: string, sectionId: string, timelineId: string) {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('contents')
      .doc(timelineId)
      .collection('answers')
      .get()
      .pipe(
        this.log('getQuestionnaireAnswersPromise'),
        map(res => this.mapArray(res, it => new Object({userId: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getQuestionnaireAnswersPromise'))
      ));
  }

  getQuestionnaireAnswersByCurrentUser(eventId: string, sectionId: string, contentId: string, questionnaireId: string) {
    if (!contentId || contentId === questionnaireId) {
      return this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(sectionId)
        .collection('contents')
        .doc(questionnaireId)
        .collection('answers')
        .doc(this.auth.getAuthUser().uid)
        .valueChanges()
        .pipe(
          this.log('getQuestionnaireAnswersByCurrentUser'),
          catchError(err => this.firestoreCatchError(err, 'getQuestionnaireAnswersByCurrentUser'))
        );
    } else if (contentId !== questionnaireId && contentId) {
      return this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(sectionId)
        .collection('contents')
        .doc(contentId)
        .collection(ContentContainer.DB_PATH)
        .doc(questionnaireId)
        .collection('answers')
        .doc(this.auth.getAuthUser().uid)
        .valueChanges({idField: 'userId'})
        .pipe(
          this.log('getQuestionnaireAnswersByCurrentUser'),
          catchError(err => this.firestoreCatchError(err, 'getQuestionnaireAnswersByCurrentUser'))
        );
    }
  }

  getQuestionnaireAnswersByUser(userId: string, eventId: string, sectionId: string,
                                timelineId: string, anonymousMode: boolean): Promise<any> {
    if (anonymousMode) {
      const queryParam = `?userId=${userId}&eventId=${eventId}&sectionId=${sectionId}&timelineId=${timelineId}`;
      return this.http.post(this.API_URL + 'getQuestionnaireAnswersByUser' + queryParam, null)
        .toPromise()
        .then((snapshot) => {
          return snapshot ? snapshot['value'] : null;
        })
        .catch((e) => this.catchServerError(e));
    } else {
      return firstValueFrom(this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(sectionId)
        .collection('contents')
        .doc(timelineId)
        .collection('answers')
        .doc(userId)
        .get()
        .pipe(
          this.log('getQuestionnaireAnswersByUser'),
          map(res => this.mapObject(res, it => new Object({userId: it.id, ...it.data()}))),
          catchError(err => this.firestoreCatchError(err, 'getQuestionnaireAnswersByUser'))
        ));
    }
  }

  sendAnswerQ(userKey: string, eventId: string, sectionId: string, timelineId: string, questionId: string,
              answer: string[], anonymousAnswers: boolean) {
    const userCode = anonymousAnswers ? userKey : this.auth.getAuthUser().uid;
    const answerJson = JSON.stringify(answer);
    let queryParam = `?userKey=${userCode}&eventId=${eventId}&sectionId=${sectionId}&timelineId=${timelineId}`;
        queryParam = queryParam + `&questionId=${questionId}&anonymousAnswers=${anonymousAnswers}`;
    const answerObj = {
      value: answerJson
    };
    return this.http.post(this.API_URL + 'sendAnswerQ' + queryParam, answerObj)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  clearDependentAnswersQ(userKey: string, eventId: string, sectionId: string, timelineId: string, questionsIdList: string[],
                         anonymousAnswers: boolean): Promise<any> {
    const userCode = anonymousAnswers ? userKey : this.auth.getAuthUser().uid;
    const questionsIdListJson = JSON.stringify(questionsIdList);
    let queryParam = `?userKey=${userCode}&eventId=${eventId}&sectionId=${sectionId}&timelineId=${timelineId}`;
        queryParam = queryParam + `&anonymousAnswers=${anonymousAnswers}`;
    const questionsIdObj = {
      value: questionsIdListJson
    };
    return this.http.post(this.API_URL + 'clearDependentAnswersQ' + queryParam, questionsIdObj)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getRegistrationQuestionnaireAnswers(eventId: string, sectionId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('registration_questionnaire')
      .doc(sectionId)
      .collection('answers')
      .valueChanges({idField: 'userId'})
      .pipe(
        this.log('getRegistrationQuestionnaireAnswers'),
        catchError(err => this.firestoreCatchError(err, 'getRegistrationQuestionnaireAnswers'))
      );
  }

  getRegistrationQuestionnaireAnswersByUser(userId: string, eventId: string, sectionId: string) {
    const queryParam = `?userId=${userId}&eventId=${eventId}&sectionId=${sectionId}`;
    return this.http.post(this.API_URL + 'getRegistrationQuestionnaireAnswersByUser' + queryParam, null)
      .toPromise()
      .then((snapshot) => {
        return snapshot ? snapshot['value'] : null;
      })
      .catch((e) => this.catchServerError(e));
  }

  sendRegistrationAnswerQ(userKey: string, eventId: string, sectionId: string, questionId: string,
              answer: string[], anonymousAnswers: boolean, logChanges: boolean) {
    if (!anonymousAnswers) {
      const answerJson = JSON.stringify(answer);
      const queryParam =
    `?userKey=${userKey}&eventId=${eventId}&sectionId=${sectionId}&questionId=${questionId}&logChanges=${logChanges}`;
      const answerObj = {
        value: answerJson
      };
      return this.http.post(this.API_URL + 'sendRegistrationAnswerQ' + queryParam, answerObj)
        .toPromise()
        .catch((e) => this.catchServerError(e));
    } else {
      return this.throwFirestoreError('Anonymous mode is not supported when answering registration questionnaire questions.');
    }
  }

  leaveNote(userKey: string, eventId: string, parentId: string, timelineId: string, note: string): Promise<void> {
    const obj = {notes: {}};
    obj.notes[userKey] = note ? note : FieldValue.delete();
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(parentId)
      .collection('contents')
      .doc(timelineId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  leaveRegistrationNote(userKey: string, eventId: string, sectionId: string, note: string): Promise<void> {
    const obj = {notes: {}};
    obj.notes[userKey] = note ? note : FieldValue.delete();
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('registration_questionnaire')
      .doc(sectionId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  leaveTimeLineValues(eventId: string, parentId: string, timelineId: string, questionId: string, values: any): any {
    let obj = values;
    if (questionId) {
      obj = {
        questions: {
          [questionId]: values
        }
      };
    }
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(parentId)
      .collection('contents')
      .doc(timelineId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  leaveRegistrationValues(eventId: string, sectionId: string, questionId: string, values: any): any {
    const obj = {
      questions: {
        [questionId]: values
      }
    };
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('registration_questionnaire')
      .doc(sectionId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  saveUserInformationTime(eventId: string, fieldName: string) {
    const userId = this.auth.getAuthUser().uid;
    const currentTime = new Date().getTime();
    const obj = {
      email: this.auth.getAuthUser().email,
      userId: userId
    };
    obj[fieldName] = currentTime;
    if (this.auth.getAuthUser().isAnonymous) {
      obj['guest'] = true;
    }
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_online')
      .doc(userId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  saveUserOnlineStateData(eventId: string) {
    const userId = this.auth.getAuthUser().uid;
    let obj = {
      email: this.auth.getAuthUser().email,
      userId: userId
    };
    if (this.auth.getAuthUser().isAnonymous) {
      obj['guest'] = true;
    } else {
      obj = merge(obj, {
        lastActivity: new Date().getTime(),
        language: this.auth.getAppUser().language ?? null,
        countryCode: this.auth.getAppUser().countryCode ?? null,
        country: this.auth.getAppUser().country ?? null
      });
    }
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_online')
      .doc(userId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  changeConferenceUserRole(eventId: string, userId: string, role: ConferenceUserRole, action: 'add' | 'remove' = 'add'): Promise<any> {
    const obj = {
      roles: action === 'add' ? FieldValue.arrayUnion(role) : FieldValue.arrayRemove(role)
    };
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_online')
      .doc(userId)
      .update(obj)
      .catch((err) => this.throwFirestoreError(err));
  }

  saveUserInformationField(eventId: string, userId: string, fieldName: string, fieldValue: any): Promise<any> {
    const obj = {
      [fieldName]: fieldValue
    };
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_online')
      .doc(userId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  saveUserMeetingState(eventId: string, userId: string, sectionId: string, state: ISectionUserMeetingState): Promise<any> {
    const obj = {
      ['sectionMeetingState']: {[sectionId]: state}
    };
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_online')
      .doc(userId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  saveUserMediaDevicesState(eventId: string, userId: string, state: IUserMediaDevicesState): Promise<any> {
    const obj = {
      ['mediaDevicesState']: state
    };
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_online')
      .doc(userId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  getEventChatMessages(eventId: string): Observable<DocumentChange<ChatMessage>[]> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('chat_messages')
      .stateChanges(['added', 'modified', 'removed'])
      .pipe(
        this.log('getEventChatMessages'),
        map(res => this.mapArray(res, (it) => it.payload)),
        catchError(err => this.firestoreCatchError(err, 'getEventChatMessages'))
      );
  }

  getEventGroupChatMessages(eventId: string, sectionId: string): Observable<DocumentChange<ChatMessage>[]> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('chat_messages')
      .stateChanges(['added', 'modified', 'removed'])
      .pipe(
        this.log('getEventGroupChatMessages'),
        map(res => this.mapArray(res, (it) => it.payload)),
        catchError(err => this.firestoreCatchError(err, 'getEventGroupChatMessages'))
      );
  }

  getEventManagerChatMessages(eventId: string): Observable<DocumentChange<ChatMessage>[]> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('chat_messages_manager')
      .stateChanges(['added', 'modified', 'removed'])
      .pipe(
        this.log('getEventManagerChatMessages'),
        map(res => this.mapArray(res, (it) => it.payload)),
        catchError(err => this.firestoreCatchError(err, 'getEventManagerChatMessages'))
      );
  }

  getSectionChatMessages(eventId: string, sectionId: string): Observable<DocumentChange<ChatMessage>[]> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('section_chat_messages')
      .stateChanges(['added', 'modified', 'removed'])
      .pipe(
        this.log('getSectionChatMessages'),
        map(res => this.mapArray(res, (it) => it.payload)),
        catchError(err => this.firestoreCatchError(err, 'getSectionChatMessages'))
      );
  }

  sendChatMessage(eventId: string, type: 'event' | 'group' | 'manager' | 'section', sectionId: string, message: string) {
    const currentTime = new Date().getTime();
    const chatMessage = {
      sectionId: sectionId,
      userId: this.currentUserId,
      date: currentTime,
      message: message,
      picture: this.currentAppUser.picture ? this.currentAppUser.picture : null,
      userName: this.currentAppUser.fullName ? this.currentAppUser.fullName : null
    };
    if (type === 'event') {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('chat_messages')
        .add(chatMessage);
    } else if (type === 'group') {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(sectionId)
        .collection('chat_messages')
        .add(chatMessage);
    } else if (type === 'section') {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(sectionId)
        .collection('section_chat_messages')
        .add(chatMessage);
    } else if (type === 'manager') {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('chat_messages_manager')
        .add(chatMessage);
    } else {
      return Promise.resolve();
    }
  }

  deleteChatMessage(eventId: string, type: 'event' | 'group' | 'manager' | 'section', sectionId: string, message) {
    const messageId = message.id;
    if (type === 'event') {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('chat_messages')
        .doc(messageId).ref.delete();
    } else if (type === 'group') {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(sectionId)
        .collection('chat_messages')
        .doc(messageId).ref.delete();
    } else if (type === 'section') {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(sectionId)
        .collection('section_chat_messages')
        .doc(messageId).ref.delete();
    } else if (type === 'manager') {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('chat_messages_manager')
        .doc(messageId).ref.delete();
    } else {
      return Promise.resolve();
    }
  }

  leavePersonalNote(eventId: string, parentId: string, timelineId: string, message: string) {
    if (message) {
      const currentTime = new Date().getTime();
      const obj = {
        timelineId: timelineId,
        parentId: parentId,
        userId: this.currentUserId,
        date: currentTime,
        message: message
      };
      return this.afs.collection('conference')
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection('contents')
        .doc(timelineId)
        .collection('personal_notes')
        .doc(this.currentUserId)
        .set(obj, {merge: true})
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection('contents')
        .doc(timelineId)
        .collection('personal_notes')
        .doc(this.currentUserId)
        .delete()
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  addQuestionnaireContent(eventId: string, content: any) {
    const id = this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(content.parentId)
      .collection('contents')
      .ref.id;
    content.userId = this.auth.getAuthUser().uid;
    content.creator = {
      userId: this.currentAppUser.userId,
      picture: !isEmpty(this.currentAppUser.picture) ? this.currentAppUser.picture : null,
      fullName: !isEmpty(this.currentAppUser.fullName) ? this.currentAppUser.fullName : null,
      department: !isEmpty(this.currentAppUser.department) ? this.currentAppUser.department : null
    };
    if (content.questions) {
      Object.keys(content.questions).forEach(key => {
        content.questions[key].eventId = eventId;
        content.questions[key].timelineId = isEmpty(content.id) ? id : content.id;
      });
    }
    return this.addAnyContent(eventId, content);
  }

  // todo maybe update only approved fields
  editQuestionnaireContent(eventId: string, timelineId: string, title: string, content: any,
                           isPublic: boolean, fullUpdateDocument = false) {
    return this.editContent(eventId, timelineId, content, fullUpdateDocument);
  }

  editRegistrationQuestionnaire(eventId: string, sectionId: string, title: string, content: QuestionnaireContent) {
    const obj = new QuestionnaireContent(content);
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('registration_questionnaire')
      .doc(sectionId)
      .set(obj.toObject())
      .catch((err) => this.throwFirestoreError(err));
  }

  deleteAnyContent(eventId: string, parentId: string, contentId: string, deleteDraft?: boolean) {
    return this.aFirestore.firestore.runTransaction(transaction => {
      let del = Promise.resolve();

      const docRef = this.getContentPath({eventId, parentId} as AbstractContent, deleteDraft)
        .doc(contentId)
        .ref;
      transaction.delete(docRef);

      if (this.loginService.applicationMode === APP_MODE.QUESTION_CARDS) {
        return Promise.resolve(contentId);
      }

      const likeRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection('contents_like')
        .doc(contentId)
        .ref;
      transaction.delete(likeRef);

      const msgRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection('relevancy_summary')
        .doc(contentId)
        .ref;
      transaction.delete(msgRef);

      const drawnRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection(!deleteDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
        .doc(contentId)
        .collection('drawn-metadata')
        .doc('public-metadata').ref;
      transaction.delete(drawnRef);

      const pinRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection(!deleteDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
        .doc(contentId)
        .collection('pins-metadata')
        .doc('public-metadata').ref;
      transaction.delete(pinRef);

      firstValueFrom(this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection('contents_user_like')
        .get()).then((snapshot) => {
          for (const doc of snapshot.docs) {
            del = del.then(() => doc.ref.set({[contentId]: FieldValue.delete()}, {merge: true}));
          }
        });
      firstValueFrom(this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection(!deleteDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
        .doc(contentId)
        .collection('qa_messages')
        .get()).then((snapshot) => {
          for (const doc of snapshot.docs) {
            del = del.then(() => doc.ref.delete());
          }
        });
      firstValueFrom(this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection(!deleteDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
        .doc(contentId)
        .collection('qa_messages_count')
        .get()).then((snapshot) => {
          for (const doc of snapshot.docs) {
            del = del.then(() => doc.ref.delete());
          }
          return del;
        });
      firstValueFrom(this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection(!deleteDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
        .doc(contentId)
        .collection('drawn-metadata')
        .get()).then((snapshot) => {
        for (const doc of snapshot.docs) {
          del = del.then(() => doc.ref.delete());
        }
      });
      firstValueFrom(this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection(!deleteDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
        .doc(contentId)
        .collection('pins-metadata')
        .get()).then((snapshot) => {
        for (const doc of snapshot.docs) {
          del = del.then(() => doc.ref.delete());
        }
      });
      return del.then(() => Promise.resolve(contentId));
    });
  }

  deleteQuestionnaireContent(eventId: string, parentId: string, timelineId: string, deleteDraft: boolean): any {
    return this.deleteAnyContent(eventId, parentId, timelineId, deleteDraft);
  }

  deleteRegistrationQuestionnaire(eventId: string, sectionId: string): any {
    return this.aFirestore.firestore.runTransaction(transaction => {
      const qRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('registration_questionnaire')
        .doc(sectionId).ref;
      return firstValueFrom(this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('registration_questionnaire')
        .doc(sectionId)
        .collection('answers')
        .get())
        .then((snapUsersAnswers) => {
          for (const doc  of snapUsersAnswers.docs) {
            transaction.delete(doc.ref);
          }
          transaction.delete(qRef);
          return Promise.resolve();
        }).catch(err => {
          this.common.log.error(err);
          throw err;
        });
    });
  }

  deleteRegistrationUsers(eventId: string, sectionId: string): any {
    return this.aFirestore.firestore.runTransaction(transaction => {
      return Promise.resolve().then(() => firstValueFrom(this.afs.collection(this.getDBPath())
          .doc(eventId)
          .collection('assistant_registration')
          .get()
          .pipe(catchError(err => this.firestoreCatchError(err, 'deleteRegistrationUsers'))))
          .then(snapshot => {
            const users = snapshot.docs;
            for (const doc of users) {
              const user = doc.data();
              const assistantRef = this.afs.collection(this.getDBPath())
                .doc(eventId)
                .collection('assistant_registration')
                .doc(doc.id)
                .ref;
              if (!isEmpty(user.sections) && user.sections[sectionId]) {
                delete user.sections[sectionId];
                if (isEmpty(user.sections)) {
                  transaction.delete(assistantRef);
                } else {
                  transaction.update(assistantRef, user);
                }
              } else if (isEmpty(user.sections)) {
                transaction.delete(assistantRef);
              }
            }
          })
      ).then(() => firstValueFrom(this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('user_registration')
        .get()
        .pipe(catchError(err => this.firestoreCatchError(err, 'deleteRegistrationUsers'))))
        .then(snapshot => {
          const users = snapshot.docs;
          for (const doc of users) {
            const user = doc.data();
            const urRef = this.afs.collection('conference')
              .doc(eventId)
              .collection('user_registration')
              .doc(doc.id)
              .ref;
            if (!isEmpty(user.sections) && user.sections[sectionId]) {
              delete user.sections[sectionId];
              if (isEmpty(user.sections)) {
                transaction.delete(urRef);
              } else {
                transaction.update(urRef, user);
              }
            } else if (isEmpty(user.sections)) {
              transaction.delete(urRef);
            }
          }
        })
      );
    });
  }

  deleteSectionRegistrationSummary(eventId: string, sectionId: string) {
    return this.aFirestore.firestore.runTransaction(transaction => {
      return Promise.resolve().then(() => {
        const s1Ref = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('section_registration_statuses_summary')
        .doc(sectionId).ref;
        transaction.delete(s1Ref);

        const s2Ref = this.afs.collection(this.getDBPath())
          .doc(eventId)
          .collection('section_registration_statuses_public_summary')
          .doc(sectionId).ref;
        transaction.delete(s2Ref);

        return Promise.resolve();
       });
    });
  }

  editWelcomeScreen(eventId: string, content: WelcomeScreen, clientId?: string): Promise<any> {
    if (!content) {
      return Promise.resolve();
    }
    return this.getAfs(clientId).collection('welcome_screen')
      .doc(eventId)
      .set(content.toObject(), {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  simpleEditSection(eventId: string, sectionId: string, content: any): Promise<any> {
    const obj = this.toObject(content);
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .set(obj, {merge: true})
      .then(() => Promise.resolve(content.id))
      .catch((err) => this.throwFirestoreError(err));
  }

  editContent(eventId: string, timelineId: string, content: any, fullUpdateDocument = false, saveAsDraft = false, updateTime?: number) {
    const currentTime = new Date().getTime();
    if (Object.hasOwnProperty('orderIndex') && content.orderIndex === undefined || content.orderIndex === null) {
      content.orderIndex = currentTime + 1000000;
    } else if (content.orderIndex === 0) {
      content.orderIndex = 1;
    }
    content.updateTime = updateTime ?? currentTime;

    if (content.anonymously) {
      content.userId = UtilsService.md5u(this.loginService.getAppUser());
      content.creator = FieldValue.delete();
    }

    delete content.anonymously;

    if (!content.moveToNewPath) {
      const obj = this.toObject(content);
      if (fullUpdateDocument) {
        return this.getContentPath(content, saveAsDraft)
          .doc(timelineId)
          .update(obj)
          .then(() => Promise.resolve(content.id))
          .catch((err) => this.throwFirestoreError(err));
      } else {
        return this.getContentPath(content, saveAsDraft)
          .doc(timelineId)
          .set(obj, {merge: true})
          .then(() => Promise.resolve(content.id))
          .catch((err) => this.throwFirestoreError(err));
      }
    } else {
      if (this.loginService.applicationMode === APP_MODE.QUESTION_CARDS) {
        throw Error('cannot move contents in application mode ' + APP_MODE.QUESTION_CARDS);
      }
      const newParentId = content.newParentId;
      return this.aFirestore.firestore.runTransaction(transaction => {
        const oldRef = this.afs.collection(this.getDBPath())
          .doc(eventId)
          .collection(this.getSectionsDBPath())
          .doc(content.parentId)
          .collection('contents')
          .doc(timelineId).ref;
        return transaction.get(oldRef).then(data => {
          const doc = data.data();
          doc.parentId = newParentId;
          const ref = this.afs.collection(this.getDBPath())
            .doc(eventId)
            .collection(this.getSectionsDBPath())
            .doc(newParentId)
            .collection('contents')
            .doc(timelineId).ref;
          transaction.set(ref, doc, {merge: true});
          transaction.delete(oldRef);
        });
      }).then(() => {
        this.moveTypeFinished$.next(null);
        return this.aFirestore.firestore.runTransaction(transaction1 => {
          const oldLikeRef = this.afs.collection(this.getDBPath())
            .doc(eventId)
            .collection(this.getSectionsDBPath())
            .doc(content.parentId)
            .collection('contents_like')
            .doc(timelineId).ref;
          return transaction1.get(oldLikeRef).then(res => {
            const likeRef = this.afs.collection(this.getDBPath())
              .doc(eventId)
              .collection(this.getSectionsDBPath())
              .doc(newParentId)
              .collection('contents_like')
              .doc(timelineId).ref;
            if (res.data() !== undefined) {
              transaction1.set(likeRef, res.data(), {merge: true});
              transaction1.delete(oldLikeRef);
              // hackish (to prevent firestore transaction errors)
              // transaction1.update(oldViewRef, {});
            }
            return Promise.resolve();
          });
        });
      }).then(() => {
        return this.aFirestore.firestore.runTransaction(transaction2 => {
          const oldViewRef = this.afs.collection(this.getDBPath())
            .doc(eventId)
            .collection(this.getSectionsDBPath())
            .doc(content.parentId)
            .collection('relevancy_summary')
            .doc(timelineId).ref;
          return transaction2.get(oldViewRef).then(res => {
            const viewRef = this.afs.collection(this.getDBPath())
              .doc(eventId)
              .collection(this.getSectionsDBPath())
              .doc(newParentId)
              .collection('relevancy_summary')
              .doc(timelineId).ref;
            if (res.data() !== undefined) {
              transaction2.set(viewRef, res.data(), {merge: true});
              transaction2.delete(oldViewRef);
              // hackish (to prevent firestore transaction errors)
              // transaction1.update(oldViewRef, {});
            }
            return Promise.resolve();
          });
        });
      }).then(() => {
        return this.aFirestore.firestore.runTransaction(transaction2 => {
          return firstValueFrom(this.afs.collection(this.getDBPath())
            .doc(eventId)
            .collection(this.getSectionsDBPath())
            .doc(content.parentId)
            .collection('contents')
            .doc(timelineId)
            .collection('answers').get()
            .pipe(catchError(err => this.firestoreCatchError(err, 'editContent'))))
            .then(snapshot => {
              for (const doc of snapshot.docs) {
                const answer = doc.data();
                const answerRef = this.afs.collection(this.getDBPath())
                  .doc(eventId)
                  .collection(this.getSectionsDBPath())
                  .doc(newParentId)
                  .collection('contents')
                  .doc(timelineId)
                  .collection('answers')
                  .doc(doc.id).ref;
                const oldAnswerRef = this.afs.collection(this.getDBPath())
                  .doc(eventId)
                  .collection(this.getSectionsDBPath())
                  .doc(content.parentId)
                  .collection('contents')
                  .doc(timelineId)
                  .collection('answers')
                  .doc(doc.id).ref;
                transaction2.set(answerRef, answer);
                transaction2.delete(oldAnswerRef);
              }
              return Promise.resolve();
          });
        });
      }).then(() => {
        return this.aFirestore.firestore.runTransaction(transaction3 => {
          return firstValueFrom(this.afs.collection(this.getDBPath())
            .doc(eventId)
            .collection(this.getSectionsDBPath())
            .doc(content.parentId)
            .collection('contents_user_like').get()
            .pipe(catchError(err => this.firestoreCatchError(err, 'editContent'))))
            .then((snapshot) => {
              let move = Promise.resolve();
              for (const doc of snapshot.docs) {
                const d = doc.data();
                const userId = doc.id;
                const likeValue = d[timelineId] === undefined ? FieldValue.delete() : d[timelineId];
                const newRef = this.afs.collection(this.getDBPath())
                  .doc(eventId)
                  .collection(this.getSectionsDBPath())
                  .doc(newParentId)
                  .collection('contents_user_like')
                  .doc(userId).ref;
                const oldRef = this.afs.collection(this.getDBPath())
                  .doc(eventId)
                  .collection(this.getSectionsDBPath())
                  .doc(content.parentId)
                  .collection('contents_user_like')
                  .doc(userId).ref;
                move = move.then(() => {
                  return newRef.set({[timelineId]: likeValue}, {merge: true}).then(() => {
                    return oldRef.set({[timelineId]: FieldValue.delete()}, {merge: true});
                  });
                });
              }
              return move.then(() => Promise.resolve());
            });
        }).then(() => {
          return this.aFirestore.firestore.runTransaction(transaction10 => {
            return firstValueFrom(this.afs.collection(this.getDBPath())
              .doc(eventId)
              .collection(this.getSectionsDBPath())
              .doc(content.parentId)
              .collection('contents')
              .doc(timelineId)
              .collection('pin_metadata').get()
              .pipe(catchError(err => this.firestoreCatchError(err, 'editContent'))))
              .then(snapshot => {
                for (const doc of snapshot.docs) {
                  const item = doc.data();
                  const itemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(newParentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('pin_metadata')
                    .doc(doc.id).ref;
                  const oldItemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(content.parentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('pin_metadata')
                    .doc(doc.id).ref;
                  transaction10.set(itemRef, item);
                  transaction10.delete(oldItemRef);
                }
                return Promise.resolve();
              });
          });
        }).then(() => {
          return this.aFirestore.firestore.runTransaction(transaction10 => {
            return firstValueFrom(this.afs.collection(this.getDBPath())
              .doc(eventId)
              .collection(this.getSectionsDBPath())
              .doc(content.parentId)
              .collection('contents')
              .doc(timelineId)
              .collection('pin_metadata_personal').get()
              .pipe(catchError(err => this.firestoreCatchError(err, 'editContent'))))
              .then(snapshot => {
                for (const doc of snapshot.docs) {
                  const item = doc.data();
                  const itemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(newParentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('pin_metadata_personal')
                    .doc(doc.id).ref;
                  const oldItemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(content.parentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('pin_metadata_personal')
                    .doc(doc.id).ref;
                  transaction10.set(itemRef, item);
                  transaction10.delete(oldItemRef);
                }
                return Promise.resolve();
              });
          });
        }).then(() => {
          return this.aFirestore.firestore.runTransaction(transaction10 => {
            return firstValueFrom(this.afs.collection(this.getDBPath())
              .doc(eventId)
              .collection(this.getSectionsDBPath())
              .doc(content.parentId)
              .collection('contents')
              .doc(timelineId)
              .collection('svg_metadata').get()
              .pipe(catchError(err => this.firestoreCatchError(err, 'editContent'))))
              .then(snapshot => {
                for (const doc of snapshot.docs) {
                  const item = doc.data();
                  const itemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(newParentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('svg_metadata')
                    .doc(doc.id).ref;
                  const oldItemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(content.parentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('svg_metadata')
                    .doc(doc.id).ref;
                  transaction10.set(itemRef, item);
                  transaction10.delete(oldItemRef);
                }
                return Promise.resolve();
              });
          });
        }).then(() => {
          return this.aFirestore.firestore.runTransaction(transaction10 => {
            return firstValueFrom(this.afs.collection(this.getDBPath())
              .doc(eventId)
              .collection(this.getSectionsDBPath())
              .doc(content.parentId)
              .collection('contents')
              .doc(timelineId)
              .collection('svg_metadata_personal').get()
              .pipe(catchError(err => this.firestoreCatchError(err, 'editContent'))))
              .then(snapshot => {
                for (const doc of snapshot.docs) {
                  const item = doc.data();
                  const itemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(newParentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('svg_metadata_personal')
                    .doc(doc.id).ref;
                  const oldItemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(content.parentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('svg_metadata_personal')
                    .doc(doc.id).ref;
                  transaction10.set(itemRef, item);
                  transaction10.delete(oldItemRef);
                }
                return Promise.resolve();
              });
          });
        }).then(() => {
          return this.aFirestore.firestore.runTransaction(transaction11 => {
            return firstValueFrom(this.afs.collection(this.getDBPath())
              .doc(eventId)
              .collection(this.getSectionsDBPath())
              .doc(content.parentId)
              .collection('contents')
              .doc(timelineId)
              .collection('qa_messages').get()
              .pipe(catchError(err => this.firestoreCatchError(err, 'editContent'))))
              .then(snapshot => {
                for (const doc of snapshot.docs) {
                  const item = doc.data();
                  const itemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(newParentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('qa_messages')
                    .doc(doc.id).ref;
                  const oldItemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(content.parentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('qa_messages')
                    .doc(doc.id).ref;
                  item['sectionId'] = newParentId;
                  transaction11.set(itemRef, item);
                  transaction11.delete(oldItemRef);
                }
                return Promise.resolve();
              });
          });
        }).then(() => {
          return this.aFirestore.firestore.runTransaction(transaction11 => {
            return firstValueFrom(this.afs.collection(this.getDBPath())
              .doc(eventId)
              .collection(this.getSectionsDBPath())
              .doc(content.parentId)
              .collection('contents')
              .doc(timelineId)
              .collection('qa_messages_count').get()
              .pipe(catchError(err => this.firestoreCatchError(err, 'editContent'))))
              .then(snapshot => {
                for (const doc of snapshot.docs) {
                  const item = doc.data();
                  const itemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(newParentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('qa_messages_count')
                    .doc(doc.id).ref;
                  const oldItemRef = this.afs.collection(this.getDBPath())
                    .doc(eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(content.parentId)
                    .collection('contents')
                    .doc(timelineId)
                    .collection('qa_messages_count')
                    .doc(doc.id).ref;
                  transaction11.set(itemRef, item);
                  transaction11.delete(oldItemRef);
                }
                return Promise.resolve();
              });
          });
        });
      });
    }
  }

  editBreakContent(eventId: string, timelineId: string, content: any) {
    return this.editContent(eventId, timelineId, content);
  }

  editQuestionnaireContentSimple(eventId: string, timelineId: string, content: any) {
    return this.editContent(eventId, timelineId, content);
  }

  updateQuestionImageUrl(eventId: string, timelineId: string, parentId: string, questionId: string, imageUrl: string) {
    const obj = {
      questions: {
        [questionId]: {
          imageUrl: imageUrl
        }
      }
    };
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(parentId)
      .collection('contents')
      .doc(timelineId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  updateQuestionTemplateUrl(eventId: string, timelineId: string, parentId: string, questionId: string, imageUrl: string) {
    const obj = {
      questions: {
        [questionId]: {
          wordCloudTemplate: {
            url: imageUrl
          }
        }
      }
    };
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(parentId)
      .collection('contents')
      .doc(timelineId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  deleteModularContent(eventId: string, parentId: string, timelineId: string, deleteDraft: boolean) {
    return this.deleteAnyContent(eventId, parentId, timelineId, deleteDraft);
  }

  deleteBreakContent(eventId: string, parentId: string, timelineId: string) {
    return this.deleteAnyContent(eventId, parentId, timelineId);
  }

  private uploadDeleteSectionBackground(content: SectionContent, clearImage = false) {
    return new Promise<any>((resolve, reject) => {
      if (content.backgroundImage && (content.backgroundImage.startsWith('-') || clearImage)) {
        // remove
        this.deleteImageFromStorage(content.eventId, content.id, content.id).then(function (snapshot) {
          content.backgroundImage = null;
          resolve(content);
        }).catch((e) => {
          this.common.log.error(e);
          resolve(content);
        });
      } else if (content.backgroundImage && !this.utils.isFBUrl(content.backgroundImage, content.eventId)) {
        // upload
        this.uploadImageToStorage(content.eventId, content.id, content.id, content.backgroundImage).then((snapshot) => {
          if (!snapshot) {
            this.common.log.error('Error upload image ' + content.id);
            resolve(content);
          } else {
            snapshot.ref.getDownloadURL().then(url => {
              content.backgroundImage = url;
              resolve(content);
            }).catch((e) => {
              this.common.log.error('Error upload image ' + content.id, e);
              resolve(content);
            });
          }
        }).catch((e) => {
          this.common.log.error('Error upload image ' + content.id, e);
          resolve(content);
        });
      } else {
        resolve(content);
      }
    });
  }

  updateSectionContent(eventId: string, timelineId: string, section: SectionContent, restricted?: boolean): Promise<any> {
    const changelog = () => {
      return this.saveChangeLogValue(eventId, {
        sourceId: timelineId,
        sourceType: Constants.CONTENT_TYPE_SECTION,
        changeType: CHANGE_LOG_VALUE_TYPE.EDITED,
        title: section.title
      });
    };

    return this.aFirestore.firestore.runTransaction(transaction => {
      const sectionRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(timelineId);
      return this.uploadDeleteSectionBackground(section).then((content) => {
        if (content instanceof SectionContent && !restricted) {
          const speakersRef = this.afs.collection('event_sections_speakers').doc(eventId);
          if (content.users) {
            const obj = {
              [timelineId]: {}
            };
            const users: SectionUser[] = content.users;
            for (const user of users) {
              if (user.speaker) {
                obj[timelineId][user.userId] = true;
              }
            }
            return transaction.get(speakersRef.ref).then((res) => {
              if (isEmpty(res.data())) {
                transaction.set(speakersRef.ref, obj, {merge: true});
              } else {
                transaction.update(speakersRef.ref, obj);
              }
              transaction.set(sectionRef.ref, content.toObject());
              return changelog();
            });
          } else {
            const obj = {
              [timelineId]: FieldValue.delete()
            };
            transaction.set(speakersRef.ref, obj, {merge: true});
            transaction.set(sectionRef.ref, content.toObject());
          }
        } else {
          const obj = this.toObject(content);
          transaction.set(sectionRef.ref, obj, {merge: true});
        }
        return changelog();
      }).catch((err) => this.throwFirestoreError(err));
    });
  }

  updateSectionAndModulesFieldsValues(eventId: string, sectionValues: {sectionId: string, fieldName: string, value: any}[],
                            moduleValues: {moduleId: string, fieldName: string, value: any}[]) {
    const cache: IWriteCache[] = [];
    if (!isEmpty(sectionValues)) {
      for (const value of sectionValues) {
        const ref = this.afs.collection(this.getDBPath())
          .doc(eventId)
          .collection('timeline')
          .doc(value.sectionId)
          .ref;
        cache.push({ref: ref, obj: {[value.fieldName]: value.value}});
      }
    }
    if (!isEmpty(moduleValues)) {
      for (const value of moduleValues) {
        const ref = this.afs.collection(this.getDBPath())
          .doc(value.moduleId)
          .ref;
        cache.push({ref: ref, obj: {[value.fieldName]: value.value}});
      }
    }
    return this.commitCache(cache);
  }

  updateSectionVirtualConferenceSettings(eventId: string, timelineId: string, settings: VirtualConferenceSettings): Promise<any> {
    const obj = settings instanceof VirtualConferenceSettings ? settings.toObject() : settings;
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(timelineId)
      .set({virtualConferenceSettings: obj}, {merge: true});
  }

  deleteSectionContent(eventId: string, sectionId: string, title: string): Promise<any> {
    return this.icsTemplateApiService.deleteIcsAllTemplates(eventId, sectionId)
      .then(() => {
        return this.mailTemplateApiService.deleteAllEmailTemplates(eventId, sectionId);
      })
      .then(() => this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('timeline')
        .doc(sectionId)
        .collection('meeting_params')
        .doc('params').delete()
      )
      .then(() => {
        return firstValueFrom(this.afs.collection(this.getDBPath())
          .doc(eventId)
          .collection('timeline')
          .doc(sectionId)
          .collection('feedback')
          .get())
          .then(usersData => {
            const cache: IWriteCache[] = [];
            for (const doc of usersData.docs) {
              const ref = this.afs.collection(this.getDBPath())
                .doc(eventId)
                .collection('timeline')
                .doc(sectionId)
                .collection('feedback')
                .doc(doc.id)
                .ref;
              cache.push({ref: ref, obj: null, action: 'delete'});
            }
            return this.commitCache(cache);
          });
      })
      .then(() => {
        return firstValueFrom(this.afs.collection(this.getDBPath())
          .doc(eventId)
          .collection('timeline')
          .doc(sectionId)
          .collection('subtarget_rating')
          .get())
          .then(usersData => {
            const cache: IWriteCache[] = [];
            const cacheFinally: IWriteCache[] = [];
            for (const doc of usersData.docs) {
              const ref = this.afs.collection(this.getDBPath())
                .doc(eventId)
                .collection('timeline')
                .doc(sectionId)
                .collection('subtarget_rating')
                .doc(doc.id)
                .ref;
              if (doc.id !== 'summary_rating') {
                cache.push({ref: ref, obj: null, action: 'delete'});
              } else {
                cacheFinally.push({ref: ref, obj: null, action: 'delete'});
              }
            }
            return this.commitCache(cache).then(() => this.commitCache(cacheFinally));
          });
      })
      .then(() => {
        return firstValueFrom(this.afs.collection(this.getDBPath())
          .doc(eventId)
          .collection('timeline')
          .doc(sectionId)
          .collection('meeting_rooms')
          .get())
          .then(roomsData => {
            const cache: IWriteCache[] = [];
            for (const doc of roomsData.docs) {
              const ref = this.afs.collection(this.getDBPath())
                .doc(eventId)
                .collection('timeline')
                .doc(sectionId)
                .collection('meeting_rooms')
                .doc(doc.id)
                .ref;
              cache.push({ref: ref, obj: null, action: 'delete'});
            }
            return this.commitCache(cache);
          });
      })
      .then(() => {
        return this.aFirestore.firestore.runTransaction(transaction => {
          const docRef = this.afs.collection(this.getDBPath())
            .doc(eventId)
            .collection(this.getSectionsDBPath())
            .doc(sectionId)
            .ref;
          const timelineQADocRef = this.afs.collection(this.getDBPath())
            .doc(eventId)
            .collection('timeline_questionnaire_answers')
            .doc(sectionId)
            .ref;
          return transaction.get(docRef).then((sectionObject) => {
            const section = sectionObject.data();
            return this.uploadDeleteSectionBackground(new SectionContent(merge(section, {id: sectionObject.id})), true)
              .then(() => {
                transaction.delete(timelineQADocRef);
                transaction.delete(docRef);

                const regSettingsRef = this.afs.collection(this.getDBPath())
                  .doc(eventId)
                  .collection('registration_settings')
                  .doc(sectionId)
                  .ref;
                transaction.delete(regSettingsRef);

                const regQuestionnaireRef = this.afs.collection(this.getDBPath())
                  .doc(eventId)
                  .collection('registration_questionnaire')
                  .doc(sectionId)
                  .ref;
                transaction.delete(regQuestionnaireRef);
                return Promise.resolve(sectionId);
            }).catch((err) => this.throwFirestoreError(err));
          }).catch((err) => this.throwFirestoreError(err));
        }).catch((err) => this.throwFirestoreError(err));
      })
      .then(async () => {
        await this.saveChangeLogValue(eventId, {
          sourceId: sectionId,
          sourceType: Constants.CONTENT_TYPE_SECTION,
          changeType: CHANGE_LOG_VALUE_TYPE.DELETE,
          title: title
        });
        return this.clearUserDataBySection(eventId, sectionId);
      });
  }

  deleteSimpleSection(eventId: string, sectionId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .delete();
  }

  deleteTaskDocumentContent(eventId: string, parentId: string, timelineId: string, deleteDraft: boolean) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(parentId)
      .collection(!deleteDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
      .doc(timelineId)
      .delete();
  }

  incContentLike(eventId: string, parentId: string, timelineId: string, increment: number, oldValue: number,
                 notCalcSummaryIncrement: boolean) {
    const obj = {like: notCalcSummaryIncrement ? 0 :
        FieldValue.increment(increment ? increment : (oldValue ? oldValue : 0) * -1)};
    const userObj = {[timelineId]: increment ? increment : FieldValue.delete()};
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(parentId)
      .collection('contents_like')
      .doc(timelineId)
      .set(obj, {merge: true}).then(() => {
        return this.afs.collection('conference')
          .doc(eventId)
          .collection(this.getSectionsDBPath())
          .doc(parentId)
          .collection('contents_user_like')
          .doc(this.currentUserId)
          .set(userObj, {merge: true})
          .catch((err) => this.throwFirestoreError(err));
      });
  }

  removeUserFromConference(eventId: string, userId: string, fbUserId: string, email: string) {
    let queryParam = `?eventId=${eventId}&email=${email}`;
    if (userId) {
      queryParam = queryParam + '&userId=' + userId;
    }
    if (fbUserId) {
      queryParam = queryParam + '&fbUserId=' + fbUserId;
    }
    return this.http.post(this.API_URL + 'removeUserFromConference' + queryParam, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  batchedWrite(collection: string, dataItems: any[], start: number) {
    const arr = dataItems.slice(start, start + 100);
    if (arr.length === 0) {
      return Promise.resolve(dataItems.length);
    }
    const batch = this.aFirestore.firestore.batch();
    arr.forEach(item => {
      const doc = this.afs.collection(collection).doc(item.key).ref;
      if (item.action === 'update') {
        batch.update(doc, item.data);
      } else {
        batch.set(doc, item.data);
      }
    });
    return batch.commit().then(() => {
      return this.batchedWrite(collection, dataItems, start + 100);
    });
  }

  pushRefreshUserEvent(event: Event) {
    const pathOrRef = `conference/${event.eventId}/users_online`;
    return firstValueFrom(this.afs.collection<ConferenceUser>(pathOrRef)
      .get()
      .pipe(
        map(res => this.mapArray(res.docs, (it) => new ConferenceUser({userId: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'pushRefreshUserEvent'))
      ))
      .then((values) => {
        const timestamp = new Date().getTime();
        const usersData = values.map(user => ({
          key: user.userId,
          action: 'update',
          data: { [event.eventId]: timestamp },
        }));
        const url = `${this.API_URL}pushRefreshUserEvent?eventId=${event.eventId}`;
        return firstValueFrom(this.http.post<any>(url, usersData))
          .catch((e) => this.catchServerError(e));
      });
  }

  getSectionsList(eventId: string, available = false): Observable<SectionContent[]> {
    const pathOrRef = `${this.getDBPath()}/${eventId}/timeline`;
    return this.afs.collection<SectionContent>(pathOrRef, q => q.where('education', '!=', !available))
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getSectionsList'),
        map((res: SectionContent[]) => res.filter(o => o.type === Constants.CONTENT_TYPE_SECTION)),
        map(res => this.mapArray(res, (it) => new SectionContent(it))),
        catchError(err => this.firestoreCatchError(err, 'getSectionsList'))
      );
  }

  getSections(eventId: string): Observable<SectionContent[]> {
    const pathOrRef = `${this.getDBPath()}/${eventId}/timeline`;
    return this.afs.collection<SectionContent>(pathOrRef)
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getSections'),
        map((res: SectionContent[]) => res.filter(o => o.type === Constants.CONTENT_TYPE_SECTION)),
        map(res => this.mapArray(res, (it) => new SectionContent(it))),
        catchError(err => this.firestoreCatchError(err, 'getSections'))
      );
  }

  getSectionsByMode(eventId: string, availableContentsMode: boolean) {
    const pathOrRef = `${this.getDBPath()}/${eventId}/timeline`;

    const rootSnapshotChanges = () => this.afs.collection<SectionContent>(pathOrRef, q => q.where('title', '==', 'root'))
      .valueChanges({idField: 'id'})
      .pipe(map(res => this.mapArray(res.filter(o => o.title === 'root' && !o.parentId), (it) => it)));

    if (availableContentsMode) {
      return combineLatest([
        rootSnapshotChanges(),
        this.afs.collection<SectionContent>(pathOrRef, q => q.where('education', '==', true))
          .snapshotChanges()
          .pipe(
        filter((actions: DocumentChangeAction<any>[], idx) => {
          return idx > 0 || actions.every(a => a.payload.doc.metadata.fromCache === false);
        }),
        map((actions: DocumentChangeAction<any>[]) => actions
          .map(a => ({id: a.payload.doc.id, ...a.payload.doc.data()}))
          .filter(o => o.education === true))),
        of([]),
        this.afs.collection<SectionContent>(pathOrRef, q => q.where('fixedSectionType', '>', ''))
          .valueChanges({idField: 'id'})
          .pipe(map(res => this.mapArray(res.filter(o => !!o.fixedSectionType), (it) => it))),
      ]).pipe(
        this.log(`getSectionsBy/${TIMELINE_MODE.AVAILABLE_CONTENTS}`),
        catchError(err => this.firestoreCatchError(err, `getSectionsBy/${TIMELINE_MODE.AVAILABLE_CONTENTS}`))
      );
    } else {
      return combineLatest([
        rootSnapshotChanges(),
        this.afs.collection<SectionContent>(pathOrRef, q => q.where('education', '==', this.loginService.educationMode))
          .snapshotChanges()
          .pipe(
            filter((actions: DocumentChangeAction<any>[], idx) => {
              return idx > 0 || actions.every(a => a.payload.doc.metadata.fromCache === false);
            }),
            map((actions: DocumentChangeAction<any>[]) => actions
              .map(a => ({id: a.payload.doc.id, ...a.payload.doc.data()}))
              .filter(o => o.education === this.loginService.educationMode && !!o.parentId))),
        this.afs.collection<SectionContent>(pathOrRef, q => q.where('shortcuts', 'not-in', ['']))
          .valueChanges({idField: 'id'})
          .pipe(map(res => this.mapArray(res.filter(o => !isEmpty(o.shortcuts)), (it) => it))),
        of([])
      ]).pipe(
        this.log(`getSectionsBy/${TIMELINE_MODE.TIMELINE}`),
        catchError(err => this.firestoreCatchError(err, `getSectionsBy/${TIMELINE_MODE.AVAILABLE_CONTENTS}`))
      );
    }
  }

  getSectionsPromise(eventId: string, available = false): Promise<SectionContent[]> {
    const pathOrRef = `${this.getDBPath()}/${eventId}/timeline`;
    const query: QueryFn = !available ? q => q.where('education', '==', false) : q => q.where('education', '==', true);
    return firstValueFrom(
      combineLatest(
        [
          this.afs.collection(pathOrRef, q => q.where('parentId', '==', null))
            .get()
            .pipe(
              this.log(`getSectionsPromise/root`),
              map((res: QuerySnapshot<SectionContent>) =>
                this.mapArray(res.docs.filter(o => o.data().type === Constants.CONTENT_TYPE_SECTION),
                (it) => new SectionContent({id: it.id, ...it.data()}))),
              catchError(err => this.firestoreCatchError(err, 'getSectionsPromise'))
            ),
          this.afs.collection(pathOrRef, query)
            .get()
            .pipe(
              this.log(`getSectionsPromise/${!available ? TIMELINE_MODE.TIMELINE : TIMELINE_MODE.AVAILABLE_CONTENTS}`),
              map((res: QuerySnapshot<SectionContent>) =>
                this.mapArray(res.docs.filter(o => o.data().type === Constants.CONTENT_TYPE_SECTION),
                (it) => new SectionContent({id: it.id, ...it.data()}))),
              catchError(err => this.firestoreCatchError(err, 'getSectionsPromise'))
            )])
        .pipe(map(([root, list]) => {
          const all = <SectionContent[]>uniqBy(union(root, list), 'id');
          return all.filter(s => !s.parentId || all.find(p => p.id === s.parentId));
        }))
    );
  }

  checkGarbageSectionsPromise(eventId: string): Promise<SectionContent[]> {
    const pathOrRef = `conference/${eventId}/timeline`;
    return firstValueFrom(this.afs.collection(pathOrRef)
      .get()
      .pipe(
        this.log('checkGarbageSectionsPromise'),
        map((res: QuerySnapshot<SectionContent>) => this.mapArray(res.docs.filter(o => !o.data().type),
          (it) => new Object({id: it.id}))),
        catchError(err => this.firestoreCatchError(err, 'checkGarbageSectionsPromise'))
      ));
  }

  getSection(eventId: string, sectionId: string): Observable<SectionContent> {
    if (!sectionId) {
      return EMPTY;
    }
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .snapshotChanges()
      .pipe(
        this.log('getSection'),
        map((res: Action<DocumentSnapshot<SectionContent>>) =>
          this.mapObject(res.payload, (it) => new SectionContent({id: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getSection'))
      );
  }

  getSectionPromise(eventId: string, sectionId: string) {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .get()
      .pipe(
        this.log('getSectionPromise'),
        map(res => this.mapObject(res, (it) => new SectionContent({id: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getSectionPromise'))
      ));
  }

  getRootSection(eventId: string): Observable<SectionContent> {
    const pathOrRef = `${this.getDBPath()}/${eventId}/timeline`;
    return this.afs.collection(pathOrRef,
      ref => ref.where('parentId', '==', null)
        .where('type', '==', Constants.CONTENT_TYPE_SECTION))
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getRootSection'),
        map(res => new SectionContent(res[0])),
        catchError(err => this.firestoreCatchError(err, 'getRootSection'))
      );
  }

  getRootSectionPromise(eventId: string): Promise<SectionContent> {
    const pathOrRef = `${this.getDBPath()}/${eventId}/timeline`;
    return firstValueFrom(this.afs.collection(pathOrRef,
      ref => ref.where('parentId', '==', null)
                .where('type', '==', Constants.CONTENT_TYPE_SECTION))
      .get()
      .pipe(
        this.log('getRootSectionPromise'),
        first(),
        map((res: QuerySnapshot<SectionContent>) => this.mapObject(res.docs[0], (it) => new SectionContent({id: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getRootSectionPromise'))
      ));
  }

  getSectionsByParent(eventId: string, sectionId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath(), ref => ref.where('parentId', '==', sectionId))
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getSectionsByParent'),
        catchError(err => this.firestoreCatchError(err, 'getSectionsByParent'))
      );
  }

  getSectionsSpeakersPromise(eventId) {
    return firstValueFrom(this.afs
      .collection('event_sections_speakers')
      .doc(eventId)
      .get()
      .pipe(
        this.log('getSectionsSpeakersPromise'),
        map((res: DocumentSnapshot<any>) => res.data()),
        catchError(err => this.firestoreCatchError(err, 'getSectionsSpeakersPromise'))
      ));
  }

  getSectionContents(eventId: string, section: SectionContent | SectionTimeline, secondParentId: string,
                     user: AppUser, isPresenter: boolean): Observable<any[]> {
    if (!secondParentId) {
      if (section.isAttached()) {
        const pathOriginRef = `${this.getDBPath()}/${eventId}/timeline/${section.id}/contents`;
        const pathShortcutRef = `${this.getDBPath()}/${eventId}/timeline/${section.originId}/contents`;
        return combineLatest([
          this.afs.collection(pathOriginRef).valueChanges({idField: 'id'}),
          this.afs.collection(pathShortcutRef).valueChanges({idField: 'id'}),
        ]).pipe(
          map(([origin, shortcut]) => union(origin, shortcut)),
          this.log('getSectionContents/combined'),
          catchError(err => this.firestoreCatchError(err, 'getSectionContents'))
        );
      } else {
        const pathOrRef = `${this.getDBPath()}/${eventId}/timeline/${section.id}/contents`;
        return this.afs.collection(pathOrRef)
          .valueChanges({idField: 'id'})
          .pipe(
            this.log('getSectionContents'),
            catchError(err => this.firestoreCatchError(err, 'getSectionContents'))
          );
      }
    } else {
      if (isPresenter || !user) {
        return this.afs.collection(this.getDBPath())
          .doc(eventId)
          .collection(this.getSectionsDBPath())
          .doc(section.id)
          .collection('contents', query => query.where('secondParentId', '==', secondParentId))
          .valueChanges({idField: 'id'})
          .pipe(
            this.log('getSectionContents'),
            catchError(err => this.firestoreCatchError(err, 'getSectionContents'))
          );
      } else {
        return new Observable(obs => {
          combineLatest([
            this.afs.collection(this.getDBPath())
              .doc(eventId)
              .collection(this.getSectionsDBPath())
              .doc(section.id)
              .collection('contents', query => query
                .where('secondParentId', '==', secondParentId)
                .where('userId', '==', user.userId))
              .valueChanges({idField: 'id'}),
            this.afs.collection(this.getDBPath())
              .doc(eventId)
              .collection(this.getSectionsDBPath())
              .doc(section.id)
              .collection('contents', query => query
                .where('secondParentId', '==', secondParentId)
                .where('userId', '==', UtilsService.md5u(user)))
              .valueChanges({idField: 'id'})
          ]).subscribe(([v1, v2]) => {
            obs.next(union(!v1 ? [] : v1, !v2 ? [] : v2));
          });
        });
      }
    }
  }

  getSectionDraftContents(eventId: string, section: SectionContent | SectionTimeline): Observable<any[]> {
    const pathOrRef = `${this.getDBPath()}/${eventId}/timeline/${section.id}/${CONTENT_PATH_TYPE.DRAFT}`;
    return this.afs.collection(pathOrRef)
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getSectionDraftContents'),
        catchError(err => this.firestoreCatchError(err, 'getSectionDraftContents'))
      );
  }

  getSectionContentsPromise(eventId: string, sectionId: string, pathType: CONTENT_PATH_TYPE = CONTENT_PATH_TYPE.DEFAULT): Promise<any> {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection(pathType)
      .get()
      .pipe(
        this.log('getSectionContentsPromise'),
        map((res: QuerySnapshot<any>) => this.mapArray(res.docs, (it) => new Object({id: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getSectionContentsPromise'))
      ));
  }

  loadSectionContentsByLocation(eventId: string, sectionId: string, loadFromEducation: boolean): Promise<Object> {
    return firstValueFrom(this.http.post(this.API_URL + 'loadSectionContents',
      {
        value: {
          eventId: eventId,
          sectionId: sectionId,
          loadFromEducation: loadFromEducation
        }
      },
      {responseType: 'json'}))
      .catch((e) => this.catchServerError(e));
  }

  getSectionContent(eventId: string, sectionId: string, contentId: string): Observable<any> {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .snapshotChanges()
      .pipe(
        this.log('getSectionContent'),
        map(res => this.mapObject(res, (it) => ({id: it.payload.id, ...it.payload.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getSectionContent'))
      );
  }

  getSectionContentPromise(eventId: string, sectionId: string, contentId: string): Promise<any> {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .get()
      .pipe(
        this.log('getSectionContentPromise'),
        map(res => this.mapObject(res, (it) => ({id: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getSectionContentPromise'))
      ));
  }

  copyStorageObject(pathFrom, pathTo, clientIdTo?: string) {
    const callable = this.aff.httpsCallable('copyStorageObject');
    const obj = {
      pathFrom: `${this.clientStoragePath}/${pathFrom}`,
      pathTo: `${this.getClientStoragePath(clientIdTo)}/${pathTo}`
    };
    return callable(obj).pipe(take(1)).toPromise();
  }

  setRecordingStartJson(obj: RecordingStartRequest) {
    const callable = this.aff.httpsCallable('onRecordingStart');
    return callable(obj).pipe(take(1)).toPromise();
  }

  imageRewriteTo(eventIdFrom, eventIdTo, pathFrom, pathTo, clientIdTo?: string) {
    return this.copyStorageObject(
      `${this.getDBPath()}/${eventIdFrom}/${pathFrom}`,
      `${this.getDBPath()}/${eventIdTo}/${pathTo}`,
      clientIdTo);
  }

  copyWordCloudTemplateToContent(eventIdTo, pathFrom, pathTo) {
    return this.copyStorageObject(`word_cloud_templates/templates/${pathFrom}`, `${this.getDBPath()}/${eventIdTo}/${pathTo}`);
  }

  /* registration */

  editRegistrationSettings(eventId: string, sectionId: string, content: RegistrationSettings, autoInvite?: boolean) {
    if (content) {
      const obj = content instanceof RegistrationSettings ? content.toObject() : content;
      return this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(RegistrationSettings.DB_PATH)
        .doc(sectionId)
        .set(obj, {merge: true})
        .then(() => {
          if (autoInvite && content.autoWaitingListInvite || content.autoFreeSpaceInform) {
            const path = `autoInviteFromWaitingList?eventId=${eventId}&sectionId=${sectionId}`;
            return this.http.post(this.API_URL + path, null)
              .toPromise()
              .catch((e) => this.catchServerError(e));
          }
        })
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return Promise.resolve();
    }
  }

  deleteRegistrationSettings(eventId: string, sectionId: string) {
    return this.aFirestore.firestore.runTransaction(transaction => {
      return Promise.resolve().then(() => {
        const rsRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('registration_settings')
        .doc(sectionId).ref;
        transaction.delete(rsRef);
        return Promise.resolve();
      });
    });
  }

  getRegistrationSettings(eventId: string,  sectionId: string): Observable<RegistrationSettings> {
    return this.afs
      .collection(this.getDBPath())
      .doc(eventId)
      .collection('registration_settings')
      .doc<RegistrationSettings>(sectionId)
      .valueChanges()
      .pipe(
        this.log('getRegistrationSettings'),
        map(res => res ? this.mapObject(res, (it) => new RegistrationSettings(it)) : new RegistrationSettings()),
        catchError(err => this.firestoreCatchError(err, 'getRegistrationSettings'))
      );
  }

  getRegistrationSettingsPromise(eventId: string,  sectionId: string): Promise<RegistrationSettings> {
    return firstValueFrom(this.afs
      .collection(this.getDBPath())
      .doc(eventId)
      .collection('registration_settings')
      .doc<RegistrationSettings>(sectionId)
      .get()
      .pipe(
        this.log('getRegistrationSettingsPromise'),
        map(res => res ? this.mapObject(res, (it) => new RegistrationSettings(it.data())) : new RegistrationSettings()),
        catchError(err => this.firestoreCatchError(err, 'getRegistrationSettingsPromise'))
      ));
  }

  getRegistrationSettingsCollection(eventId: string): Observable<RegistrationSettings[]> {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('registration_settings')
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getRegistrationSettingsCollection'),
        map(res => this.mapArray(res, (it) => new RegistrationSettings(it))),
        catchError(err => this.firestoreCatchError(err, 'getRegistrationSettingsCollection'))
      );
  }

  getRegistrationQuestionnaire(eventId: string, sectionId: string): Observable<QuestionnaireContent> {
    const queryFn = query => query.where('parentId', '==', sectionId).where('questionsCount', '>', 0);
    return this.afs.collection<QuestionnaireContent>(this.getDBPath())
      .doc(eventId)
      .collection('registration_questionnaire', queryFn)
      .valueChanges()
      .pipe(
        this.log('getRegistrationQuestionnaire'),
        map((list: any[]) => list.length ? list[0] : null),
        map(res => this.mapObject(res, (it) => new QuestionnaireContent({id: sectionId, ...it}))),
        catchError(err => this.firestoreCatchError(err, 'getRegistrationQuestionnaire'))
      );
  }

  getRegistrationQuestionnairePromise(eventId: string, sectionId: string): Promise<QuestionnaireContent> {
    return firstValueFrom(this.afs
      .collection(this.getDBPath())
      .doc(eventId)
      .collection('registration_questionnaire')
      .doc<QuestionnaireContent>(sectionId)
      .get()
      .pipe(
        this.log('getRegistrationQuestionnairePromise'),
        map(res => this.mapObject(res, (it) => new QuestionnaireContent({id: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getRegistrationQuestionnairePromise'))
      ));
  }

  getRegistrationQuestionnaireCollectionAsMap(eventId: string): Observable<IQuestionnaireContentMap> {
    const path = `${this.getDBPath()}/${eventId}/registration_questionnaire`;
    return this.afs.collection<QuestionnaireContent>(path)
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getRegistrationQuestionnaireCollectionAsMap'),
        map((res: any[]) => res.reduce(function (vAccum, vCurrent) {
          vAccum[vCurrent.id] = vCurrent;
          return vAccum;
        }, {})),
        catchError(err => this.firestoreCatchError(err, 'getRegistrationQuestionnaireCollectionAsMap'))
      );
  }

  getRegistrationQuestionnairesList(eventId: string): Observable<QuestionnaireContent[]> {
    const queryFn = query => query.where('questionsCount', '>', 0);
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('registration_questionnaire', queryFn)
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getRegistrationQuestionnairesList'),
        map(res => this.mapArray(res, (it) => new QuestionnaireContent(it))),
        catchError(err => this.firestoreCatchError(err, 'getRegistrationQuestionnairesList'))
      );
  }

  private getSectionRegistrationQuestionnaireAnswersByUser(userCode: string, eventId: string, sectionId: string): Observable<any> {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('registration_questionnaire')
      .doc(sectionId)
      .collection('answers')
      .doc(userCode)
      .valueChanges()
      .pipe(this.log('getSectionRegistrationQuestionnaireAnswersByUser'),
        map(res => new Object({[userCode]: {[sectionId]: res}})),
        catchError(err => this.firestoreCatchError(err, 'getSectionRegistrationQuestionnaireAnswersByUser'))
      );
  }

  getSectionRegistrationQuestionnaireAnswersByUserAsMap(userCode: string, eventId: string,
                                                        sectionIdList: string[]): Observable<IRegistrationQuestionnaireAnswers[]> {
    const observableAll: Observable<IRegistrationQuestionnaireAnswers>[] = [];
    for (const sectionId of sectionIdList) {
      observableAll.push(this.getSectionRegistrationQuestionnaireAnswersByUser(userCode, eventId, sectionId)
        .pipe(map(obj => obj))
      );
    }
    return combineLatest(observableAll);
  }

  getUserNotifications(eventId: string, sectionId: string): Observable<any> {
    return this.afs
      .collection('conference')
      .doc(eventId)
      .collection('user_notifications', query => query.where(sectionId, '!=', null))
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getUserNotifications'),
        catchError(err => this.firestoreCatchError(err, 'getUserNotifications'))
      );
  }

  getSectionsRegisteredUsers(eventId: string): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('user_registration')
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getSectionsRegisteredUsers'),
        catchError(err => this.firestoreCatchError(err, 'getSectionsRegisteredUsers'))
      );
  }

  // todo Deprecated. We should to to use cached value from timelineService
  getSectionUserRegistration(eventId: string, sectionId: string, userCode: string): Observable<any> {
    const queryFn = query => query.where(FieldPath.documentId(), '==', userCode).where(`sections.${sectionId}.userId`, '==', userCode);
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('user_registration', queryFn)
      .valueChanges()
      .pipe(
        this.log('getSectionUserRegistration'),
        map((list: any[]) => list.length ? list[0] : null),
        catchError(err => this.firestoreCatchError(err, 'getSectionUserRegistration'))
      );
  }

  getUserRegistration(eventId: string, userId: string): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('user_registration')
      .doc(userId)
      .valueChanges()
      .pipe(
        this.log('getUserRegistration'),
        catchError(err => this.firestoreCatchError(err, 'getUserRegistration'))
      );
  }

  getUserRegistrationPromise(eventId: string, userId: string): Promise<any> {
    return firstValueFrom(this.afs.collection('conference')
      .doc(eventId)
      .collection('user_registration')
      .doc(userId)
      .get()
      .pipe(
        this.log('getUserRegistrationPromise'),
        map(res => this.mapObject(res, (it) => new Object(it.data()))),
        catchError(err => this.firestoreCatchError(err, 'getUserRegistrationPromise'))
      ));
  }

  getAssistantRegistrationUsers(eventId: string, sectionId: string): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('assistant_registration')
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getAssistantRegistrationUsers'),
        catchError(err => this.firestoreCatchError(err, 'getAssistantRegistrationUsers'))
      );
  }

  deleteUserRegistrationAnswers(userKey: string, eventId: string, sectionId: string, list: any[]) {
    if (!list || !list.length) {
      return Promise.resolve();
    }
    const queryParam = `?userKey=${userKey}&eventId=${eventId}&sectionId=${sectionId}`;
    return this.http.post(this.API_URL + 'deleteRegistrationAnswerQ' + queryParam, {value: list})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getMandatoryQuestionnaireSectionsIdList(eventId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('registration_settings', ref => ref.where('requiredAllAnswers', '==', true))
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getMandatoryQuestionnaireSectionsIdList'),
        catchError(err => this.firestoreCatchError(err, 'getMandatoryQuestionnaireSectionsIdList'))
      );
  }

  uploadImageToStorage(eventId: string, timelineId: string, imageId: string, base64: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/image/${timelineId}/${imageId}.png`);
    const index = base64.indexOf(';base64,');
    base64 = base64.substr(index + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: 'image/png'});
  }

  uploadPdfToStorage(eventId: string, timelineId: string, imageId: string, base64: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/pdf/${timelineId}/${imageId}.pdf`);
    const index = base64.indexOf(';base64,');
    base64 = base64.substr(index + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: 'application/pdf'});
  }

  uploadZifToStorage(eventId: string, timelineId: string, imageId: string, base64: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/zif/${timelineId}/${imageId}.zif`);
    const index = base64.indexOf(';base64,');
    base64 = base64.substr(index + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: 'image/ziff'});
  }

  deleteImageFromStorage(eventId: string, timelineId: string, imageId: string): any {
    return this.deleteObjectFromStorage(eventId, `${this.getDBPath()}/${eventId}/image/${timelineId}/${imageId}.png`);
  }

  deletePdfFromStorage(eventId: string, timelineId: string, imageId: string): any {
    return this.deleteObjectFromStorage(eventId, `${this.getDBPath()}/${eventId}/pdf/${timelineId}/${imageId}.pdf`);
  }

  deleteZifFromStorage(eventId: string, timelineId: string, imageId: string): any {
    return this.deleteObjectFromStorage(eventId, `${this.getDBPath()}/${eventId}/zif/${timelineId}/${imageId}.zif`);
  }

  getDownloadUrl(eventId: string, path: string, clientId?: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/${path}`, clientId);
    return reference.getDownloadURL();
  }

  uploadPresenterAnyDocumentToStorage(eventId: string, timelineId: string, documentName: string, base64: string, metaType: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/content/${timelineId}/presenter/${documentName}`);
    const index = base64.indexOf(';base64,');
    base64 = base64.substr(index + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: metaType});
  }

  deletePresenterAnyDocumentFromStorage(eventId: string, timelineId: string, documentName: string): any {
    return this.deleteObjectFromStorage(eventId, `${this.getDBPath()}/${eventId}/content/${timelineId}/presenter/${documentName}`);
  }

  uploadUserAnyDocumentToStorage(eventId: string, timelineId: string, userId: string,
                                 documentName: string, base64: string, metaType: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/content/${timelineId}/users/${userId}/${documentName}`);
    const index = base64.indexOf(';base64,');
    base64 = base64.substr(index + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: metaType});
  }

  deleteUserAnyDocumentFromStorage(eventId: string, timelineId: string, userId: string, documentName: string): any {
    return this.deleteObjectFromStorage(eventId, `${this.getDBPath()}/${eventId}/content/${timelineId}/users/${userId}/${documentName}`);
  }

  saveSvgMetadataAndUploadToStorage(eventId: string, sectionId: string, contentId: string,  moduleId: string,
                                    ownerId: string, svgId: string, base64: string,
                                    orderIndex: number, placeType: PLACE_TYPE,
                                    reference: REF_METADATA) {
    const idSVG = !svgId ? this.aFirestore.createId() : svgId;
    return this.uploadSvgToStorage(eventId, contentId, ownerId, idSVG, base64).then(() => {
      return this.saveSvgMetadata(eventId, sectionId, contentId, idSVG,
        {userId: this.currentUserId, userName: this.currentAppUser.fullName, moduleId: moduleId,
          ownerId: ownerId, svgId: idSVG, orderIndex: orderIndex,
          pinMode: null, reference: reference, placeType: placeType}, reference)
        .then(() => {
        return Promise.resolve(idSVG);
      }).catch((err) => {
          this.common.log.error('error save svg metadata.', err);
          return this.deleteObjectFromStorage(eventId, `${this.getDBPath()}/${eventId}/image/${contentId}/${ownerId}-SVG/${ownerId}-${idSVG}.svg`)
            .then(() => {
              return Promise.resolve(null);
            }).catch((e) => {
              this.common.log.error('error delete svg from storage.', err);
              return Promise.resolve(null);
            });
        });
    }).catch((err) => {
      this.common.log.error('error upload svg.', err);
      return Promise.resolve(null);
    });
  }

  savePinSvgMetadata(eventId: string, sectionId: string, contentId: string,  moduleId: string,
                     ownerId: string, pinXY: string, picture: string, placeType: PLACE_TYPE,
                     pinData: {pinMode: PINS_MODE, reference: REF_METADATA, userId?: string, userName?: string,
                       pinText?: string, pinNumber?: number}) {
    const safeGet = (value) => value ? value : null;
    const pinId = this.aFirestore.createId();
    return this.saveSvgMetadata(eventId, sectionId, contentId, pinId,
      {
        userId: !pinData.userId ? this.currentUserId : pinData.userId, moduleId: moduleId,
        userName: !pinData.userId ? this.currentAppUser.fullName : pinData.userName,
        ownerId: ownerId, svgId: pinId, orderIndex: (new Date()).getTime(),
        pinXY: pinXY, picture: picture, pinMode: pinData.pinMode, reference: pinData.reference, placeType: placeType,
        pinText: safeGet(pinData.pinText),
        pinNumber: safeGet(pinData.pinNumber)},
        pinData.reference)
      .then(() => {
        return Promise.resolve(pinId);
      }).catch((err) => {
      this.common.log.error('error upload svg.', err);
      return Promise.resolve(null);
    });
  }

  editPinSvgMetadata(eventId: string, sectionId: string, contentId: string, moduleId: string,
                     ownerId: string, pinXY: string, picture: string, placeType: PLACE_TYPE,
                     pinData: IPinData) {
    const safeGet = (value) => value ? value : null;
    const pinId = !pinData.id ? this.aFirestore.createId() : pinData.id;
    return this.editSvgMetadata(eventId, sectionId, contentId, pinId,
      {
        userId: !pinData.userId ? this.currentUserId : pinData.userId, moduleId: moduleId,
        userName: !pinData.userId ? this.currentAppUser.fullName : pinData.userName,
        ownerId: ownerId, svgId: pinId, orderIndex: (new Date()).getTime(),
        pinXY: pinXY, picture: picture, pinMode: pinData.pinMode, reference: pinData.reference, placeType: placeType,
        pinText: safeGet(pinData.pinText),
        pinNumber: safeGet(pinData.pinNumber)},
        pinData.reference)
      .then(() => {
        return Promise.resolve(pinId);
      }).catch((err) => {
      this.common.log.error('error upload svg.', err);
      return Promise.resolve(null);
    });
  }

  public saveSvgMetadata(eventId: string,  sectionId: string, contentId: string, svgId: string, svgMetadata: ISVGMetadata,
                          reference: REF_METADATA) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection(reference)
      .doc(svgId).set(svgMetadata, {merge: true});
  }

  private editSvgMetadata(eventId: string,  sectionId: string, contentId: string, svgId: string, svgMetadata: ISVGMetadata,
                          reference: REF_METADATA) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection(reference)
      .doc(svgId).set(svgMetadata, {merge: true});
  }

  public refreshSvgMetadataForClient(eventId: string,  sectionId: string, contentId: string, svgId: string,
                          reference: REF_METADATA) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection(reference)
      .doc(svgId).set({updateTime: (new Date()).getTime()}, {merge: true});
  }

  deletePinSvgMetadata(eventId: string, sectionId: string, contentId: string, pinId: string,
                       reference: REF_METADATA) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection(reference)
      .doc(pinId)
      .delete()
      .catch((err) => this.throwFirestoreError(err));
  }

  deleteSvgLine(eventId: string, sectionId: string, contentId: string, imageId: string, svgId: string, reference: REF_METADATA) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection(reference)
      .doc(svgId)
      .delete()
      .then(() => this.deleteObjectFromStorage(eventId,
        `${this.getDBPath()}/${eventId}/image/${contentId}/${imageId}-SVG/${imageId}-${svgId}.svg`));
  }


  public uploadSvgToStorage(eventId: string, contentId: string, ownerId: string, svgId: string, base64: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/image/${contentId}/${ownerId}-SVG/${ownerId}-${svgId}.svg`);
    const index = base64.indexOf(';base64,');
    base64 = base64.substr(index + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: 'image/svg+xml'}).then(() => {
      return reference.getDownloadURL()
        .then((url) => Promise.resolve(url));
    });
  }

  getSvgFromStorage(eventId: string, timelineId: string, ownerId: string, svgId: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/image/${timelineId}/${ownerId}-SVG/${ownerId}-${svgId}.svg`);
    return new Promise<string>((resolve, reject) => {
      reference.getDownloadURL().then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'text';
        xhr.onload = function (event) {
          return resolve(xhr.response);
        };
        xhr.open('GET', url);
        xhr.send();
      }).catch((e) => {
        return resolve(null);
      });
    });
  }

  deleteImageSvgFromStorageAndMetadata(eventId: string, sectionId: string, contentId: string, ownerId: string,
                                       type: 'general' | 'personal') {
      const svgRef = type === 'general' ? REF_METADATA.SVG : REF_METADATA.SVG_PERSONAL;
      const pinRef = type === 'general' ? REF_METADATA.PIN : REF_METADATA.PIN_PERSONAL;
      return this.getSvgMetadataPromise(eventId, sectionId, contentId, ownerId, svgRef)
        .then((list) => {
        let imgPromise = Promise.resolve(null);
          imgPromise.then(() => {
            this.getSvgMetadataPromise(eventId, sectionId, contentId, ownerId, pinRef).then((pList) => {
              const promises = [];
              for (const svgObj of pList) {
                promises.push(this.afs.collection(this.getDBPath())
                  .doc(eventId)
                  .collection('timeline')
                  .doc(sectionId)
                  .collection('contents')
                  .doc(contentId)
                  .collection(pinRef).doc(svgObj.svgId).delete());
              }
              return Promise.all(promises);
            }).catch((err) => {
              this.common.log.error(err);
              return Promise.reject(err);
            });
          }).catch((err) => {
            this.common.log.error(err);
            return Promise.reject(err);
          });
          imgPromise = imgPromise.then(() => {
            const promises = [];
            for (const svgObj of list) {
              promises.push(this.afs.collection(this.getDBPath())
                .doc(eventId)
                .collection('timeline')
                .doc(sectionId)
                .collection('contents')
                .doc(contentId)
                .collection(svgRef)
                .doc(svgObj.svgId)
                .delete()
                .then(() => this.deleteObjectFromStorage(eventId,
                  `${this.getDBPath()}/${eventId}/image/${contentId}/${ownerId}-SVG/${ownerId}-${svgObj.svgId}.svg`)));
            }
            return Promise.all(promises).then().catch((err) => {
              this.common.log.error(err);
              return Promise.reject(err);
            });
          });
          return imgPromise;
      });
  }

  private deleteSvgOrPinDoc(eventId: string, sectionId: string, contentId: string, docId: string,
                       reference: REF_METADATA, ownerId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection(reference).doc(docId).delete()
      .then(() => {
        if (ownerId) {
          return this.deleteObjectFromStorage(eventId,
            `${this.getDBPath()}/${eventId}/image/${contentId}/${ownerId}-SVG/${ownerId}-${docId}.svg`);
        } else {
          return Promise.resolve();
        }
      });
  }

  deleteModularContentAllSvgFromStorageAndMetadata(eventId: string, sectionId: string, contentId: string) {
    return this.getAllSvgMetadataPromise(eventId, sectionId, contentId, REF_METADATA.PIN).then((docListP) => {
      const promisesP = [];
      for (const doc of docListP) {
        promisesP.push(this.deleteSvgOrPinDoc(eventId, sectionId, contentId, doc.svgId, REF_METADATA.PIN, null));
      }
      return Promise.all(promisesP).then(() => {
        return this.getAllSvgMetadataPromise(eventId, sectionId, contentId, REF_METADATA.PIN_PERSONAL).then((docListPP) => {
          const promisesPP = [];
          for (const doc of docListPP) {
            promisesPP.push(this.deleteSvgOrPinDoc(eventId, sectionId, contentId, doc.svgId, REF_METADATA.PIN_PERSONAL, null));
          }
          return Promise.all(promisesPP).then(() => {
            return this.getAllSvgMetadataPromise(eventId, sectionId, contentId, REF_METADATA.SVG).then((docListS) => {
              const promisesS = [];
              for (const doc of docListS) {
                promisesS.push(this.deleteSvgOrPinDoc(eventId, sectionId, contentId, doc.svgId, REF_METADATA.SVG, doc.ownerId));
              }
              return Promise.all(promisesS).then(() => {
                return this.getAllSvgMetadataPromise(eventId, sectionId, contentId, REF_METADATA.SVG_PERSONAL).then((docListSP) => {
                  const promisesSP = [];
                  for (const doc of docListSP) {
                    promisesSP.push(this.deleteSvgOrPinDoc(eventId, sectionId, contentId,
                      doc.svgId, REF_METADATA.SVG_PERSONAL, doc.ownerId));
                  }
                  return Promise.all(promisesSP).then(() => {
                    return Promise.resolve();
                  });
                });
              });
            });
          });
        });
      });
    });
  }

  deleteModularContentAllGlobalSvgFromStorageAndMetadata(eventId: string, sectionId: string, contentId: string) {
    return this.getAllSvgMetadataPromise(eventId, sectionId, contentId, REF_METADATA.PIN).then((docListP) => {
      const promisesP = [];
      for (const doc of docListP) {
        promisesP.push(this.deleteSvgOrPinDoc(eventId, sectionId, contentId, doc.svgId, REF_METADATA.PIN, null));
      }
      return Promise.all(promisesP).then(() => {
        return this.getAllSvgMetadataPromise(eventId, sectionId, contentId, REF_METADATA.SVG).then((docListS) => {
          const promisesS = [];
          for (const doc of docListS) {
            promisesS.push(this.deleteSvgOrPinDoc(eventId, sectionId, contentId, doc.svgId, REF_METADATA.SVG, doc.ownerId));
          }
          return Promise.all(promisesS).then(() => {
            return Promise.resolve();
          });
        });
      });
    });
  }

  deleteModularContentModuleSvgFromStorageAndMetadata(eventId: string, sectionId: string, contentId: string, moduleId: string) {
    return this.getModuleSvgMetadataPromise(eventId, sectionId, contentId, moduleId, REF_METADATA.PIN).then((docListP) => {
      const promisesP = [];
      for (const doc of docListP) {
        promisesP.push(this.deleteSvgOrPinDoc(eventId, sectionId, contentId, doc.svgId, REF_METADATA.PIN, null));
      }
      return Promise.all(promisesP).then(() => {
        return this.getModuleSvgMetadataPromise(eventId, sectionId, contentId, moduleId, REF_METADATA.PIN_PERSONAL).then((docListPP) => {
          const promisesPP = [];
          for (const doc of docListPP) {
            promisesPP.push(this.deleteSvgOrPinDoc(eventId, sectionId, contentId, doc.svgId, REF_METADATA.PIN_PERSONAL, null));
          }
          return Promise.all(promisesPP).then(() => {
            return this.getModuleSvgMetadataPromise(eventId, sectionId, contentId, moduleId, REF_METADATA.SVG).then((docListS) => {
              const promisesS = [];
              for (const doc of docListS) {
                promisesS.push(this.deleteSvgOrPinDoc(eventId, sectionId, contentId, doc.svgId, REF_METADATA.SVG, doc.ownerId));
              }
              return Promise.all(promisesS).then(() => {
                return this.getModuleSvgMetadataPromise(eventId, sectionId, contentId,
                  moduleId, REF_METADATA.SVG_PERSONAL).then((docListSP) => {
                  const promisesSP = [];
                  for (const doc of docListSP) {
                    promisesSP.push(this.deleteSvgOrPinDoc(eventId, sectionId, contentId,
                      doc.svgId, REF_METADATA.SVG_PERSONAL, doc.ownerId));
                  }
                  return Promise.all(promisesSP).then(() => {
                    return Promise.resolve();
                  });
                });
              });
            });
          });
        });
      });
    });
  }



  getSvgMetadata(eventId: string, sectionId: string, contentId: string, ownerId: string,
                 reference: REF_METADATA): Observable<DocumentChange<ISVGMetadata>[]> {
    let queryFn = q => q.where('ownerId', '==', ownerId);
    if (reference === REF_METADATA.SVG_PERSONAL || reference === REF_METADATA.PIN_PERSONAL) {
      const userId = this.auth.getAuthUser().uid;
      queryFn = q => q.where('ownerId', '==', ownerId).where('userId', '==', userId);
    }
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection(reference, queryFn)
      .stateChanges(['added', 'modified', 'removed'])
      .pipe(
        this.log('getSvgMetadata'),
        map(res => this.mapArray(res, (it) => it.payload)),
        catchError(err => this.firestoreCatchError(err, 'getSvgMetadata')));
  }

  private getSvgMetadataPromise(eventId: string, sectionId: string, contentId: string, ownerId: string,
                                reference: REF_METADATA): Promise<ISVGMetadata[]> {
    let queryFn = q => q.where('ownerId', '==', ownerId);
    if (reference === REF_METADATA.SVG_PERSONAL || reference === REF_METADATA.PIN_PERSONAL) {
      const userId = this.auth.getAuthUser().uid;
      queryFn = q => q.where('ownerId', '==', ownerId).where('userId', '==', userId);
    }
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection(reference, queryFn)
      .get()
      .pipe(
        this.log('getSvgMetadataPromise'),
        map(res => this.mapArray(res, (it) => new Object(it.data()))),
        catchError(err => this.firestoreCatchError(err, 'getSvgMetadataPromise'))));
  }

  private getModuleSvgMetadataPromise(eventId: string, sectionId: string, contentId: string, moduleId: string,
                                      reference: REF_METADATA): Promise<ISVGMetadata[]> {
    const queryFn = q => q.where('moduleId', '==', moduleId);
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection(reference, queryFn)
      .get()
      .pipe(
        this.log('getModuleSvgMetadataPromise'),
        map(res => this.mapArray(res, (it) => new Object(it.data()))),
        catchError(err => this.firestoreCatchError(err, 'getModuleSvgMetadataPromise'))));
  }

  public getAllSvgMetadataPromise(eventId: string, sectionId: string, contentId: string,
                                   reference: REF_METADATA): Promise<ISVGMetadata[]> {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection(reference)
      .get()
      .pipe(
        this.log('getAllSvgMetadataPromise'),
        map(res => this.mapArray(res, (it) => new Object(it.data()))),
        catchError(err => this.firestoreCatchError(err, 'getAllSvgMetadataPromise'))));
  }

  getDuplicateEventTaskStatus(eventId: string, newEventId: string, userId: string) {
    return this.afs.collection('duplicate_event_log')
      .doc(eventId)
      .collection('duplicate_owner')
      .doc(userId)
      .valueChanges()
      .pipe(
        this.log('getDuplicateEventTaskStatus'),
        map(res => res && res[newEventId] ? res[newEventId] : []),
        catchError(err => this.firestoreCatchError(err, 'getDuplicateEventTaskStatus'))
      );
  }

  getContentsLikes(eventId: string, sectionId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('contents_like')
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getContentsLikes'),
        catchError(err => this.firestoreCatchError(err, 'getContentsLikes'))
      );
  }

  getContentsUserLikes(eventId: string, sectionId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('contents_user_like')
      .doc(this.currentUserId)
      .valueChanges()
      .pipe(
        this.log('getContentsUserLikes'),
        catchError(err => this.firestoreCatchError(err, 'getContentsUserLikes'))
      );
  }

  getRelevancySummary(eventId: string, sectionId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('relevancy_summary')
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getRelevancySummary'),
        catchError(err => this.firestoreCatchError(err, 'getRelevancySummary'))
      );
  }

  /**
   * Return question word cloud png template url
   * @param eventId
   * @param contentId
   * @param questionId
   * @returns {any}
   */
  getWordCloudURL(eventId: string, contentId: string, questionId: string): any {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/question/${contentId}/word_cloud_template/${questionId}.png`);
    return reference.getDownloadURL();
  }

  uploadQuestionWordCloudTemplate(eventId: string, contentId: string, questionId: string, base64: string) {
    const reference = this.getStorageRef(`${this.getDBPath()}/${eventId}/question/${contentId}/word_cloud_template/${questionId}.png`);
    const b64Ind = base64.indexOf(';base64,');
    base64 = base64.substring(b64Ind + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: 'image/png'});
  }

  /**
   * Delete any object from firebase storage (path start without word 'conference' and without sign '/')
   * @param eventId
   * @param path
   */
  deleteObjectFromStorage(eventId: string, path: string) {
    return this.getStorageRef(`${path}`).delete()
      .catch((e) => {
        if (e.code === 'storage/object-not-found') {
          return Promise.resolve();
        } else {
          return Promise.reject(e);
        }
      });
  }

  postManagementNote(eventId: string, timelineId: string, noteText: string): any {
    const obj = {noteText: noteText, timelineId: timelineId};
    const ref = this.afs.collection('conference')
      .doc(eventId)
      .collection('management_notes')
      .doc(timelineId);
    if (!noteText) {
      return ref.delete()
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return ref.set(obj, {merge: true})
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getSectionsManagementNotes(eventId: string) {
    return this.afs.collection('conference')
      .doc<Notes>(eventId)
      .collection('management_notes')
      .valueChanges()
      .pipe(
        this.log('getSectionsManagementNotes'),
        catchError(err => this.firestoreCatchError(err, 'getSectionsManagementNotes'))
      );
  }

  updateManageTimeSections(eventId: string, task: {sectionId, fieldName, value, type: 'event' | 'section'}[]) {
    return this.http.post(this.API_URL + 'updateManageTimeSections?eventId=' + eventId, task)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  // todo used only in service task for update database to new version
  clearUserOnlineDevices(eventId: number, userId: string, devices: any) {
    const contentJSON = JSON.stringify(devices);
    return this.api(() => this.gapi.client.eventModeApi.clearUserOnlineDevices({
      eventId: eventId, userId: userId}, {object: contentJSON}));
  }

  sendSelfLearningTime(eventId: string, parentId: string, timelineId: string, spentTime: number) {
    const params = `?eventId=${eventId}&parentId=${parentId}&timelineId=${timelineId}&spentTime=${spentTime}`;
    return this.http.post(this.API_URL + 'sendSelfLearningTime' + params, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  pechaKuchaNextSlide(eventId: string, contentId: string, slideNum: number) {
    // todo currently not used
    return Promise.resolve();
    // return this.api(() => this.gapi.client.eventModeApi.pechaKuchaNextSlide(
    //   {eventId: eventId, contentId: contentId, slideNum: slideNum}));
  }

  getQuestionAnswers(eventId: string, sectionId: string, contentId: string): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline_questionnaire_answers')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .valueChanges()
      .pipe(
        this.log('getQuestionAnswers'),
        catchError(err => this.firestoreCatchError(err, 'getQuestionAnswers'))
      );
  }

  getQuestionnaireContentsPromise(eventId: string): Promise<any[]> {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .get()
      .pipe(
        this.log('getQuestionnaireContentsPromise'),
        map(res => this.mapArray(res, (it) => new Object({id: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err, 'getQuestionnaireContentsPromise'))
      ))
      .then(sections => {
        const allPromise = [];
        for (const section of sections) {
          allPromise.push(firstValueFrom(this.afs.collection(this.getDBPath())
            .doc(eventId)
            .collection(this.getSectionsDBPath())
            .doc(section.id)
            .collection('contents', ref => ref.where('type', '==', Constants.CONTENT_TYPE_QUESTIONNAIRE))
            .get()
            .pipe(
              this.log('getQuestionnaireContentsPromiseContents'),
              map(res => this.mapArray(res, (it) => new Object({id: it.id, ...it.data()}))),
              catchError(err => this.firestoreCatchError(err, 'getQuestionnaireContentsPromiseContents'))
            )));
          allPromise.push(firstValueFrom(this.afs.collection(this.getDBPath())
            .doc(eventId)
            .collection(this.getSectionsDBPath())
            .doc(section.id)
            .collection('contents', ref => ref.where('itemsTypes', 'array-contains', CONTAINER_ITEM_TYPE.QUIZ))
            .get()
            .pipe(
              this.log('getContentContainerQuizItemsPromise'),
              map(res => this.mapArray(res, (it) => new Object({id: it.id, ...it.data()}))),
              catchError(err => this.firestoreCatchError(err, 'getContentContainerQuizItemsPromise'))
            )));
        }
        return Promise.all(allPromise).then((list) => {
          return Promise.resolve<any[]>(list.filter(it => !isEmpty(it)).reduce((accum: any[], current) => {
            for (const obj of current) {
              if (obj.type === Constants.CONTENT_TYPE_QUESTIONNAIRE) {
                accum.push(new QuestionnaireContent(obj));
              }
              if (obj.type === Constants.CONTENT_TYPE_CONTENT_CONTAINER) {
                const cc = new ContentContainer(obj);
                cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ &&
                  it.data.anonymousAnswersMode !== ANONYMOUS_ANSWERS_MODE.ANONYMOUS)
                  .forEach(o => accum.push(new ExtQuiz(new Object({
                    id: o.id,
                    title: cc.title,
                    contentId: cc.id,
                    parentId: cc.parentId,
                    ...o.data}))));
              }
            }
            return accum;
          }, []));
        }).catch((err) => {
          this.common.log.error(err);
          return Promise.resolve(null);
        });
      });
  }

  getContentsShort(eventId: string, fromPath?: string) {
    return this.afs.collection(fromPath ?? this.getDBPath())
      .doc(eventId)
      .collection('timeline_content_short')
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getContentsShort'),
        map( (res: any[]) => {
          return res.reduce((data, src) => {
            const id = src.id;
            delete src.id;
            if (isEmpty(src)) {
              return data;
            }
            data[id] = src;
            return data;
          }, {});
        }),
        catchError(err => this.firestoreCatchError(err, 'getContentsShort'))
      );
  }

  getQuestionnaireDependencyPromise(eventId: string, questionnaireId: string) {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline_questionnaire_dependency')
      .doc(questionnaireId)
      .get()
      .pipe(
        this.log('getQuestionnaireDependencyPromise'),
        map(res => this.mapObject(res, (it) => new Object(it.data()))),
        catchError(err => this.firestoreCatchError(err, 'getQuestionnaireDependencyPromise'))
      ));
  }

  getUserLastActivity(userId: string) {
    return this.afs.collection('user_events_last_activity')
      .doc(userId)
      .valueChanges()
      .pipe(
        this.log('getUserLastActivity'),
        catchError(err => this.firestoreCatchError(err, 'getUserLastActivity'))
      );
  }

  getUserConferenceSettings(eventId: string, userId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_conference_settings')
      .doc(userId)
      .valueChanges()
      .pipe(
        this.log('getUserConferenceSettings'),
        catchError(err => this.firestoreCatchError(err, 'getUserConferenceSettings'))
      );
  }

  getUsersOnlineCount(eventId: string): Observable<number> {
    return this.afs.collection('conference')
      .doc(eventId)
      .valueChanges()
      .pipe(
        this.log('getUsersOnlineCount'),
        map(res => res ? res['online_count'] : 0),
        catchError(err => this.firestoreCatchError(err, 'getUsersOnlineCount'))
      );
  }

  /* self-learning*/
  getUserLearningContents(eventId: string, userId: string): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('self_learning')
      .doc(userId)
      .valueChanges()
      .pipe(
        this.log('getUserLearningContents'),
        catchError(err => this.firestoreCatchError(err, 'getUserLearningContents'))
      );
  }

  getUsersLearningContents(eventId: string): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('self_learning')
      .snapshotChanges()
      .pipe(
        this.log('getUsersLearningContents'),
        catchError(err => this.firestoreCatchError(err, 'getUsersLearningContents'))
      );
  }

  getEventICS(eventId: string) {
    return this.http.post(this.API_URL + 'getEventICS?eventId=' + eventId, null, {responseType: 'text'})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  setEventStatus(eventId: string) {
    return this.api(() => this.gapi.client.eventModeApi.setEventStatus({eventId: eventId}));
  }

  userSubmitTaskDocument(eventId: string, contentId: string, userTaskObject: any) {
    const obj = userTaskObject instanceof UserTaskDocumentObject ? userTaskObject.toObject() : userTaskObject;
    const userId = this.auth.getAuthUser().uid;
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_task_content_answers')
      .doc(contentId)
      .collection('answers')
      .doc(userId)
      .set(obj)
      .catch((err) => this.throwFirestoreError(err));
  }

  loadUserTaskDocumentObject(eventId: string, contentId: string, userId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_task_content_answers')
      .doc(contentId)
      .collection('answers')
      .doc(userId)
      .valueChanges()
      .pipe(
        this.log('loadUserTaskDocumentObject'),
        catchError(err => this.firestoreCatchError(err, 'loadUserTaskDocumentObject'))
      );
  }

  loadAllUsersTaskDocumentObjects(eventId: string, contentId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_task_content_answers')
      .doc(contentId)
      .collection('answers')
      .valueChanges()
      .pipe(
        this.log('loadAllUsersTaskDocumentObjects'),
        catchError(err => this.firestoreCatchError(err, 'loadAllUsersTaskDocumentObjects'))
      );
  }

  setUserTaskFieldValue(user: AppUser, eventId: string, contentId: string, parentId: string,
                        fieldName: USER_TASK_FIELDS, fieldValue: string, speaker: ITaskDocumentSpeaker) {
    return this.aFirestore.firestore.runTransaction(transaction => {
      let obj = {};
      obj = {
        [fieldName]: fieldValue ? (!isNaN(Number(fieldValue)) ? Number(fieldValue) : fieldValue) : FieldValue.delete()
      };
      if (speaker) {
        obj['speaker'] = speaker;
      }
      const aRef = this.afs.collection('conference')
        .doc(eventId)
        .collection('users_task_content_answers')
        .doc(contentId)
        .collection('answers')
        .doc(user.userId);
      transaction.set(aRef.ref, obj, {merge: true});
      return Promise.resolve();
    });
  }

  getCustomActionLongValue(eventId: string, fieldName: string) {
    return this.afs.collection('conference')
      .doc<any>(eventId)
      .valueChanges()
      .pipe(
        this.log('getCustomActionLongValue'),
        map((res: any) => res && res.current_action ? res.current_action[fieldName] : null),
        catchError(err => this.firestoreCatchError(err, 'getCustomActionLongValue'))
      );
  }

  setCustomActionLongValue(eventId: string, fieldName: string, fieldValue: number | string) {
    const obj: ICurrentAction = {
      current_action: {
        [fieldName]: {
          value: fieldValue,
          userId: this.currentUserId
        }
      }
    };
    return this.afs.collection('conference')
      .doc(eventId)
      .set(obj, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  getJitsiRoomToken(eventId: string, sectionId: string) {
    return this.http.post(this.API_URL + `getJitsiRoomToken?eventId=${eventId}&sectionId=${sectionId}`, null, {responseType: 'text'})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  extendEventPhase(eventId, phase: SECTION_EVENT_PHASE, extendEventParam: 'start' | 'end',
                   params?: {dateTime: number}) {
    const updObj = {};
    switch (phase) {
      case SECTION_EVENT_PHASE.PREP:
        if (extendEventParam === 'start') {
          updObj['prepPhaseStart'] = params.dateTime;
          updObj['prepPhaseStartFixed'] = true;
          updObj['prepPhaseDurationFixed'] = false;
          updObj['prepPhaseEndFixed'] = true;
        } else if (extendEventParam === 'end') {
          updObj['prepPhaseDurationFixed'] = false;
          updObj['prepPhaseEndFixed'] = false;
        }
        break;
      case SECTION_EVENT_PHASE.WRAP_UP:
        if (extendEventParam === 'start') {
          updObj['wrapUpPhaseStart'] = params.dateTime;
          updObj['wrapUpPhaseStartFixed'] = true;
          updObj['wrapUpPhaseDurationFixed'] = false;
          updObj['wrapUpPhaseEndFixed'] = true;
        } else if (extendEventParam === 'end') {
          updObj['wrapUpPhaseDurationFixed'] = false;
          updObj['wrapUpPhaseEndFixed'] = false;
        }
        break;
      default:
        if (extendEventParam === 'start') {
          updObj['startDate'] = new Date(params.dateTime);
          updObj['startDateFixed'] = true;
          updObj['durationFixed'] = false;
          updObj['endDateFixed'] = true;
        } else if (extendEventParam === 'end') {
          updObj['durationFixed'] = false;
          updObj['endDateFixed'] = false;
        }
        break;
    }
    return this.afs.collection('events')
      .doc(eventId)
      .set(updObj, {merge: true})
      .then(() => Promise.resolve(true))
      .catch((err) => this.throwFirestoreError(err));
  }

  async setTypingQuestion(eventId, questionId: string, userId: string, avatar: UserAvatar) {
    const model: UserAvatar & { date: string } = { ...avatar, date: new Date().toISOString() };

    const client_id = this.auth.client_id$.getValue();
    const pathOrRef = `client_data/${client_id}/conference/${eventId}/users_typing_question/${questionId}/${userId}`;
    await this.afDB.object(pathOrRef).update(model);
  }

  getUsersTypingQuestion(eventId, questionId: string) {
    const client_id = this.auth.client_id$.getValue();
    const pathOrRef = `client_data/${client_id}/conference/${eventId}/users_typing_question/${questionId}`;
    return this.afDB.object(pathOrRef).valueChanges();
  }

  removeUserFromTypingQuestion(eventId, questionId: string, userId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('users_typing_question')
      .doc(questionId)
      .collection('users')
      .doc(userId)
      .delete();
  }


  resetEventFollowMe(eventId) {
    const actionsRef = 'follow_me_action';
    const actions = ['action', 'full_screen', 'ext_full_screen', 'qa_action', 'conference_grid', 'meeting_view_mode',
      'scroll_to', 'zoom_to', 'command', 'pool_action'];
    const instantSettingsObject = {
      followMeSettings: {
        followMeMode: FOLLOW_ME_MODE.DONT_FOLLOW
      }
    };
    return this.aFirestore.firestore.runTransaction(transaction => {
      for (const action of actions) {
        transaction.delete(this.afs.collection('conference')
          .doc(eventId)
          .collection(actionsRef)
          .doc(action).ref);
      }
      transaction.set(this.getAfs(null).collection(InstantSettings.DB_PATH).doc(eventId).ref, instantSettingsObject, {merge: true});
      transaction.delete(this.afs.collection('conference').doc(eventId).collection('follow_me_action').doc('presenter').ref);
      transaction.delete(this.afs.collection('conference').doc(eventId).collection('follow_me_action').doc('meeting_speaker').ref);
      return Promise.resolve();
    });
  }

  resetSectionFollowMe(eventId, sectionId: string) {
    const actionsRef = 'follow_me_action';
    const actions = ['action', 'full_screen', 'ext_full_screen', 'qa_action', 'conference_grid', 'meeting_view_mode',
      'scroll_to', 'zoom_to', 'command', 'pool_action'];
    const instantSettingsObject = {
      followMeSettings: {
        followMeMode: FOLLOW_ME_MODE.DONT_FOLLOW
      }
    };
    return this.aFirestore.firestore.runTransaction(transaction => {
      for (const action of actions) {
        transaction.delete(this.afs.collection('conference')
          .doc(eventId)
          .collection('timeline')
          .doc(sectionId)
          .collection(actionsRef)
          .doc(action).ref);
      }
      transaction.set(this.afs.collection('conference').doc(eventId).collection('timeline').doc(sectionId).ref,
        instantSettingsObject, {merge: true});
      transaction.delete(this.afs.collection('conference').doc(eventId).collection('timeline')
        .doc(sectionId).collection('follow_me_action').doc('speaker').ref);
      transaction.delete(this.afs.collection('conference').doc(eventId).collection('timeline')
        .doc(sectionId).collection('follow_me_action').doc('meeting_speaker').ref);
      transaction.delete(this.afs.collection('conference').doc(eventId).collection('timeline')
        .doc(sectionId).collection('follow_me_action').doc('meeting_view_mode').ref);
      return Promise.resolve();
    });
  }

  setEventFollowMe(eventId, followMeMode: FOLLOW_ME_MODE, followMePresenter: AppUser, action: IFollowMeAction) {
    const instantSettingsObject = {
      followMeSettings: {
        followMeMode: followMeMode
      }
    };
    const presenterObject = {
      userId: followMePresenter.userId,
      picture: followMePresenter.picture,
      fullName: followMePresenter.fullName,
      email: followMePresenter.email
    };
    return this.aFirestore.firestore.runTransaction(async transaction => {
      // read current FollowMe presenter
      const presenter = await transaction.get(this.afs.collection('conference').doc(eventId).collection('follow_me_action')
        .doc('presenter').ref);
      if (presenter.data()?.userId === presenterObject.userId) {
        return Promise.resolve(false);
      }
      transaction.set(this.getAfs(null).collection(InstantSettings.DB_PATH)
        .doc(eventId).ref, instantSettingsObject, {merge: true});
      transaction.set(this.afs.collection('conference').doc(eventId).collection('follow_me_action')
        .doc('presenter').ref, presenterObject, {merge: false});
      if (!isEmpty(action)) {
        transaction.set(this.afs.collection('conference').doc(eventId).collection('follow_me_action')
          .doc('action').ref, action, {merge: false});
      } else {
        transaction.delete(this.afs.collection('conference').doc(eventId).collection('follow_me_action')
          .doc('action').ref);
      }
      return Promise.resolve(true);
    });
  }

  setSectionFollowMe(eventId, sectionId: string, followMeMode: FOLLOW_ME_MODE, followMePresenter: AppUser, action: IFollowMeAction) {
    const instantSettingsObject = {
      followMeSettings: {
        followMeMode: followMeMode
      }
    };
    const presenterObject = {
      userId: followMePresenter.userId,
      picture: followMePresenter.picture,
      fullName: followMePresenter.fullName,
      email: followMePresenter.email
    };
    return this.aFirestore.firestore.runTransaction(async transaction => {
      // read current FollowMe speaker
      const speaker = await transaction.get(this.afs.collection('conference').doc(eventId).collection('timeline')
        .doc(sectionId).collection('follow_me_action').doc('speaker').ref);
      if (speaker.data()?.userId === presenterObject.userId) {
        return Promise.resolve(false);
      }
      transaction.set(this.afs.collection('conference').doc(eventId).collection('timeline').doc(sectionId).ref,
        instantSettingsObject, {merge: true});
      transaction.set(this.afs.collection('conference').doc(eventId).collection('timeline')
        .doc(sectionId).collection('follow_me_action').doc('speaker').ref, presenterObject, {merge: false});
      if (!isEmpty(action)) {
        transaction.set(this.afs.collection('conference').doc(eventId).collection('timeline')
          .doc(sectionId).collection('follow_me_action').doc('action').ref, action, {merge: false});
      } else {
        transaction.delete(this.afs.collection('conference').doc(eventId).collection('timeline')
          .doc(sectionId).collection('follow_me_action').doc('action').ref);
      }
      return Promise.resolve(true);
    });
  }

  setFollowMeAction(eventId, action: IFollowMeAction) {
    if (!isEmpty(action)) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('action')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('action')
        .delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeAction(eventId): Observable<IFollowMeAction> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('action')
      .valueChanges()
      .pipe(
        this.log('getFollowMeAction'),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeAction'))
      );
  }

  getFollowMeActionPromise(eventId) {
    return firstValueFrom(this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('action')
      .get()
      .pipe(
        this.log('getFollowMeActionPromise'),
        map((res: DocumentSnapshot<any>) => this.mapObject(res.data(), (it) => it as IFollowMeAction)),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeActionPromise'))
      ));
  }


  setFollowMeFullScreen(eventId, fullScreenMode: boolean) {
    if (fullScreenMode !== null) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('full_screen')
        .set({'full_screen_mode': fullScreenMode}, {merge: true})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('full_screen')
        .delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeFullScreen(eventId) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('full_screen')
      .valueChanges()
      .pipe(
        this.log('getFollowMeFullScreen'),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeFullScreen'))
      );
  }

  setFollowMeExtFullScreen(eventId, fullScreenMode: boolean, sectionId: string, contentId: string, moduleId: string) {
    if (fullScreenMode) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('ext_full_screen')
        .set({
          fullscreen: fullScreenMode,
          sectionId: sectionId,
          contentId: contentId,
          moduleId: moduleId
        }, {merge: true})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('ext_full_screen')
        .delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeExtFullScreen(eventId) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('ext_full_screen')
      .valueChanges()
      .pipe(
        this.log('getFollowMeExtFullScreen'),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeExtFullScreen'))
      );
  }

  getFollowMePresenter(eventId): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('presenter')
      .valueChanges()
      .pipe(
        this.log('getFollowMePresenter'),
        catchError(err => this.firestoreCatchError(err, 'getFollowMePresenter'))
      );
  }

  setSpeakerFollowMeAction(eventId, mainSpeakerSectionId: string, action: IFollowMeAction) {
    if (!isEmpty(action)) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('action')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('action').delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getSpeakerFollowMeAction(eventId, mainSpeakerSectionId: string): Observable<IFollowMeAction> {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('action')
        .valueChanges()
        .pipe(
          this.log('getSpeakerFollowMeAction'),
          map(res => <IFollowMeAction>res),
          catchError(err => this.firestoreCatchError(err, 'getSpeakerFollowMeAction'))
        );

  }

  setSpeakerFollowMeFullScreen(eventId, mainSpeakerSectionId: string, fullScreenMode: boolean) {
    if (fullScreenMode !== null) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('full_screen')
        .set({full_screen_mode: fullScreenMode}, {merge: true})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('full_screen')
        .delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeSpeaker(eventId, mainSpeakerSectionId: string): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(mainSpeakerSectionId)
      .collection('follow_me_action')
      .doc('speaker')
      .valueChanges()
      .pipe(
        this.log('getFollowMeSpeaker'),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeSpeaker'))
      );
  }

  getFollowMeSpeakerFullScreen(eventId, mainSpeakerSectionId: string): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(mainSpeakerSectionId)
      .collection('follow_me_action')
      .doc('full_screen')
      .valueChanges()
      .pipe(
        this.log('getFollowMeSpeakerFullScreen'),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeSpeakerFullScreen'))
      );
  }


  setSpeakerFollowMeExtFullScreen(eventId, mainSpeakerSectionId: string, fullScreenMode: boolean,
                                  sectionId: string, contentId: string, moduleId: string) {
    if (fullScreenMode !== null) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('ext_full_screen')
        .set({
          fullscreen: fullScreenMode,
          sectionId: sectionId,
          contentId: contentId,
          moduleId: moduleId
        }, {merge: true})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('ext_full_screen')
        .delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeSpeakerExtFullScreen(eventId, mainSpeakerSectionId: string): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(mainSpeakerSectionId)
      .collection('follow_me_action')
      .doc('ext_full_screen')
      .valueChanges()
      .pipe(
        this.log('getFollowMeSpeakerFullScreen'),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeSpeakerFullScreen'))
      );
  }

  setFollowMeQAAction(eventId, action: IFollowMeQAAction) {
    if (!isEmpty(action)) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('qa_action')
        .set(action, {merge: true})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('qa_action')
        .delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeQAAction(eventId): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('qa_action')
      .valueChanges()
      .pipe(
        this.log('getFollowMeQAAction'),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeQAAction'))
      );
  }

  setFollowMeSpeakerQAAction(eventId, mainSpeakerSectionId: string, action: IFollowMeQAAction) {
    if (!isEmpty(action)) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('qa_action')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('scroll_to').delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeSpeakerQAAction(eventId, mainSpeakerSectionId: string): Observable<IFollowMeQAAction> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(mainSpeakerSectionId)
      .collection('follow_me_action')
      .doc('qa_action')
      .valueChanges()
      .pipe(
        this.log('getFollowMeSpeakerQAAction'),
        map(res => <IFollowMeQAAction>res),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeSpeakerQAAction'))
      );
  }

  setFollowMeScrollPosition(eventId, action: IFollowMeScrollTo) {
    if (!isEmpty(action)) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('scroll_to')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('scroll_to')
        .delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeScrollPosition(eventId): Observable<IFollowMeScrollTo> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('scroll_to')
      .valueChanges()
      .pipe(
        this.log('getFollowMeScrollPosition'),
        map(res => <IFollowMeScrollTo>res),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeScrollPosition'))
      );
  }


  setFollowMeSpeakerScrollPosition(eventId, mainSpeakerSectionId: string, action: IFollowMeScrollTo) {
    if (!isEmpty(action)) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('scroll_to')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('scroll_to').delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeSpeakerScrollPosition(eventId, mainSpeakerSectionId: string): Observable<IFollowMeScrollTo> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(mainSpeakerSectionId)
      .collection('follow_me_action')
      .doc('scroll_to')
      .valueChanges()
      .pipe(
        this.log('getSpeakerFollowMeScrollPosition'),
        map(res => <IFollowMeScrollTo>res),
        catchError(err => this.firestoreCatchError(err, 'getSpeakerFollowMeScrollPosition'))
      );
  }

  setFollowMeZoomPosition(eventId, action: IFollowMeZoomTo) {
    if (!isEmpty(action)) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('zoom_to')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('zoom_to')
        .delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeZoomPosition(eventId): Observable<IFollowMeZoomTo> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('zoom_to')
      .valueChanges()
      .pipe(
        this.log('getFollowMeZoomPosition'),
        map(res => <IFollowMeZoomTo>res),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeZoomPosition'))
      );
  }

  setFollowMeSpeakerZoomPosition(eventId, mainSpeakerSectionId: string, action: IFollowMeZoomTo) {
    if (!isEmpty(action)) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('zoom_to')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('zoom_to').delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeSpeakerZoomPosition(eventId, mainSpeakerSectionId: string): Observable<IFollowMeZoomTo> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(mainSpeakerSectionId)
      .collection('follow_me_action')
      .doc('zoom_to')
      .valueChanges()
      .pipe(
        this.log('getFollowMeSpeakerZoomPosition'),
        map(res => <IFollowMeZoomTo>res),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeSpeakerZoomPosition'))
      );
  }

  setFollowMeCurrentMeetingPresenter(eventId, action: IFollowMeMeetingSpeaker) {
    if (!isEmpty(action)) {
      action['datetime'] = new Date().getTime();
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('meeting_speaker')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('meeting_speaker')
        .delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeCurrentMeetingPresenter(eventId): Observable<IFollowMeMeetingSpeaker> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('meeting_speaker')
      .valueChanges()
      .pipe(
        this.log('getFollowMeCurrentMeetingPresenter'),
        tap((res: IFollowMeMeetingSpeaker) => res),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeCurrentMeetingPresenter'))
      );
  }

  setFollowMeCurrentMeetingSpeaker(eventId, mainSpeakerSectionId: string, action: IFollowMeMeetingSpeaker) {
    if (!isEmpty(action)) {
      action['datetime'] = new Date().getTime();
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('meeting_speaker')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('meeting_speaker').delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  setFollowMeConferenceGrid(eventId, action: IFollowMeConferenceGrid) {
    if (!isEmpty(action)) {
      action['datetime'] = new Date().getTime();
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('conference_grid')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('conference_grid').delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeConferenceGrid(eventId: string): Observable<IFollowMeConferenceGrid> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('conference_grid')
      .valueChanges()
      .pipe(
        this.log('getFollowMeConferenceGrid'),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeConferenceGrid'))
      );
  }

  setFollowMePoolAction(eventId, action: IFollowMePoolAction) {
    if (!isEmpty(action)) {
      action['datetime'] = new Date().getTime();
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('pool_action')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('pool_action').delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMePoolAction(eventId: string): Observable<IFollowMePoolAction> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('pool_action')
      .valueChanges()
      .pipe(
        this.log('getFollowMePoolAction'),
        catchError(err => this.firestoreCatchError(err, 'getFollowMePoolAction'))
      );
  }

  getFollowMeCurrentMeetingSpeaker(eventId, mainSpeakerSectionId: string): Observable<IFollowMeMeetingSpeaker> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(mainSpeakerSectionId)
      .collection('follow_me_action')
      .doc('meeting_speaker')
      .valueChanges()
      .pipe(
        this.log('getFollowMeCurrentMeetingSpeaker'),
        tap((res: IFollowMeMeetingSpeaker) => res),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeCurrentMeetingSpeaker'))
      );
  }

  setFollowMeCurrentMeetingViewMode(eventId, action: IFollowMeMeetingViewMode) {
    return this.aFirestore.firestore.runTransaction(transaction => {
      const ref = this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('meeting_view_mode').ref;
      return transaction.get(ref).then( object => {
        if (!isEmpty(action)) {
          const actions = !object.data() ? {} : object.data();
          actions[action.sectionId] = action;
          transaction.set(ref, actions, {merge: true});
          // calling method want true as boolean
          return Promise.resolve(true);
        } else {
          transaction.delete(ref);
          // calling method want true as boolean
          return Promise.resolve(true);
        }
      });
    });
  }

  getFollowMeCurrentMeetingViewMode(eventId): Observable<IFollowMeMeetingViewModeMap> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('follow_me_action')
      .doc('meeting_view_mode')
      .valueChanges()
      .pipe(
        this.log('getFollowMeCurrentMeetingViewMode'),
        tap((res: IFollowMeMeetingViewModeMap) => res),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeCurrentMeetingViewMode'))
      );
  }

  setFollowMeCurrentMeetingViewModeSpeaker(eventId, mainSpeakerSectionId: string, action: IFollowMeMeetingViewMode) {
    if (!isEmpty(action)) {
      action['datetime'] = new Date().getTime();
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('meeting_view_mode')
        .set(action, {merge: false})
        .then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(mainSpeakerSectionId)
        .collection('follow_me_action')
        .doc('meeting_view_mode').delete().then(() => Promise.resolve(true))
        .catch((err) => this.throwFirestoreError(err));
    }
  }

  getFollowMeCurrentMeetingViewModeSpeaker(eventId, mainSpeakerSectionId: string): Observable<IFollowMeMeetingViewMode> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(mainSpeakerSectionId)
      .collection('follow_me_action')
      .doc('meeting_view_mode')
      .valueChanges()
      .pipe(
        this.log('getFollowMeCurrentMeetingViewModeSpeaker'),
        tap((res: IFollowMeMeetingViewMode) => res),
        catchError(err => this.firestoreCatchError(err, 'getFollowMeCurrentMeetingViewModeSpeaker'))
      );
  }

  setFollowMeCommand(eventId, command: IFollowMeCommand, sectionId?: string) {
    const eventFollowMeCommand = () => {
      if (!isEmpty(command)) {
        if (!command.time) {
          command.time = new Date().getTime();
        }
        return this.afs.collection('conference')
          .doc(eventId)
          .collection('follow_me_action')
          .doc('command')
          .set(command, {merge: false})
          .then(() => Promise.resolve(true))
          .catch((err) => this.throwFirestoreError(err));
      } else {
        return this.afs.collection('conference')
          .doc(eventId)
          .collection('follow_me_action')
          .doc('command')
          .delete().then(() => Promise.resolve(true))
          .catch((err) => this.throwFirestoreError(err));
      }
    };

    const sectionFollowMeCommand = () => {
      if (!isEmpty(command)) {
        if (!command.time) {
          command.time = new Date().getTime();
        }
        return this.afs.collection('conference')
          .doc(eventId)
          .collection('timeline')
          .doc(sectionId)
          .collection('follow_me_action')
          .doc('command')
          .set(command, {merge: false})
          .then(() => Promise.resolve(true))
          .catch((err) => this.throwFirestoreError(err));
      } else {
        return this.afs.collection('conference')
          .doc(eventId)
          .collection('timeline')
          .doc(sectionId)
          .collection('follow_me_action')
          .doc('command')
          .delete().then(() => Promise.resolve(true))
          .catch((err) => this.throwFirestoreError(err));
      }
    };
    return !sectionId ? eventFollowMeCommand() : sectionFollowMeCommand();
  }

  getFollowMeCommand(eventId: string, sectionId?: string): Observable<IFollowMeCommand> {
    const eventFollowMeCommand = () => this.afs.collection('conference')
        .doc(eventId)
        .collection('follow_me_action')
        .doc('command')
        .valueChanges()
        .pipe(
          this.log('getFollowMeCommand'),
          tap((res: IFollowMeCommand) => res),
          catchError(err => this.firestoreCatchError(err, 'getFollowMeCommand'))
        );
    const sectionFollowMeCommand = () => this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(sectionId)
        .collection('follow_me_action')
        .doc('command')
        .valueChanges()
        .pipe(
          this.log('getFollowMeCommand'),
          tap((res: IFollowMeCommand) => res),
          catchError(err => this.firestoreCatchError(err, 'getFollowMeCommand'))
        );
    return !sectionId ? eventFollowMeCommand() : sectionFollowMeCommand();
  }

  deleteSectionCollectionsData(eventId: string, sectionId: string) {
    return this.aFirestore.firestore.runTransaction(transaction => {
      const actionsRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(sectionId)
        .collection('follow_me_action');
      transaction.delete(actionsRef.doc('action').ref);
      transaction.delete(actionsRef.doc('speaker').ref);
      transaction.delete(actionsRef.doc('full_screen').ref);
      transaction.delete(actionsRef.doc('ext_full_screen').ref);
      transaction.delete(actionsRef.doc('qa_action').ref);
      const notesRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('management_notes')
        .doc(sectionId);
      transaction.delete(notesRef.ref);
      const promises = [];
      promises.push(Promise.resolve());
      promises.push(firstValueFrom(this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(sectionId)
        .collection('chat_messages')
        .get()).then((snap) => {
          const docsObjects = snap.docs;
          for (const doc of docsObjects) {
            transaction.delete(doc.ref);
          }
          return Promise.resolve();
        }));
      promises.push(firstValueFrom(this.afs.collection('notification_tasks', ref =>
        ref.where('eventId', '==', eventId)
           .where('sectionId', '==', sectionId))
        .get()).then((snap) => {
          const docsObjects = snap.docs;
          for (const doc of docsObjects) {
            transaction.delete(doc.ref);
          }
          return Promise.resolve();
        }));
      return Promise.all(promises).then(() => {
        return Promise.resolve();
      });
    });
  }

  deleteQuestionnaireAnswers(eventId: string, parentId: string, contentId: string) {
    return this.aFirestore.firestore.runTransaction(transaction => {
      return firstValueFrom(this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(parentId)
        .collection('contents')
        .doc(contentId).collection('answers')
        .get())
        .then((snapUsersAnswers) => {
          for (const doc  of snapUsersAnswers.docs) {
            transaction.delete(doc.ref);
          }
          // delete questionnaire answers summary
          const sumRef = this.afs.collection(this.getDBPath())
            .doc(eventId)
            .collection('timeline_questionnaire_answers')
            .doc(parentId)
            .collection('contents')
            .doc(contentId).ref;
          transaction.delete(sumRef);
          return Promise.resolve();
        });
    });
  }

  commitCache(cache: IWriteCache[], options: SetOptions = {merge: true}) {
    let batch = this.aFirestore.firestore.batch();
    let promise = Promise.resolve();
    let counter = 0;
    for (const task of cache) {
      promise = promise.then(() => {
        counter++;
        if (!task.action || task.action === 'set') {
          batch.set(task.ref, task.obj, task.options ? task.options : options);
        } else if (task.action === 'delete') {
          batch.delete(task.ref);
        }
        if (counter > 250) {
          return batch.commit().then(() => {
            counter = 0;
            batch = this.aFirestore.firestore.batch();
          });
        }
        return Promise.resolve();
      });
    }
    promise = promise.then(() => {
      if (counter > 0) {
        return batch.commit().then(() => {
          counter = 0;
          batch = this.aFirestore.firestore.batch();
        });
      }
      return Promise.resolve();
    });
    return promise;
  }

  copySectionBackgroundImage(eventId: string, sectionIdFrom: string, sectionIdTo: string) {
    return this.imageRewriteTo(eventId, eventId,
      `image/${sectionIdFrom}/${sectionIdFrom}.png`,
      `image/${sectionIdTo}/${sectionIdTo}.png`).then(() => {
        return this.getDownloadUrl(eventId, `image/${sectionIdTo}/${sectionIdTo}.png`).then((url) => {
          return this.afs.collection('conference')
            .doc(eventId)
            .collection(this.getSectionsDBPath())
            .doc(sectionIdTo)
            .set({backgroundImage: url ? url : null}, {merge: true})
            .catch((err) => this.throwFirestoreError(err));
        }).catch((err) => this.throwFirestoreError(err));
    }).catch((err) => this.throwFirestoreError(err));
  }

  buildQuestionnaireSummary(eventId: string, sectionId: string, contentId: string, deleteSummary: boolean) {
    return this.http
      .post(this.API_URL +
        `buildQuestionnaireSummary?eventId=${eventId}&sectionId=${sectionId}&contentId=${contentId}&deleteSummary=${deleteSummary}`, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  updateSectionsTask(eventId: string, task: ISectionUpdateTask[]) {
    const cache: IWriteCache[] = [];
    for (const t of task) {
      const ref = this.afs.collection('conference')
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(t.sectionId).ref;
      const obj = {[t.fieldName]: t.value};
      cache.push({ref: ref, obj: obj});
    }
    return this.commitCache(cache);
  }

  setPlayerPlaybackState(eventId: string, sectionId: string, state: string, time: number) {
    const client_id = this.auth.client_id$.getValue();
    const pathOrRef = `client_data/${client_id}/conference/${eventId}/playback/${sectionId}`;
    const obj = {
      state: state,
      time: time
    };
    return this.afDB.object(pathOrRef).update(obj);
  }

  getPlayerPlaybackState(eventId: string, sectionId: string) {
    const client_id = this.auth.client_id$.getValue();
    const pathOrRef = `client_data/${client_id}/conference/${eventId}/playback/${sectionId}`;
    return this.afDB.object(pathOrRef).valueChanges();
  }

  resendUserStatusChangeEmails(
    eventId: string, sectionId: string = null, email: string = null, emails: string[] = null, status: string = null
  ) {
    let params;
    if (sectionId) {
      params += `&sectionId=${sectionId}`;
    }
    if (email) {
      params += `${params ? '&' : ''}email=${email}`;
    }
    if (emails) {
      params += `${params ? '&' : ''}emails=${emails}`;
    }
    if (status) {
      params += `${params ? '&' : ''}status=${status}`;
    }
    return this.http.post(this.API_URL + `${eventId}/resendUserStatusChangeEmails?${params}`, null)
      .toPromise();
  }

  saveSectionFeedback(eventId: string, sectionId: string, value: IFeedbackValue) {
    const userId = this.loginService.getAppUser().userId;
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('feedback')
      .doc(userId)
      .set(value, {merge: true})
      .then(() => Promise.resolve(true))
      .catch((err) => this.throwFirestoreError(err));
  }

  getSectionFeedback(eventId: string, sectionId: string): Observable<IFeedbackValue> {
    const userId = this.loginService.getAppUser().userId;
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('feedback')
      .doc(userId)
      .valueChanges()
      .pipe(
        this.log('getSectionFeedback'),
        catchError(err => this.firestoreCatchError(err, 'getSectionFeedback'))
      );
  }

  getSectionSummaryFeedback(eventId: string, sectionId: string): Observable<IFeedbackValue[]> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('feedback')
      .valueChanges()
      .pipe(
        this.log('getSectionSummaryFeedback'),
        tap((res: IFeedbackValue[]) => res),
        catchError(err => this.firestoreCatchError(err, 'getSectionSummaryFeedback'))
      );
  }

  getSectionSummaryFeedbackPromise(eventId: string, sectionId: string): Promise<ISectionFeedbackUserValue[]> {
    return firstValueFrom(this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('feedback')
      .get()
      .pipe(
        this.log('getSectionSummaryFeedbackPromise'),
        map((res: QuerySnapshot<ISectionFeedbackUserValue[]>) =>
          this.mapArray(res.docs, (it) => (new Object({
            sectionId: sectionId, userId: it.id, ...it.data()})) as ISectionFeedbackUserValue)),
        catchError(err => this.firestoreCatchError(err, 'getSectionSummaryFeedbackPromise'))
      ));
  }

  getSectionFeedbackPromise(eventId: string, sectionId: string): Promise<IFeedbackValue> {
    const userId = this.loginService.getAppUser().userId;
    return firstValueFrom(this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('feedback')
      .doc(userId)
      .get()
      .pipe(
        this.log('getSectionFeedbackPromise'),
        map((res: DocumentSnapshot<IFeedbackValue>) => this.mapObject(res.data(), (it) => it as IFeedbackValue)),
        catchError(err => this.firestoreCatchError(err, 'getSectionFeedbackPromise'))
      ));
  }

  getEventUsersByAssistantId(eventId: string, assistantId: string): Observable<any> {
    const pathOrRef = `conference/${eventId}/users_online`;
    return this.afs.collection<ConferenceUser>(pathOrRef, ref => ref.where('assistantId', '==', assistantId))
      .valueChanges({idField: 'userId'})
      .pipe(
        this.log('getEventUsersByAssistantId'),
        catchError(err => this.firestoreCatchError(err, 'getEventUsersByAssistantId'))
      );
  }

  getRegistrationCounters(eventId: string): Observable<any> {
    const pathOrRef = `conference/${eventId}/timeline_counters`;
    return this.afs.collection(pathOrRef)
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getRegistrationCounters'),
        catchError(err => this.firestoreCatchError(err, 'getRegistrationCounters'))
      );
  }

  saveStreamingStatus(eventId: string, streamId: string, status: boolean): Promise<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('streaming')
      .doc(streamId)
      .set({streamStatus: status}, {merge: true});
  }

  getStreamingStatus(eventId: string, streamId: string): Observable<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('streaming')
      .doc(streamId)
      .valueChanges({idField: 'sectionId'})
      .pipe(
        this.log('getStreamingStatus'),
        catchError(err => this.firestoreCatchError(err, 'getStreamingStatus'))
      );
  }

  saveScreenSharingStatus(eventId: string, streamId: string, userId: string, status: boolean): Promise<any> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('streaming')
      .doc(streamId)
      .set({screenSharingStatus: status, screenSharingUserId: userId}, {merge: true});
  }

  setFollowMePlayerPlaybackState(eventId: string, action: IPlayBackAction) {
    const client_id = this.auth.client_id$.getValue();
    const pathOrRef = `client_data/${client_id}/conference/${eventId}/follow_me_action/${action.sectionId}/${action.moduleId}/playback/`;
    return this.afDB.object(pathOrRef).update(action);
  }

  getFollowMePlayerPlaybackState(eventId: string, sectionId: string, moduleId: string) {
    const client_id = this.auth.client_id$.getValue();
    const pathOrRef = `client_data/${client_id}/conference/${eventId}/follow_me_action/${sectionId}/${moduleId}/playback/`;
    return this.afDB.object(pathOrRef).valueChanges();
  }

  private getContainerDocumentPath(params: IDocumentPathParams) {
    return this.dataAfs
      .doc(params.eventId)
      .collection(this.getSectionsDBPath())
      .doc(params.sectionId)
      .collection('contents')
      .doc(params.contentId)
      .collection(ContentContainer.DB_PATH)
      .doc(params.containerId);
  }


  setFollowMePlayerState(documentPathParams: IDocumentPathParams, action) {
    const pathOrRef = `${this.getContainerDocumentPath(documentPathParams).ref.path}/follow_me_action/playback/`;
    if (action) {
      return this.afDB.object(pathOrRef).update(action);
    } else {
      return this.afDB.object(pathOrRef).remove();
    }
  }

  getFollowMePlayerState(documentPathParams: IDocumentPathParams) {
    const pathOrRef = `${this.getContainerDocumentPath(documentPathParams).ref.path}/follow_me_action/playback/`;
    return this.afDB.object(pathOrRef).valueChanges();
  }

  addMeetingUserToDashboard(eventId: string, sectionId: string, userId: string,
                            object: {picture: string, name: string, left: string, top: string}) {
    object['date'] = new Date().getTime();
    object['userId'] = userId;
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('meeting_dashboard')
      .doc(userId)
      .set(object, {merge: true});
  }

  updateMeetingUserOnDashboard(eventId: string, sectionId: string, userId: string, object: {microphone: boolean, webcam: boolean}) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('meeting_dashboard')
      .doc(userId)
      .set(object, {merge: true});
  }

  deleteMeetingUserFromDashboard(eventId: string, sectionId: string, userId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('meeting_dashboard')
      .doc(userId).delete();
  }

  getMeetingDashboardUsers(eventId: string, sectionId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('meeting_dashboard')
      .stateChanges(['added', 'modified', 'removed'])
      .pipe(
        this.log('getMeetingDashboardUsers'),
        map(res => this.mapArray(res, (it) => it.payload)),
        catchError(err => this.firestoreCatchError(err, 'getMeetingDashboardUsers'))
      );
  }

  getMeetingDashboardUsersList(eventId: string, sectionId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('meeting_dashboard')
      .valueChanges()
      .pipe(
        this.log('getMeetingDashboardUsersList'),
        catchError(err => this.firestoreCatchError(err, 'getMeetingDashboardUsersList'))
      );
  }

  addMeetingUserToFocusView(eventId: string, sectionId: string, userId: string, object: {picture: string, name: string}) {
    object['date'] = new Date().getTime();
    object['user_id'] = userId;
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('meeting_focus_view')
      .doc('focus_view_user')
      .set(object, {merge: true});
  }

  deleteMeetingUserFromFocusView(eventId: string, sectionId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('meeting_focus_view')
      .doc('focus_view_user').delete();
  }

  getMeetingFocusViewUser(eventId: string, sectionId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('meeting_focus_view')
      .stateChanges(['added', 'modified', 'removed'])
      .pipe(
        this.log('getMeetingFocusViewUser'),
        map(res => this.mapArray(res, (it) => it.payload)),
        catchError(err => this.firestoreCatchError(err, 'getMeetingFocusViewUser'))
      );
  }

  getMeetingFocusViewCurrentUser(eventId: string, sectionId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('meeting_focus_view')
      .valueChanges()
      .pipe(
        this.log('getMeetingFocusViewCurrentUser'),
        catchError(err => this.firestoreCatchError(err, 'getMeetingFocusViewCurrentUser'))
      );
  }

  setMeetingParams(eventId: string, sectionId: string, params: any) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('meeting_params')
      .doc('params')
      .set(params, {merge: true});
  }

  removeFromQueue(eventId: string, sectionId: string, userId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('queue')
      .doc(userId).delete();
  }

  setQueue(eventId: string, sectionId: string, model: QueueModel) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('queue')
      .doc(model.userId)
      .set(model, {merge: true});
  }

  getQueue(eventId: string, sectionId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('queue')
      .valueChanges()
      .pipe(
        this.log('getQueue'),
        catchError(err => this.firestoreCatchError(err, 'getQueue'))
      );
  }

  setWhoCanHear(eventId: string, sectionId: string, whoCanHearId: string, model: WhoCanHear) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('who_can_hear')
      .doc(whoCanHearId)
      .collection(`users`)
      .doc(model.userId)
      .set(model, {merge: true});
  }

  getWhoCanHear(eventId: string, sectionId: string, whoCanHearId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('who_can_hear')
      .doc(whoCanHearId)
      .collection(`users`)
      .valueChanges()
      .pipe(
        this.log('getWhoCanHear'),
        catchError(err => this.firestoreCatchError(err, 'getWhoCanHear'))
      );
  }

  getMeetingParams(eventId: string, sectionId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('meeting_params')
      .valueChanges()
      .pipe(
        this.log('getMeetingParams'),
        catchError(err => this.firestoreCatchError(err, 'getMeetingParams'))
      );
  }

  saveMeetingRoomParticipants(eventId: string, ownerSectionId: string, roomId: string, roomName: string,
                              action: 'add-user' | 'delete-user' | 'remove-all' | 'create-room', participantId?: string) {
    return this.aFirestore.firestore.runTransaction(transaction => {
      const ref = this.afs.collection('conference')
        .doc(eventId)
        .collection('timeline')
        .doc(ownerSectionId)
        .collection('meeting_rooms')
        .doc(roomId).ref;
      return transaction.get(ref).then(() => {
        let participantsValue;
        switch (action) {
          case 'add-user':
            participantsValue = FieldValue.arrayUnion(participantId);
            break;
          case 'delete-user':
            participantsValue = FieldValue.arrayRemove(participantId);
            break;
          case 'remove-all':
          case 'create-room':
            participantsValue = [];
            break;
          default:
            return;
        }
        const obj = {roomName: roomName, participants: participantsValue};
        transaction.set(ref, obj, {merge: true});
      });
    });
  }

  addUpdateMeetingRoomInDB(eventId: string, ownerSectionId: string, room: IRoom) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(ownerSectionId)
      .collection('meeting_rooms')
      .doc(room.roomId)
      .set(room, {merge: true});
  }

  setConferenceRoomInvite(eventId: string, ownerSectionId: string, invite: ConferenceRoomInvite) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(ownerSectionId)
      .collection('meeting_rooms')
      .doc(invite.roomId)
      .collection(`invites`)
      .doc(invite.inviteId)
      .set(invite, {merge: true});
  }

  getMeetingRoomsParticipants(eventId: string, ownerSectionId: string) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(ownerSectionId)
      .collection('meeting_rooms')
      .valueChanges({idField: 'roomId'})
      .pipe(
        this.log('getMeetingRoomParticipants'),
        mergeMap((rooms => {
          const response = rooms.sort(this.common.utils.comparator('permanent')).map(room => {
            return this.afs.collection('conference')
            .doc(eventId)
            .collection('timeline')
            .doc(ownerSectionId)
            .collection('meeting_rooms')
            .doc(room.roomId)
            .collection('invites')
            .valueChanges({ idField: 'inviteId' })
            .pipe(
              map(invites => {
                return {...room, participants: room.participants || [], invites: invites || []};
              })
            );
          });

          return isEmpty(rooms) ? of([]) : combineLatest(response);
        })),
        catchError(err => this.firestoreCatchError(err, 'getMeetingRoomParticipants'))
      ) as any;
  }

  deleteMeetingRoomParticipants(eventId: string, ownerSectionId: string, roomId: string) {
    return this.aFirestore.firestore.runTransaction(transaction => {
      const roomRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('timeline')
        .doc(ownerSectionId)
        .collection('meeting_rooms')
        .doc(roomId).ref;
      const inviteRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('timeline')
        .doc(ownerSectionId)
        .collection('meeting_rooms')
        .doc(roomId)
        .collection('invites');
      return firstValueFrom(inviteRef.get().pipe(
          this.log('deleteMeetingRoomParticipants (delete)'),
          map((res: QuerySnapshot<DocumentData[]>) =>
            this.mapArray(res.docs, doc => doc.ref)),
          catchError(err => this.firestoreCatchError(err, 'deleteMeetingRoomParticipants (delete)'))
        )
      ).then(refList => {
        const inviteRefList = (refList || []);
        for (const ref of inviteRefList) {
          transaction.delete(ref);
        }
        transaction.delete(roomRef);
        return Promise.resolve();
      });
    });
  }

  deleteAllParticipantsFromMeetingRoom(eventId: string, ownerSectionId: string, roomId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('timeline')
      .doc(ownerSectionId)
      .collection('meeting_rooms')
      .doc(roomId)
      .set({participants: []}, {merge: true});
  }

  joinParticipantToMeetingRoom(eventId: string, ownerSectionId: string, roomId: string, participants: string[]) {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(ownerSectionId)
      .collection('meeting_rooms')
      .doc(roomId)
      .set({participants: participants}, {merge: true});
  }

  saveMeetingRoomPosition(eventId: string, ownerSectionId: string, roomId: string, position: {top: string, left: string}) {
    position['position'] = 'absolute';
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(ownerSectionId)
      .collection('meeting_rooms')
      .doc(roomId)
      .set({position: position}, {merge: true});
  }

  updateEducationModule(moduleId: string, content) {
    const obj = this.toObject(content);
    return this.afs.collection(this.getDBPath())
      .doc(moduleId)
      .set(obj, {merge: true});
  }

  saveUpdateDeleteQAMessages(eventId: string, message: QAMessage, remove: boolean): Promise<string> {
    return this.aFirestore.firestore.runTransaction(transaction => {
      const isNew = !message.messageId;
      if (!message.messageId) {
        message.messageId = this.generateNewDocumentId();
      }
      const docRef = this.afs.collection('conference')
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(message.sectionId)
        .collection('contents')
        .doc(message.contentId)
        .collection('qa_messages')
        .doc(message.messageId).ref;
      const incrementRef = this.afs.collection('conference')
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(message.sectionId)
        .collection('contents')
        .doc(message.contentId)
        .collection('qa_messages_count')
        .doc(message.contentId).ref;
      const childRef = this.afs.collection('conference')
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(message.sectionId)
        .collection('contents')
        .doc(message.contentId)
        .collection('qa_messages', q => q.where('parentMessageId', '==', message.messageId));
      if (!remove) {
        if (isNew) {
          return transaction.get(incrementRef).then(incrementDoc => {
            transaction.set(docRef, message.toObject(), {merge: true});
            transaction.set(incrementRef, {count: incrementDoc?.data() ? FieldValue.increment(1) : 1}, {merge: true});
            return Promise.resolve(message.messageId);
          });
        } else {
          transaction.set(docRef, message.toObject(), {merge: true});
          return Promise.resolve(message.messageId);
        }
      } else {
        return transaction.get(incrementRef).then(incrementDoc => {
          return firstValueFrom(childRef.get().pipe(
              this.log('saveUpdateDeleteQAMessages (delete)'),
              map((res: QuerySnapshot<DocumentData[]>) =>
                this.mapArray(res.docs, doc => doc.ref)),
              catchError(err => this.firestoreCatchError(err, 'saveUpdateDeleteQAMessages (delete)'))
            )
          ).then(refList => {
            const childRefList = (refList || []);
            const childCount = childRefList.length;
            for (const ref of childRefList) {
              transaction.delete(ref);
            }
            transaction.delete(docRef);
            let decrement = true;
            const decrementValue = 1 + childCount;
            if (incrementDoc?.data()?.count && incrementDoc?.data()?.count - decrementValue < 0) {
              decrement = false;
            }
            transaction.set(incrementRef, {count: decrement ? FieldValue.increment(decrementValue * -1) : 0}, {merge: true});
            return Promise.resolve(message.messageId);
          });
        });
      }
    });
  }

  getContentQAMessages(eventId: string, sectionId: string, contentId: string): Observable<DocumentChange<QAMessage>[]> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection('qa_messages')
      .stateChanges(['added', 'modified', 'removed'])
      .pipe(
        this.log('getContentQAMessages'),
        map(res => this.mapArray(res, (it) => it.payload)),
        catchError(err => this.firestoreCatchError(err, 'getContentQAMessages'))
      );
  }

  getContentQAMessagesCount(eventId: string, sectionId: string, contentId: string): Observable<number> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection('qa_messages_count')
      .doc<{count: number}>(contentId)
      .valueChanges()
      .pipe(
        this.log('getContentQAMessagesCount'),
        map(res => res ? res.count : 0),
        catchError(err => this.firestoreCatchError(err, 'getContentQAMessagesCount'))
      );
  }

  addContentContainer(eventId: string, content: any, saveAsDraft?: boolean) {
    return this.addAnyContent(eventId, content, saveAsDraft);
  }

  editContentContainer(eventId: string, timelineId: string, content: any, saveAsDraft: boolean, updateTime: number) {
    return this.editContent(eventId, timelineId, content, false, saveAsDraft, updateTime);
  }

  deleteContentContainer(eventId: string, parentId: string, contentId: string, deleteDraft?: boolean) {
    return this.deleteAnyContent(eventId, parentId, contentId, deleteDraft);
  }

  copyContent(content: AbstractContent, newEventId: string, newParentId: string, newOrderIndex: number,
              copyPinLine: boolean, direction: DIRECTION_COPY_CONTENT): Promise<string> {
    const isSection = [Constants.CONTENT_TYPE_SECTION, Constants.CONTENT_TYPE_TIMELINE].includes(content.type);
    if (isSection && !newOrderIndex) {
      newOrderIndex = this._maxTimelineSectionsOrderIndex.getValue() + 1000000;
      this.setMaxTimelineSectionsOrderIndex(newOrderIndex + 1000000);
    }
    let values = {};
    if (isSection && content['originId']) {
      values = pick(content, SectionContent.SHORTCUT_FIELDS);
    } else {
      values = content;
    }
    return firstValueFrom(this.http.post(this.API_URL + 'copyContent',
      {
        value: {
          eventId: content.eventId,
          contentId: content.id,
          parentId: content.parentId,
          newEventId: newEventId,
          newParentId: newParentId,
          newOrderIndex: newOrderIndex,
          type: content.type,
          dirty: content['dirty'],
          educationMode: this.auth.educationMode,
          copyPinLine: copyPinLine,
          direction: direction,
          fieldValues: values
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  loadSectionChildrenTree(section: AbstractContent, loadFromEducation: boolean): Promise<Object> {
    return firstValueFrom(this.http.post(this.API_URL + 'loadSectionChildrenTree',
      {
        value: {
          section: section,
          loadFromEducation: loadFromEducation,
        }
      },
      {responseType: 'json'}))
      .catch((e) => this.catchServerError(e));
  }

  relocateContentContainer(content: ContentContainer, params: IRelocateParams): Promise<any> {
    const isDraft = content.dirty;
    return this.aFirestore.firestore.runTransaction(transaction => {
      const oldRef = this.afs.collection(this.getDBPath())
        .doc(content.eventId)
        .collection(this.getSectionsDBPath())
        .doc(content.parentId)
        .collection(!isDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
        .doc(content.id).ref;
      return transaction.get(oldRef).then(async data => {
        const doc = data.data();
        doc.eventId = params.eventId;
        doc.parentId = params.parentId;
        doc.orderIndex = params.orderIndex ?? doc.orderIndex;
        doc.updateTime = new Date().getTime();
        const ref = this.afs.collection(this.getDBPath())
          .doc(params.eventId)
          .collection(this.getSectionsDBPath())
          .doc(params.parentId)
          .collection(!isDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
          .doc(content.id).ref;
        transaction.set(ref, doc, {merge: true});
        if (content.parentId !== params.parentId) {
          transaction.delete(oldRef);
          for (const collectionName of CONTENT_CONTAINER_COLLECTIONS) {
            await firstValueFrom(this.afs.collection(this.getDBPath())
              .doc(content.eventId)
              .collection(this.getSectionsDBPath())
              .doc(content.parentId)
              .collection(!isDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
              .doc(content.id)
              .collection(collectionName).get()
              .pipe(catchError(err => this.firestoreCatchError(err, 'relocateContentContainer'))))
              .then(snapshot => {
                for (const clDocData of snapshot.docs) {
                  const clDoc = clDocData.data();
                  const oldDocRef = this.afs.collection(this.getDBPath())
                    .doc(content.eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(content.parentId)
                    .collection(!isDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
                    .doc(content.id)
                    .collection(collectionName)
                    .doc(clDocData.id).ref;
                  const newDocRef = this.afs.collection(this.getDBPath())
                    .doc(params.eventId)
                    .collection(this.getSectionsDBPath())
                    .doc(params.parentId)
                    .collection(!isDraft ? CONTENT_PATH_TYPE.DEFAULT : CONTENT_PATH_TYPE.DRAFT)
                    .doc(content.id)
                    .collection(collectionName)
                    .doc(clDocData.id).ref;
                  transaction.set(newDocRef, clDoc);
                  transaction.delete(oldDocRef);
                }
                return Promise.resolve();
              });
          }
        }
        return this.saveChangeLogValue(content.eventId, {
          sourceId: content.id,
          sourceType: content.type,
          parentId: params.parentId,
          title: content.title,
          changeType: CHANGE_LOG_VALUE_TYPE.RELOCATE
        });
      });
    });
  }

  saveContentDrawnLines(content: AbstractContent, object, personalData = false) {
    const ref = this.afs.collection(this.getDBPath())
      .doc(content.eventId)
      .collection(this.getSectionsDBPath())
      .doc(content.parentId)
      .collection('contents')
      .doc(content.id)
      .collection('drawn-metadata')
      .doc(!personalData ? 'public-metadata' : `${this.loginService.getAppUser().userId}`).ref;
    return this.aFirestore.firestore.runTransaction(transaction => {
      transaction.set(ref, object, {merge: true});
      return Promise.resolve();
    });
  }

  updateContentDrawnLines(content: AbstractContent, object, personalData) {
    const ref = this.afs.collection(this.getDBPath())
      .doc(content.eventId)
      .collection(this.getSectionsDBPath())
      .doc(content.parentId)
      .collection('contents')
      .doc(content.id)
      .collection('drawn-metadata')
      .doc(!personalData ? 'public-metadata' : `${this.loginService.getAppUser().userId}`).ref;
    return this.aFirestore.firestore.runTransaction(transaction => {
      if (!isEmpty(object)) {
        transaction.set(ref, {[Object.keys(object)[0]]: {data: null, text: null}}, {merge: true});
        transaction.set(ref, object, {merge: true});
      } else {
        transaction.delete(ref);
      }
      return Promise.resolve();
    });
  }

  loadContentDrawnLines(content: AbstractContent, personalData) {
    return this.afs.collection(this.getDBPath())
      .doc(content.eventId)
      .collection(this.getSectionsDBPath())
      .doc(content.parentId)
      .collection('contents')
      .doc(content.id)
      .collection('drawn-metadata')
      .doc(!personalData ? 'public-metadata' : `${this.loginService.getAppUser().userId}`)
      .valueChanges()
      .pipe(
        this.log('loadContentDrawnLines'),
        catchError(err => this.firestoreCatchError(err, 'loadContentDrawnLines'))
      );
  }

  saveContentPins(content: AbstractContent, object, personalData = false) {
    const ref = this.afs.collection(this.getDBPath())
      .doc(content.eventId)
      .collection(this.getSectionsDBPath())
      .doc(content.parentId)
      .collection('contents')
      .doc(content.id)
      .collection('pins-metadata')
      .doc(!personalData ? 'public-metadata' : `${this.loginService.getAppUser().userId}`).ref;
    return this.aFirestore.firestore.runTransaction(transaction => {
      transaction.set(ref, object, {merge: true});
      return Promise.resolve();
    });
  }

  updateContentPins(content: AbstractContent, object, personalData) {
    const ref = this.afs.collection(this.getDBPath())
      .doc(content.eventId)
      .collection(this.getSectionsDBPath())
      .doc(content.parentId)
      .collection('contents')
      .doc(content.id)
      .collection('pins-metadata')
      .doc(!personalData ? 'public-metadata' : `${this.loginService.getAppUser().userId}`).ref;
    return this.aFirestore.firestore.runTransaction(transaction => {
      if (!isEmpty(object)) {
        transaction.set(ref, {[Object.keys(object)[0]]: {data: null}}, {merge: true});
        transaction.set(ref, object, {merge: true});
      } else {
        transaction.delete(ref);
      }
      return Promise.resolve();
    });
  }

  loadContentPins(content: AbstractContent, personalData) {
    return this.afs.collection(this.getDBPath())
      .doc(content.eventId)
      .collection(this.getSectionsDBPath())
      .doc(content.parentId)
      .collection('contents')
      .doc(content.id)
      .collection('pins-metadata')
      .doc(!personalData ? 'public-metadata' : `${this.loginService.getAppUser().userId}`)
      .valueChanges()
      .pipe(
        this.log('loadContentPins'),
        catchError(err => this.firestoreCatchError(err, 'loadContentPins'))
      );
  }

  getQaQuestions(eventId: string, sectionId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('contents')
      .valueChanges()
      .pipe(
        this.log('getQaQuestions'),
        catchError(err => this.firestoreCatchError(err, 'getQaQuestions'))
      );
  }

  getContentDocument(documentPathParams: IDocumentPathParams, pathFrom?: string): Promise<firebase.firestore.DocumentSnapshot> {
    return firstValueFrom(this.afs.collection(pathFrom ?? this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId).get());
  }

  sendQuizQuestionAnswer(documentPathParams: IDocumentPathParams, userKey: string, questionId: string, questionType: number,
                         questionAnswersSummaryMethodName: string, questionAnswersSummarySaveType: SUMMARY_SAVE_TYPE,
                         answer: string[], anonymousAnswers: boolean,
                         user: TUser, sendTime: number, properties: any): Promise<any> {
    const userCode = anonymousAnswers ? userKey : this.auth.getAuthUser().uid;
    return firstValueFrom(this.http.post(this.API_URL + 'sendQuizQuestionAnswer',      {
        value: {
          eventId: documentPathParams.eventId,
          sectionId: documentPathParams.sectionId,
          contentId: documentPathParams.contentId,
          containerId: documentPathParams.containerId,
          questionId: questionId,
          questionType: questionType,
          summaryMethodName: questionAnswersSummaryMethodName,
          summarySaveType: questionAnswersSummarySaveType,
          userKey: userCode,
          user: !anonymousAnswers ? {userId: userCode, ...user} : {userId: userCode},
          anonymousAnswers: anonymousAnswers,
          answer: answer,
          sendTime: sendTime,
          location: this.auth.applicationMode,
          properties: properties
        }
      },
      {responseType: 'text'}));
  }

  getQuizQuestionsAnswers(documentPathParams: IDocumentPathParams) {
    return this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId)
      .collection(ContentContainer.DB_PATH)
      .doc(documentPathParams.containerId)
      .collection('answers')
      .valueChanges({idField: 'userId'})
      .pipe(
        this.log('getQuizQuestionsAnswers'),
        catchError(err => this.firestoreCatchError(err, 'getQuizQuestionsAnswers'))
      );
  }

  getQuizQuestionsAnswersByUser(documentPathParams: IDocumentPathParams, userId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId)
      .collection(ContentContainer.DB_PATH)
      .doc(documentPathParams.containerId)
      .collection('answers')
      .doc(userId)
      .valueChanges({idField: 'userId'})
      .pipe(
        this.log('getQuizQuestionsAnswersByUser'),
        catchError(err => this.firestoreCatchError(err, 'getQuizQuestionsAnswersByUser'))
      );
  }

  updateQuiz(documentPathParams: IDocumentPathParams, object: {[name: string]: any}) {
    const ref = this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId).ref;
    return this.aFirestore.firestore.runTransaction(transaction => {
      return transaction.get(ref).then(snapshot => {
        const doc = new ContentContainer(snapshot.data());
        const item: ContentContainerItem = doc.items.find(it => it.id === documentPathParams.containerId);
        if (item) {
          const quiz = new Quiz(item.data);
          Object.keys(object).forEach(name => quiz[name] = object[name]);
          item.data = quiz;
          const obj = doc.toObject();
          transaction.set(ref, obj, {merge: true});
        }
        return Promise.resolve();
      });
    });
  }

  updateQuizQuestion(documentPathParams: IDocumentPathParams, questionId: string, object: {[name: string]: any}) {
    const ref = this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId).ref;
    return this.aFirestore.firestore.runTransaction(transaction => {
      return transaction.get(ref).then(snapshot => {
        const doc = new ContentContainer(snapshot.data());
        const item: ContentContainerItem = doc.items.find(it => it.id === documentPathParams.containerId);
        if (item) {
          const quiz = new Quiz(item.data);
          const question = quiz.questions[questionId];
          Object.keys(object).forEach(name => question[name] = object[name]);
          item.data = quiz;
          const obj = doc.toObject();
          transaction.set(ref, obj, {merge: true});
        }
        return Promise.resolve();
      });
    });
  }

  buildQuizSummary(clientId: string, documentPathParams: IDocumentPathParams) {
    return firstValueFrom(this.http.post(this.API_URL + 'buildQuizSummary',      {
        value: {
          clientId: clientId,
          eventId: documentPathParams.eventId,
          sectionId: documentPathParams.sectionId,
          contentId: documentPathParams.contentId,
          containerId: documentPathParams.containerId,
          education: this.loginService.educationMode
        }
      },
      {responseType: 'text'}));
  }

  getQuizSummary(documentPathParams: IDocumentPathParams) {
    return this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId)
      .collection(ContentContainer.DB_PATH)
      .doc(documentPathParams.containerId)
      .collection('quiz_answers_summary')
      .doc('answers_summary')
      .valueChanges()
      .pipe(
        this.log('getQuizSummary'),
        catchError(err => this.firestoreCatchError(err, 'getQuizSummary'))
      );
  }

  getQuizTextSummary(documentPathParams: IDocumentPathParams) {
    return this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId)
      .collection(ContentContainer.DB_PATH)
      .doc(documentPathParams.containerId)
      .collection('quiz_answers_summary')
      .doc('text_answers_summary')
      .valueChanges()
      .pipe(
        this.log('getQuizTextSummary'),
        catchError(err => this.firestoreCatchError(err, 'getQuizTextSummary'))
      );
  }

  checkQuizQuestionHasAnswers(questionId: string, questionType: number, documentPathParams: IDocumentPathParams): Promise<boolean> {
    const summaryName = questionType !== Constants.QTYPE_WORD_CLOUD && questionType !== Constants.QTYPE_TEXT && questionType !== Constants.QTYPE_TEXT_BALLON ?
      'answers_summary' : 'text_answers_summary';
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId)
      .collection(ContentContainer.DB_PATH)
      .doc(documentPathParams.containerId)
      .collection('quiz_answers_summary')
      .doc(summaryName)
      .get())
      .then(object => {
        return !isEmpty(((object.data() || {})['questions'] || {})[questionId]);
      });
  }

  checkQuizHasAnswers(documentPathParams: IDocumentPathParams): Promise<boolean> {
    const summaryNames = ['answers_summary', 'text_answers_summary'];
    const summary: Promise<any>[] = [];
    for (const smn of summaryNames) {
      summary.push(firstValueFrom(this.afs.collection(this.getDBPath())
        .doc(documentPathParams.eventId)
        .collection(this.getSectionsDBPath())
        .doc(documentPathParams.sectionId)
        .collection('contents')
        .doc(documentPathParams.contentId)
        .collection(ContentContainer.DB_PATH)
        .doc(documentPathParams.containerId)
        .collection('quiz_answers_summary')
        .doc(smn)
        .get()));
    }
    return Promise.all(summary).then(results => {
      return results.some(r => !isEmpty(r.data()));
    });
  }

  getQuizConstraint(documentPathParams: IDocumentPathParams): Promise<IQuizConstraint> {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection('timeline_questionnaire_dependency')
      .doc(documentPathParams.contentId)
      .get()
      .pipe(
        this.log('getQuizConstraint'),
        map(res => this.mapObject(res, (it) => new Object(it.data()))),
        catchError(err => this.firestoreCatchError(err, 'getQuizConstraint'))
      ));
  }

  deleteQuizUserAnswers(userId: string, documentPathParams: IDocumentPathParams,
                        questionTypes: {[questionId: string]: {methodName: string, saveType: SUMMARY_SAVE_TYPE}}) {
    return firstValueFrom(this.http.post(this.API_URL + 'deleteQuizUserAnswers',      {
        value: {
          eventId: documentPathParams.eventId,
          sectionId: documentPathParams.sectionId,
          contentId: documentPathParams.contentId,
          containerId: documentPathParams.containerId,
          userId: userId,
          questionTypes: questionTypes,
          location: this.auth.applicationMode
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  deleteQuizAllAnswers(documentPathParams: IDocumentPathParams) {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId)
      .collection(ContentContainer.DB_PATH)
      .doc(documentPathParams.containerId)
      .collection('answers')
      .snapshotChanges()).then(async snap => {
      for (const obj of snap) {
        await obj.payload.doc.ref.delete();
      }
      return this.afs.collection(this.getDBPath())
        .doc(documentPathParams.eventId)
        .collection(this.getSectionsDBPath())
        .doc(documentPathParams.sectionId)
        .collection('contents')
        .doc(documentPathParams.contentId)
        .collection(ContentContainer.DB_PATH)
        .doc(documentPathParams.containerId)
        .delete();
    }).then(() => this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId)
      .collection(ContentContainer.DB_PATH)
      .doc(documentPathParams.containerId)
      .collection('quiz_answers_summary')
      .doc('answers_summary').delete())
      .then(() => this.afs.collection(this.getDBPath())
        .doc(documentPathParams.eventId)
        .collection(this.getSectionsDBPath())
        .doc(documentPathParams.sectionId)
        .collection('contents')
        .doc(documentPathParams.contentId)
        .collection(ContentContainer.DB_PATH)
        .doc(documentPathParams.containerId)
        .collection('quiz_answers_summary')
        .doc('text_answers_summary').delete())
      .then(() => this.storageDataService.deleteContainerUsersFolderObjectsFromStorageByDataPath(
        documentPathParams.eventId, documentPathParams.contentId, documentPathParams.containerId));
  }

  async deleteContainerDocumentWithInsideCollections(documentPathParams: IDocumentPathParams, collectionList: string[]): Promise<boolean> {
    return this.aFirestore.firestore.runTransaction(async transaction => {
      for (const collectionName of collectionList) {
        await firstValueFrom(this.afs.collection(this.getDBPath())
          .doc(documentPathParams.eventId)
          .collection(this.getSectionsDBPath())
          .doc(documentPathParams.sectionId)
          .collection('contents')
          .doc(documentPathParams.contentId)
          .collection(ContentContainer.DB_PATH)
          .doc(documentPathParams.containerId)
          .collection(collectionName)
          .snapshotChanges()
          .pipe(
            this.log(`deleteContainerDocumentWithInsideCollections/${collectionName}`),
            catchError(err => this.firestoreCatchError(err, `deleteContainerDocumentWithInsideCollections/${collectionName}`))
          )).then(async objects => {
            for (const obj of objects || []) {
              const payload: firebase.firestore.DocumentChange = obj.payload;
              transaction.delete(payload.doc.ref);
            }
        });
      }
      const containerRef = this.afs.collection(this.getDBPath())
        .doc(documentPathParams.eventId)
        .collection(this.getSectionsDBPath())
        .doc(documentPathParams.sectionId)
        .collection('contents')
        .doc(documentPathParams.contentId)
        .collection(ContentContainer.DB_PATH)
        .doc(documentPathParams.containerId).ref;
      transaction.delete(containerRef);
      return Promise.resolve(true);
    }).catch(e => this.throwFirestoreError(e));
  }

  async deleteContainerDocumentWithInsideCollectionsWithStoreData(documentPathParams: IDocumentPathParams,
                                                                  collectionList: IStorageCollectionPath[]): Promise<boolean> {
    const storagePath = () => `${documentPathParams.eventId}/${documentPathParams.contentId}/${documentPathParams.containerId}`;

    const deleteFile = (path: string) => this.aFirestore.firestore.app.storage().ref(`${this.clientStorageDataPath}/${path}`).delete()
      .catch((e) => {
        if (e.code === 'storage/object-not-found') {
          return Promise.resolve();
        } else {
          return Promise.reject(e);
        }
      });

    return this.aFirestore.firestore.runTransaction(async transaction => {
      for (const collection of collectionList) {
        await firstValueFrom(this.afs.collection(this.getDBPath())
          .doc(documentPathParams.eventId)
          .collection(this.getSectionsDBPath())
          .doc(documentPathParams.sectionId)
          .collection('contents')
          .doc(documentPathParams.contentId)
          .collection(ContentContainer.DB_PATH)
          .doc(documentPathParams.containerId)
          .collection(collection.collectionName)
          .snapshotChanges()
          .pipe(
            this.log(`deleteContainerDocumentWithInsideCollectionsWithStoreData/${collection.collectionName}`),
            catchError(err => this.firestoreCatchError(err, `deleteContainerDocumentWithInsideCollectionsWithStoreData/${collection.collectionName}`))
          )).then(async objects => {
          for (const obj of objects || []) {
            const payload: firebase.firestore.DocumentChange = obj.payload;
            const data = payload.doc.data();
            if (!isEmpty(data[collection.storageDataFiledName]) && Array.isArray(data[collection.storageDataFiledName])) {
              const storageData = data[collection.storageDataFiledName];
              for (const pathData of storageData) {
                const deletePath = `${storagePath()}/${collection.pathSuffix}/${data[collection.pathDataFieldNameSuffix]}/${pathData.id}`;
                await deleteFile(deletePath);
              }
            }
            transaction.delete(payload.doc.ref);
          }
        });
      }
      const containerRef = this.afs.collection(this.getDBPath())
        .doc(documentPathParams.eventId)
        .collection(this.getSectionsDBPath())
        .doc(documentPathParams.sectionId)
        .collection('contents')
        .doc(documentPathParams.contentId)
        .collection(ContentContainer.DB_PATH)
        .doc(documentPathParams.containerId).ref;
      transaction.delete(containerRef);
      return Promise.resolve(true);
    }).catch(e => this.throwFirestoreError(e));
  }

  relocateContainerDocumentInsideCollections(oldDocumentPathParams: IDocumentPathParams, newDocumentPathParams: IDocumentPathParams,
                                             collectionList: string[]): Promise<boolean> {
    return this.aFirestore.firestore.runTransaction(async transaction => {
      if (oldDocumentPathParams.sectionId !== newDocumentPathParams.sectionId) {
        const newRef = this.afs.collection(this.getDBPath())
          .doc(newDocumentPathParams.eventId)
          .collection(this.getSectionsDBPath())
          .doc(newDocumentPathParams.sectionId)
          .collection('contents')
          .doc(newDocumentPathParams.contentId)
          .collection(ContentContainer.DB_PATH)
          .doc(newDocumentPathParams.containerId).ref;
        transaction.set(newRef, {containerId: newDocumentPathParams.containerId});

        for (const collectionName of collectionList) {
          await firstValueFrom(this.afs.collection(this.getDBPath())
            .doc(oldDocumentPathParams.eventId)
            .collection(this.getSectionsDBPath())
            .doc(oldDocumentPathParams.sectionId)
            .collection('contents')
            .doc(oldDocumentPathParams.contentId)
            .collection(ContentContainer.DB_PATH)
            .doc(oldDocumentPathParams.containerId)
            .collection(collectionName).get()
            .pipe(catchError(err => this.firestoreCatchError(err, 'relocateContainerDocumentInsideCollections'))))
            .then(snapshot => {
              for (const clDocData of snapshot.docs) {
                const clDoc = clDocData.data();
                const oldDocRef = this.afs.collection(this.getDBPath())
                  .doc(oldDocumentPathParams.eventId)
                  .collection(this.getSectionsDBPath())
                  .doc(oldDocumentPathParams.sectionId)
                  .collection('contents')
                  .doc(oldDocumentPathParams.contentId)
                  .collection(ContentContainer.DB_PATH)
                  .doc(oldDocumentPathParams.containerId)
                  .collection(collectionName)
                  .doc(clDocData.id).ref;
                const newDocRef = this.afs.collection(this.getDBPath())
                  .doc(newDocumentPathParams.eventId)
                  .collection(this.getSectionsDBPath())
                  .doc(newDocumentPathParams.sectionId)
                  .collection('contents')
                  .doc(newDocumentPathParams.contentId)
                  .collection(ContentContainer.DB_PATH)
                  .doc(newDocumentPathParams.containerId)
                  .collection(collectionName)
                  .doc(clDocData.id).ref;
                transaction.set(newDocRef, clDoc);
                transaction.delete(oldDocRef);
              }
              return Promise.resolve();
            });
        }

        const oldRef = this.afs.collection(this.getDBPath())
          .doc(oldDocumentPathParams.eventId)
          .collection(this.getSectionsDBPath())
          .doc(oldDocumentPathParams.sectionId)
          .collection('contents')
          .doc(oldDocumentPathParams.contentId)
          .collection(ContentContainer.DB_PATH)
          .doc(oldDocumentPathParams.containerId).ref;
        transaction.delete(oldRef);
      }
      return Promise.resolve(true);
    });
  }

  getTaskUserAnswers(documentPathParams: IDocumentPathParams) {
    return this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId)
      .collection(ContentContainer.DB_PATH)
      .doc(documentPathParams.containerId)
      .collection('users_answers')
      .doc(this.loginService.getAppUser().userId)
      .valueChanges({idField: 'userId'})
      .pipe(
        this.log('getTaskUserAnswers'),
        catchError(err => this.firestoreCatchError(err, 'getTaskUserAnswers'))
      );
  }

  userSubmitTask(documentPathParams: IDocumentPathParams, userTaskObject: any) {
    const storagePath = () => `${documentPathParams.eventId}/${documentPathParams.contentId}/${documentPathParams.containerId}`;

    const uploadFile = (path: string, imageName: string, base64: string, contentType: string) => {
      const reference = this.storage.storage.ref(`${this.clientStorageDataPath}/${path}/${imageName}`);
      const index = base64.indexOf(';base64,');
      base64 = base64.substr(index + ';base64,'.length);
      return reference.putString(base64, 'base64', {contentType: contentType});
    };

    const deleteFile = (path: string) => this.aFirestore.firestore.app.storage().ref(`${this.clientStorageDataPath}/${path}`).delete()
        .catch((e) => {
          if (e.code === 'storage/object-not-found') {
            return Promise.resolve();
          } else {
            return Promise.reject(e);
          }
        });

    const obj = cloneDeep(userTaskObject instanceof UserTaskObject ? userTaskObject.toObject() : userTaskObject);
    if (!obj?.userId) {
      throw new Error('User id cannot be null.');
    }
    let files: ITaskFile[];
    if (!isEmpty(obj.files)) {
      files = cloneDeep(obj.files);
      obj.files = [];
    }
    return this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId)
      .collection(ContentContainer.DB_PATH)
      .doc(documentPathParams.containerId)
      .collection('users_answers')
      .doc(obj.userId)
      .set(obj, {merge: true})
      .then(async () => {
        if (!isEmpty(files)) {
          const deletedIds: string[] = [];
          const path = `${storagePath()}/users_answers/${obj.userId}`;
          for (const it of files) {
            if (it.id.startsWith(ITEM_MARKER.NEW)) {
              await uploadFile(path, it.id.replace(ITEM_MARKER.NEW, ''), it.src, it.metaType)
                .then(snapshot => snapshot.ref.getDownloadURL()
                  .catch(e => this.common.log.error(e))
                  .then(url => it.src = url))
                .catch(e => this.common.log.error(e));
            } else if (it.id.startsWith(ITEM_MARKER.DELETED)) {
              await deleteFile(`${path}/${it.id.replace(ITEM_MARKER.DELETED, '')}`)
                .catch(e => this.common.log.error(e));
            }
            it.id = it.id.replace(ITEM_MARKER.NEW, '');
            if (it.id.startsWith(ITEM_MARKER.DELETED)) {
              deletedIds.push(it.id);
            }
          }
          for (const id of deletedIds) {
            const index = files.findIndex(it => it.id === id);
            files.splice(index, 1);
          }
          obj.files = files;
        }
        return Promise.resolve(obj);
      }).then(() => {
        return this.afs.collection(this.getDBPath())
          .doc(documentPathParams.eventId)
          .collection(this.getSectionsDBPath())
          .doc(documentPathParams.sectionId)
          .collection('contents')
          .doc(documentPathParams.contentId)
          .collection(ContentContainer.DB_PATH)
          .doc(documentPathParams.containerId)
          .collection('users_answers')
          .doc(obj.userId)
          .set(obj, {merge: true})
          .catch((err) => this.throwFirestoreError(err));
      })
      .catch((err) => this.throwFirestoreError(err));
  }

  loadAllUsersTaskAnswers(documentPathParams: IDocumentPathParams) {
    return this.afs.collection(this.getDBPath())
      .doc(documentPathParams.eventId)
      .collection(this.getSectionsDBPath())
      .doc(documentPathParams.sectionId)
      .collection('contents')
      .doc(documentPathParams.contentId)
      .collection(ContentContainer.DB_PATH)
      .doc(documentPathParams.containerId)
      .collection('users_answers')
      .valueChanges()
      .pipe(
        this.log('loadAllUsersTaskAnswers'),
        catchError(err => this.firestoreCatchError(err, 'loadAllUsersTaskAnswers'))
      );
  }

  incrementRegistered(): Promise<any> {
    return firstValueFrom(this.http.post(this.API_URL + 'increment', null))
      .catch((e) => this.catchServerError(e));
  }

  copyContentToMyKnowledge(params: ICopyTask) {
    return firstValueFrom(this.http.post(this.API_URL + 'copyContentToMyNotes',
      {
        value: {
          eventId: params.eventId,
          parentId: params.parentId,
          contentId: params.contentId,
          direction: DIRECTION_COPY_CONTENT.TIMELINE_TO_MY_NOTES,
          itemsIdList: params.itemsIdList
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  setSectionSubtargetRating(eventId: string, sectionId: string, subtargetOrder: string, rating: string) {
    return this.aFirestore.firestore.runTransaction(transaction => {
      const docRef = this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection(this.getSectionsDBPath())
        .doc(sectionId)
        .collection('subtarget_rating')
        .doc(this.loginService.getAppUser().userId)
        .ref;
      const obj = {
        [subtargetOrder]: rating ?? null
      };
      transaction.set(docRef, obj, {merge: true});
      return Promise.resolve(subtargetOrder);
    });
  }

  getSectionSubtargetRatingByUser(eventId: string, sectionId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('subtarget_rating')
      .doc(this.loginService.getAppUser().userId)
      .valueChanges()
      .pipe(
        this.log('getSectionSubtargetRatingByUser'),
        catchError(err => this.firestoreCatchError(err, 'getSectionSubtargetRatingByUser'))
      );
  }

  getSectionSubtargetRatingSummary(eventId: string, sectionId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection('subtarget_rating')
      .doc('summary_rating')
      .valueChanges()
      .pipe(
        this.log('getSectionSubtargetRatingSummary'),
        catchError(err => this.firestoreCatchError(err, 'getSectionSubtargetRatingSummary'))
      );
  }

  getEncodedBase64ContentContainerItem(params: IExtDocumentPathParams, base64Scheme: IURLToBASE64Scheme): Promise<Object> {
    const extParams = new Object({...params, base64Scheme: base64Scheme});
    return firstValueFrom(this.http.post(this.API_URL + 'getEncodedBase64ContentContainerItem',
      {
        value: extParams
      },
      {responseType: 'json'}))
      .catch((e) => this.catchServerError(e));
  }

  getEncodedBase64Path(eventId: string, path: string): Promise<Object> {
    return firstValueFrom(this.http.post(this.API_URL + 'getEncodedBase64Path',
      {
        value: {eventId, path: `${this.auth.client_id$.getValue()}/${path}`}
      },
      {responseType: 'json'}))
      .catch((e) => this.catchServerError(e));
  }

  copyEducationChangesToTimeline(params: IUpdateChangesParams) {
    return firstValueFrom(this.http.post(this.API_URL + 'copyEducationChangesToTimeline',
      {
        value: params
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  getEventSummaryByCountry(eventId: string): Observable<ICountryStatisticsMap> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection('statistics')
      .doc('country_statistics')
      .valueChanges()
      .pipe(
        this.log('getEventSummaryByCountry'),
        tap((res: ICountryStatisticsMap) => res),
        catchError(err => this.firestoreCatchError(err, 'getEventSummaryByCountry'))
      );
  }

  checkEventTimelineStructure(eventId: string, logPath: string) {
    return firstValueFrom(this.http.post(this.API_URL + 'checkEventTimelineStructure',
      {
        value: {
          eventId: eventId,
          logPath: logPath
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  createContentByTemplate(params: IExtCopyTask) {
    return firstValueFrom(this.http.post(this.API_URL + 'createContentByTemplate',
      {
        value: {
          eventId: params.eventId,
          parentId: params.parentId,
          contentId: params.contentId,
          itemsIdList: params.itemsIdList,
          shiftToTop: params.shiftToTop,
          educationMode: params.educationMode,
          contentTitle: params.contentTitle,
          orderIndex: params.orderIndex,
          dirty: params.dirty
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  recalcOrderIndex(eventId: string) {
    return firstValueFrom(this.http.post(this.API_URL + `${eventId}/orderIndex`, null))
      .catch((e) => this.catchServerError(e));
  }

  getLockedSectionRef(eventId: string, sectionId: string) {
    return this.afDB.database
      .ref(`client_data/${this.auth.client_id$.getValue()}/${Event.DB_TIMELINE_PATH}/${eventId}/process_log/${sectionId}`);
  }

  convertContentToContentContainer(content: AbstractContent) {
    return firstValueFrom(this.http.post(this.API_URL + 'convertContentToContentContainer', {
        value: {
          eventId: content.eventId,
          sectionId: content.parentId,
          contentId: content.id,
          education: this.loginService.educationMode,
          dirty: content['dirty']
        }
      },
      {responseType: 'text'}));
  }

  isExistsContent(content: ContentContainer, contentPath: CONTENT_PATH_TYPE): Promise<ContentContainer | null> {
    return firstValueFrom(this.afs
      .collection(this.getDBPath())
      .doc(content.eventId)
      .collection(this.getSectionsDBPath())
      .doc(content.parentId)
      .collection(contentPath)
      .doc(content.id).get())
      .then(snap => snap.exists ? new ContentContainer({id: snap.id, ...snap.data()}) : null);
  }

  getSectionContentsByCondition(eventId: string, sectionId: string, condition: QueryFn, contentPath: CONTENT_PATH_TYPE) {
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection(this.getSectionsDBPath())
      .doc(sectionId)
      .collection(contentPath, condition)
      .get())
      .then(snap => snap.docs.length ? snap.docs.map(d => new Object({id: d.id, ...d.data()})) : []);
  }

  updateContentFields(content: AbstractContent, params: {[fieldName: string]: any}, contentPath: CONTENT_PATH_TYPE) {
    const ref = this.afs.collection(this.getDBPath())
      .doc(content.eventId)
      .collection(this.getSectionsDBPath())
      .doc(content.parentId)
      .collection(contentPath)
      .doc(content.id);
    return ref.set(params, {merge: true}).catch((err) => this.throwFirestoreError(err));

  }

  saveChangeLogValue(eventId: string, value: IChangeLogValue) {
    const obj = {time: new Date(), userId: this.loginService.getAppUser().userId, ...value};
    Object.keys(obj).forEach(key => {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    });
    return this.loginService.educationMode ?
      this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('actions')
        .doc('changelog')
        .set({changelog: FieldValue.arrayUnion(obj)},
          {merge: true})
        .catch((err) => this.throwFirestoreError(err))
      :
      Promise.resolve();
  }

  getChangeLog(eventId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('actions')
      .doc('changelog')
      .get()
      .pipe(
        this.log('getChangeLog'),
        map(res => {
          return res.exists ? res.data()['changelog'] : null;
        }),
        catchError(err => this.firestoreCatchError(err, 'getChangeLog'))
      );
  }

  saveChangeLog(eventId: string, changelog: IChangeLogValue[]) {
    return this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('actions')
        .doc('changelog')
        .set({changelog: changelog}, {merge: false})
        .catch((err) => this.throwFirestoreError(err));
  }

  saveChangeLogBackup(eventId: string, version: string, changelog: IChangeLogValue[]) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('changelog')
      .doc(version.replace('/', '_'))
      .set({changelog: changelog});
  }

  async backupChangeLog(eventId: string, version: string) {
    const changeLog: IChangeLogValue[] = await firstValueFrom(this.getChangeLog(eventId));
    return !isEmpty(changeLog) ?
      this.afs.collection(this.getDBPath())
        .doc(eventId)
        .collection('changelog')
        .doc(version.replace('/', '_'))
        .set({changelog: changeLog})
      :
      Promise.resolve();
  }

  deleteChangeLog(eventId: string) {
    return this.afs.collection(this.getDBPath())
      .doc(eventId)
      .collection('actions')
      .doc('changelog').delete();
  }

  getUrlTimelineContentInModule(link: IContentEntityLink) {
    return firstValueFrom(this.getContentsShort(link.entityId, Event.DB_MODULE_TIMELINE_PATH))
      .then(moduleMap => {
        const sid = Object.keys(moduleMap).find(id => !!moduleMap[id][link.contentId]);
        if (sid) {
          return {url: `/module/${link.entityId}?sid=${sid}&cid=${link.contentId}`, sectionId: sid};
        }
        return null;
      });
  }

  updateContentFromModule(timelineContent: AbstractContent, moduleContent: AbstractContent) {
    return firstValueFrom(this.http.post(this.API_URL + 'updateContentFromModule',
      {
        value: {
          eventId: timelineContent.eventId,
          eventSectionId: timelineContent.parentId,
          eventContentId: timelineContent.id,
          moduleId: moduleContent.eventId,
          moduleSectionId: moduleContent.parentId,
          moduleContentId: moduleContent.id
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  watchFollowMe(eventId: string, uid: string, sectionId?: string) {
    const sectionPath = sectionId ? `&sectionId=${sectionId}` : '';
    return this.http.post(this.API_URL + `${eventId}/watch-followme?uid=${uid}${sectionPath}`, null)
      .pipe(
        catchError(err => this.catchServerErrorObservable(err))
      );
  }

  checkUserHasDRMRightsToDeleteSectionContents(section: SectionContent) {
    if (this.loginService.getAppUser().isAdmin || this.loginService.getAppUser().isSuperAdmin || this.loginService.educationMode) {
      return Promise.resolve(true);
    }
    return firstValueFrom(this.afs.collection(this.getDBPath())
      .doc(section.eventId)
      .collection(this.getSectionsDBPath())
      .doc(section.id)
      .collection(CONTENT_PATH_TYPE.DEFAULT, ref => ref.where('drmMode', '>', '').where('drmMode', '!=', DRM_MODE.DISABLED))
      .valueChanges({idField: 'id'}).pipe(
        map(res => {
          return isEmpty(res?.filter(v => !!v['creator']?.userId && v['creator']?.userId !== this.loginService.getAppUser().userId));
        }),
        catchError(err => this.firestoreCatchError(err, 'checkUserHasDRMRightsToDeleteSectionContents'))
      ));
  }

  saveQuizTemplate(templateId: string, templateType: TEMPLATE_TYPE, template: IQuizTemplate) {
    switch (templateType) {
      case TEMPLATE_TYPE.USER_TEMPLATE:
        return this.afs.collection('app_users')
          .doc(this.loginService.getAppUser().userId)
          .collection('quiz-template')
          .doc(templateId)
          .set(template);
      case TEMPLATE_TYPE.PUBLISHED_COMPANY:
        return this.afs.collection('quiz-template')
          .doc(templateId)
          .set(template);
    }
  }

  deleteQuizTemplate(templateId: string, templateType: TEMPLATE_TYPE) {
    switch (templateType) {
      case TEMPLATE_TYPE.USER_TEMPLATE:
        return this.afs.collection('app_users')
          .doc(this.loginService.getAppUser().userId)
          .collection('quiz-template')
          .doc(templateId)
          .delete();
      case TEMPLATE_TYPE.PUBLISHED_COMPANY:
        return this.afs.collection('quiz-template')
          .doc(templateId)
          .delete();
    }
  }

  loadQuizTemplatesList(templateType: TEMPLATE_TYPE) {
    switch (templateType) {
      case TEMPLATE_TYPE.USER_TEMPLATE:
        return this.afs.collection('app_users')
          .doc(this.loginService.getAppUser().userId)
          .collection('quiz-template')
          .valueChanges({idField: 'id'})
          .pipe(
            this.log('loadQuizTemplatesList (users)'),
            catchError(err => this.firestoreCatchError(err, 'loadQuizTemplatesList (users)'))
          );
      case TEMPLATE_TYPE.PUBLISHED_COMPANY:
        return this.afs.collection('quiz-template')
          .valueChanges({idField: 'id'})
          .pipe(
            this.log('loadQuizTemplatesList (public)'),
            catchError(err => this.firestoreCatchError(err, 'loadQuizTemplatesList (public)'))
          );
    }
  }

  loadQuizTemplate(templateId: string, templateType: TEMPLATE_TYPE): Observable<IQuizTemplate> {
    switch (templateType) {
      case TEMPLATE_TYPE.USER_TEMPLATE:
        return this.afs.collection('app_users')
          .doc(this.loginService.getAppUser().userId)
          .collection('quiz-template')
          .doc(templateId).get()
          .pipe(
            this.log('loadQuizTemplate (users)'),
            map((res: DocumentSnapshot<IQuizTemplate>) => this.mapObject(res.data(), (it) => it)),
            catchError(err => this.firestoreCatchError(err, 'loadQuizTemplate (users)'))
          );
      case TEMPLATE_TYPE.PUBLISHED_COMPANY:
        return this.afs.collection('quiz-template')
          .doc(templateId).get()
          .pipe(
            this.log('loadQuizTemplate (public)'),
            map((res: DocumentSnapshot<IQuizTemplate>) => this.mapObject(res.data(), (it) => it)),
            catchError(err => this.firestoreCatchError(err, 'loadQuizTemplate (public)'))
          );
    }
  }

  saveQuizQuestionAnswersProperties(documentPathParams: IDocumentPathParams, userKey: string, questionId: string,
                                    anonymousAnswers: boolean, applicationMode: APP_MODE, properties: any): Promise<any> {
    const userCode = anonymousAnswers ? userKey : this.auth.getAuthUser().uid;
    return firstValueFrom(this.http.post(this.API_URL + 'saveQuizQuestionAnswersProperties', {
        value: {
          eventId: documentPathParams.eventId,
          sectionId: documentPathParams.sectionId,
          contentId: documentPathParams.contentId,
          containerId: documentPathParams.containerId,
          questionId: questionId,
          userKey: userCode,
          anonymousAnswers: anonymousAnswers,
          location: applicationMode,
          properties: properties
        }
      },
      {responseType: 'text'}));
  }

  listenUpdateTimelineFromEducationLog(eventId: string) {
    const client_id = this.auth.client_id$.getValue();
    return this.afDB.list(`client_data/${client_id}/conference/${eventId}/update_from_education_log`,
        q => q.orderByKey().limitToLast(1)).valueChanges();
  }

  listenEventActivity() {
    const client_id = this.auth.client_id$.getValue();
    return this.afDB.object(`client_data/${client_id}/events_activity`).valueChanges();
  }

  listenEngagedSessions(startAt: number) {
    const client_id = this.auth.client_id$.getValue();
    return this.afDB.list(`client_data/${client_id}/engaged_sessions`, q => q.orderByChild('dateTime').startAt(startAt)).valueChanges();
  }

  listenEngagedSessionsTotal() {
    const client_id = this.auth.client_id$.getValue();
    return this.afDB.list(`client_data/${client_id}/engaged_sessions_total`).stateChanges()
      .pipe(
        filter(v => v.payload.key === 'count'),
        map(v => new Object({[v.payload.key]: v.payload.val()})
        ));
  }
}
