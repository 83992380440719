import {QuizQuestionSheetComponent} from './quiz-question-sheet.component';
import {Constants} from '../../../../../core/constants';
import {cloneDeep, isEmpty} from 'lodash';
import {EventQuestion} from '../../../../../model/EventQuestion';
import {
  FEEDBACK_DIALOG_TYPE,
  FONT_SIZE_CSS_PROPERTY,
  IQuizContentAnswers,
  Quiz,
  QUIZ_FONT_SIZE,
  QUIZ_FONT_SIZE_VALUES
} from '../quiz-model/quiz';
import {ElementRef} from '@angular/core';
import {questionUseCheckingCorrectnessOfAnswer} from '../quiz-components/shared/lib/quiz-question-common-lib';

const currentUserId = (quiz: QuizQuestionSheetComponent) => !quiz.qContent.isAnonymousAnswers() ? quiz.currentUserId : quiz.currentUserKey;

export const afterViewInit = (quiz: QuizQuestionSheetComponent) => {
  const pel = quiz.tgroup._elementRef.nativeElement.firstElementChild;
  pel.appendChild(quiz.questionHeader.nativeElement);
  quiz.tabHeader = quiz.tgroup._elementRef.nativeElement.getElementsByClassName('mat-mdc-tab-header');
  const tabs: HTMLCollection = quiz.tgroup._elementRef.nativeElement.getElementsByClassName('mat-mdc-tab-label-container');
  quiz.paginatorBefore = quiz.tgroup._elementRef.nativeElement.getElementsByClassName('mat-mdc-tab-header-pagination-before');
  quiz.paginatorAfter = quiz.tgroup._elementRef.nativeElement.getElementsByClassName('mat-mdc-tab-header-pagination-after');
  if (tabs?.length && !quiz.isFullscreen) {
    tabs.item(0).setAttribute('style', `border-radius: 4px; ${isEmpty(quiz.questionList) ? 'background: unset' : ''}`);
    const div = document.createElement('paginator-tab-container');
    div.setAttribute('style', 'display: flex; padding-left: 2%; padding-right: 2%; margin-top: 2px; margin-bottom: 3px;');
    const newElem = quiz.tgroup._elementRef.nativeElement.appendChild(div);
    newElem.appendChild(quiz.paginatorBefore.item(0));
    newElem.appendChild(tabs.item(0));
    newElem.appendChild(quiz.paginatorAfter.item(0));
    setPaginatorButtonVisible(quiz);
  } else if (tabs?.length && !quiz.isPresenter && quiz.isFullscreen) {
    const div = document.createElement('paginator-tab-container');
    div.setAttribute('style', 'display: flex;' +
      (quiz.isFullscreen ? 'background: linear-gradient(to bottom, white, #d6d6d685 50%, #b5b5b5b8 100%); margin-top: 9px;' : ''));
    if (quiz.isPresenter && quiz.isFullscreen) {
      tabs.item(0).setAttribute('style', 'background: none; padding-left: 0; padding-right: 0;');
    }
    const newElem = quiz.tgroup._elementRef.nativeElement.appendChild(div);
    const divBefore = document.createElement('before-button-wrapper');
    divBefore.setAttribute('style',
      'background: linear-gradient(to bottom, white, #cecece00 100%); width: 54px; padding-left: 5px;');
    divBefore.appendChild(quiz.paginatorBefore.item(0));
    newElem.appendChild(divBefore);
    newElem.appendChild(tabs.item(0));
    const divAfter = document.createElement('after-button-wrapper');
    divAfter.setAttribute('style',
      'background: linear-gradient(to bottom, white, #cecece00 100%); width: 54px; padding-left: 9px;');
    divAfter.appendChild(quiz.paginatorAfter.item(0));
    newElem.appendChild(divAfter);
    setPaginatorButtonVisible(quiz);
  }
};

export const afterViewChecked = (quiz: QuizQuestionSheetComponent) => {
  if (quiz.questionReceived && quiz.questionList$.getValue().length !== quiz.localQuestionCount) {
    quiz.questionReceived = false;
    if (quiz.questionList && (Object.keys(quiz.questionList).length === 1)) {
      quiz.localQuestionCount = 0;
      const pel = quiz.tgroup._elementRef.nativeElement.firstElementChild;
      for (const value of pel.childNodes) {
        if (value.className === 'mat-mdc-tab-label-container') {
          value.style.height = 0;
          quiz.headerOffset = 0;
        }
        if (value.id === 'questionHeader') {
          value.style.marginTop = 0;
        }
      }
    } else {
      if (quiz.localQuestionCount === 0 && 0 < Object.keys(quiz.questionList).length) {
        quiz.localQuestionCount = Object.keys(quiz.questionList).length;
        const pel = quiz.tgroup._elementRef.nativeElement.firstElementChild;
        for (const value of pel.childNodes) {
          if (document.body.clientWidth <= Constants.MEDIA_MAX_WIDTH &&
            value.className.indexOf('mat-mdc-tab-header-pagination') > -1) {
            value.style.height = 0;
          }
          if (value.className === 'mat-mdc-tab-label-container') {
            value.style.height = 'auto';
            if (quiz.isFullscreen) {
              value.style.marginLeft = '2%';
            } else {
              value.style.marginLeft = '2%';
            }
            quiz.headerOffset = 0;
          }
          if (value.id === 'buttonCustom') {
            value.hidden = false;
          }
          if (value.id === 'questionHeader') {
            value.style.marginTop = '5px';
          }
        }
      }
    }
  } else {
    if (!quiz.isFullscreen && quiz.questionList && (Object.keys(quiz.questionList).length !== 1)) {
      const pel = quiz.tgroup._elementRef.nativeElement.firstElementChild;
    }
  }
  setPaginatorButtonVisible(quiz);
};

const setPaginatorButtonVisible = (quiz: QuizQuestionSheetComponent) => {
  const changeTab = (val) => {
    if (quiz.tgroup.selectedIndex > -1 && quiz.tgroup.selectedIndex < quiz.questionList$.getValue().length) {
      quiz.tgroup.selectedIndex = quiz.tgroup.selectedIndex + val;
    }
  };

  if (quiz.tabHeader) {
    const mth = quiz.tabHeader[0];
    if (mth && !quiz.isFullscreen) {
      if (mth.className.includes('mat-mdc-tab-header-pagination-controls-enabled')) {
        let style = (quiz.paginatorBefore[0] as Element).getAttribute('style');
        if (!style || !style.includes(' display: flex;')) {
          style = (!style ? '' : style) + ' display: flex;';
          (quiz.paginatorBefore[0] as Element).setAttribute('style', style);
          style = (quiz.paginatorAfter[0] as Element).getAttribute('style');
          style = (!style ? '' : style) + ' display: flex;';
          (quiz.paginatorAfter[0] as Element).setAttribute('style', style);
        }
      } else {
        let style = (quiz.paginatorBefore[0] as Element).getAttribute('style');
        if (style && style.includes(' display: flex;')) {
          style = style.replace(' display: flex;', '');
          (quiz.paginatorBefore[0] as Element).setAttribute('style', style);
          style = (quiz.paginatorAfter[0] as Element).getAttribute('style');
          style = style.replace(' display: flex;', '');
          (quiz.paginatorAfter[0] as Element).setAttribute('style', style);
        }
      }
    } else if (mth && !quiz.isPresenter && quiz.isFullscreen && quiz.questionList$.getValue().length > 1) {
      let style = (quiz.paginatorBefore[0] as Element).getAttribute('style');
      if (!style || !style.includes(' display: flex;')) {
        style = (!style ? '' : style) + ' display: flex; cursor: pointer; color: white;' +
          'min-width: 45px !important;' +
          'width: 45px; height: 45px; align-items: center; font-size: 16px; border-radius: 50%;' +
          'box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.1); padding: 0;';
        (quiz.paginatorBefore[0] as Element).setAttribute('style', style);
        style = (quiz.paginatorAfter[0] as Element).getAttribute('style');
        style = (!style ? '' : style) + ' display: flex; cursor: pointer; color: white;' +
          'min-width: 45px !important;' +
          'width: 45px; height: 45px; align-items: center; font-size: 16px; border-radius: 50%;' +
          'box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.1); padding: 0;';
        (quiz.paginatorAfter[0] as Element).setAttribute('style', style);
      }
      const beforeChevron = (quiz.paginatorBefore[0] as Element).getElementsByClassName('mat-mdc-tab-header-pagination-chevron');
      if (beforeChevron) {
        let styleChevron = (beforeChevron[0] as Element).getAttribute('style');
        if (!styleChevron || !styleChevron.includes(' display: none;')) {
          styleChevron = (!styleChevron ? '' : styleChevron) + ' display: none;';
          (beforeChevron[0] as Element).setAttribute('style', styleChevron);
          (quiz.paginatorBefore[0] as Element).addEventListener('click', {
            handleEvent(event) {
              changeTab(-1);
              quiz.cdr.markForCheck();
            }
          });
          const divArrow = document.createElement('i');
          divArrow.classList.value = 'material-icons scroll-icon-full-screen prev';
          divArrow.innerText = 'play_arrow';
          (quiz.paginatorBefore[0] as Element).appendChild(divArrow);
        }
      }
      const afterChevron = (quiz.paginatorAfter[0] as Element).getElementsByClassName('mat-mdc-tab-header-pagination-chevron');
      if (afterChevron) {
        let styleChevron = (afterChevron[0] as Element).getAttribute('style');
        if (!styleChevron || !styleChevron.includes(' display: none;')) {
          styleChevron = (!styleChevron ? '' : styleChevron) + ' display: none;';
          (afterChevron[0] as Element).setAttribute('style', styleChevron);
          (quiz.paginatorAfter[0] as Element).addEventListener('click', {
            handleEvent(event) {
              changeTab(1);
              quiz.cdr.markForCheck();
            }
          });
          const divArrow = document.createElement('i');
          divArrow.classList.value = 'material-icons scroll-icon-full-screen';
          divArrow.innerText = 'play_arrow';
          (quiz.paginatorAfter[0] as Element).appendChild(divArrow);
        }
      }
    }
  }
};

const applyDependency = (quiz: QuizQuestionSheetComponent, questionsIdLis: string[]): string[] => {
  const result: string[] = [];
  const questions = quiz.qContent.questions;
  questionsIdLis.forEach(qKey => {
    const cq = questions[qKey];
    const question = new EventQuestion(cq);
    const dependency = question.getDependency();
    if (dependency) {
      if (dependency.dependencyConditionShowIf) {
        const answers = ((quiz.answers$.getValue()?.answers || {})[dependency.questionId] || {})[currentUserId(quiz)];
        if (dependency.dependencyResult(answers)) {
          result.push(question.id);
        }
      } else
      if (dependency.dependencyConditionHideIf) {
        const answers = ((quiz.answers$.getValue()?.answers || {})[dependency.questionId] || {})[currentUserId(quiz)];
        if (!dependency.dependencyResult(answers)) {
          result.push(question.id);
        }
      }
    } else {
      result.push(question.id);
    }
  });
  return result;
};

export const updateQuestionKeyList = (quiz: QuizQuestionSheetComponent) => {
  quiz.questionList = quiz.qContent?.questions ?? {};
  let newQuestionList: EventQuestion[] = [];
  if (!isEmpty(quiz.qContent?.questions || {})) {
    newQuestionList = Object.values(quiz.qContent.questions).sort(quiz.common.utils.comparator(Constants.ORDERINDEX));
    if (!quiz.isPresenter) {
      const depList = applyDependency(quiz, newQuestionList.map(q => q.id));
      newQuestionList = newQuestionList.filter(q => depList.includes(q.id));
    }
    if (quiz.currentQIndex > newQuestionList.length - 1) {
      quiz.currentQIndex = newQuestionList.length - 1;
    }
    quiz.currentQKey$.next(newQuestionList[quiz.currentQIndex].id);
    if (quiz.tgroup) {
      quiz.tgroup.selectedIndex = quiz.currentQIndex;
    }
  } else {
    quiz.currentQIndex = 0;
    quiz.currentQKey$.next(null);
  }
  quiz.questionList$.next(newQuestionList.map(obj => new EventQuestion(obj)) );
};

export const acceptAnswersChange = (value: boolean, quiz: QuizQuestionSheetComponent) => {
  return quiz.dataService.updateQuiz(quiz.documentPathParams, {'acceptAnswers': value})
    .catch(e => quiz.common.log.error(e));
};

export const showCorrectAnswersChange = (value: boolean, quiz: QuizQuestionSheetComponent) => {
  quiz.dataService.updateQuizQuestion(quiz.documentPathParams, quiz.currentQKey$.getValue(), {'showCorrectAnswers': value})
    .catch(e => quiz.common.log.error(e));
};

export const showResultChange = (value: boolean, quiz: QuizQuestionSheetComponent) => {
  return quiz.dataService.updateQuiz(quiz.documentPathParams, {'showResult': value});
};

// reset correct answers status information icon for quiz with only one question and enabled feedback option
export const resetSingleQuestionQuizFeedbackStatusIcon = (quiz: QuizQuestionSheetComponent) => {
  if (quiz.questionList$.getValue()?.length === 1 &&
    quiz.questionsDirectFeedback[quiz.questionList$.getValue()[0]?.id]?.result &&
    !quiz.questionsDirectFeedback[quiz.questionList$.getValue()[0]?.id]?.result?.checkResult) {
    delete quiz.questionsDirectFeedback[quiz.questionList$.getValue()[0]?.id];
  }
};

export const updateQuestionsFeedback = (quiz: QuizQuestionSheetComponent) => {
  for (const question of quiz.questionList$.getValue()) {
    if (question.directFeedback && questionUseCheckingCorrectnessOfAnswer(question)) {
      const answers = ((quiz.answers$.getValue()?.answers || {})[question.id] || {})[currentUserId(quiz)];
      const answersJSON = !isEmpty(answers) ? JSON.stringify(answers) : null;
      const feedback = quiz.questionsDirectFeedback[question.id];
      if (!feedback || !answersJSON) {
        quiz.questionsDirectFeedback[question.id] = {
          result: question.checkCorrectAnswers(answers, quiz.languageParams$.getValue()),
          isFeedbackShowed: true,
          answersJSON: answersJSON,
          manualChecked: false
        };
      }
    }
  }
};

export const showDirectFeedback = (quiz: QuizQuestionSheetComponent, questionIndex: number) => {
  if (!quiz.isPresenter) {
    const question = quiz.questionList$.getValue()[questionIndex];
    if (question.directFeedback && questionUseCheckingCorrectnessOfAnswer(question) &&
      quiz.questionsDirectFeedback[question.id]) {
      const feedback = quiz.questionsDirectFeedback[question.id];
      const answers = ((quiz.answers$.getValue()?.answers || {})[question.id] || {})[currentUserId(quiz)];
      const answersJSON = !isEmpty(answers) ? JSON.stringify(answers) : null;
      if (!!answersJSON && feedback.answersJSON !== answersJSON) {
        feedback.result = question.checkCorrectAnswers(answers, quiz.languageParams$.getValue());
        feedback.isFeedbackShowed = false;
        feedback.answersJSON = answersJSON;
        feedback.manualChecked = false;
        if (feedback.result) {
          if (!feedback.result.checkResult) {
            quiz.common.toaster.pop('error', null, quiz.common.utils.i18n(feedback.result.feedbackMessage));
          } else {
            quiz.common.toaster.pop('success', null, quiz.common.utils.i18n(feedback.result.feedbackMessage));
          }
          feedback.isFeedbackShowed = true;
        }
      }
    }
  }
};

const getFeedbackMessage = (quiz: QuizQuestionSheetComponent) => {
  const feedbackList = Object.values(quiz.questionsDirectFeedback);
  for (const feedback of feedbackList) {
    if (feedback.result && !feedback.result.checkResult) {
      return feedback.result.feedbackMessage;
    }
  }
  if (feedbackList.length > 1) {
    return 'edit_dialog.question_dialog.input.direct.feedback.correct.message';
  } else {
    return feedbackList[0] ? feedbackList[0].result?.feedbackMessage : 'edit_dialog.question_dialog.input.direct.feedback.correct.message';
  }
};

export const finalizeDirectFeedback = (quiz: QuizQuestionSheetComponent) => {
  if (!quiz.isPresenter && quiz.directFeedbackAnswersChanged && !isEmpty(quiz.questionsDirectFeedback)) {
    Object.keys(quiz.questionsDirectFeedback).forEach(qId => {
      const question = quiz.questionList$.getValue().find(q => q.id === qId);
      if (question) {
        const answers = ((quiz.answers$.getValue()?.answers || {})[question.id] || {})[currentUserId(quiz)];
        quiz.questionsDirectFeedback[qId].result = question.checkCorrectAnswers(answers, quiz.languageParams$.getValue());
        quiz.questionsDirectFeedback[qId].isFeedbackShowed = true;
        quiz.questionsDirectFeedback[qId].answersJSON = answers ? JSON.stringify(answers) : null;
      }
    });
    if (Object.keys(quiz.qContent.questions).some(qId => quiz.qContent.questions[qId].useCorrectAnswers) &&
      (Object.values(quiz.questionsDirectFeedback).every(fe => fe.isFeedbackShowed ||
        Object.values(quiz.questionsDirectFeedback).some(fs => !fs.isFeedbackShowed))) &&
      Object.values(quiz.questionsDirectFeedback).every(f => !!f.answersJSON) &&
      Object.values(quiz.questionsDirectFeedback).some(fe => !isEmpty(fe.result))) {
      const feedbackType = Object.values(quiz.questionsDirectFeedback).some(o => !isEmpty(o.result) && !o.result.checkResult) ?
        FEEDBACK_DIALOG_TYPE.TRY_AGAIN : FEEDBACK_DIALOG_TYPE.CONTINUE;
      const feedbackMessage = getFeedbackMessage(quiz);
      quiz.quizFinalizeServiceService.registerFeedback(quiz.documentPathParams.containerId, feedbackMessage, feedbackType);
    }
  }
};

export const checkUserShowResultParam = (value: Quiz, quiz: QuizQuestionSheetComponent) => {
  if (quiz.userShowResult && quiz.qContent.showResult && !value?.showResult) {
    quiz.userShowResult = false;
  }
};

export const isQuizUsedFeedback = (quiz: QuizQuestionSheetComponent) => {
   quiz.hasAnswersFeedback = Object.values(quiz.qContent.questions || {}).some(q => q.directFeedback &&
     questionUseCheckingCorrectnessOfAnswer(q));
   quiz.tryAgain = Object.values(quiz.qContent.questions || {}).some(q => q.tryAgain &&
      questionUseCheckingCorrectnessOfAnswer(q));
};

export const checkAnswersFeedbackChange = (quiz: QuizQuestionSheetComponent, manualCheck = false) => {
  for (const q of quiz.questionList$.getValue()) {
    if (q.directFeedback && questionUseCheckingCorrectnessOfAnswer(q)) {
      const answers = ((quiz.answers$.getValue()?.answers || {})[q.id] || {})[currentUserId(quiz)];
      if (!isEmpty(answers)) {
        quiz.questionsDirectFeedback[q.id].result = q.checkCorrectAnswers(answers, quiz.languageParams$.getValue());
        quiz.questionsDirectFeedback[q.id].isFeedbackShowed = true;
        quiz.questionsDirectFeedback[q.id].answersJSON = answers ? JSON.stringify(answers) : null;
        quiz.questionsDirectFeedback[q.id].manualChecked = manualCheck;
      } else {
        delete quiz.questionsDirectFeedback[q.id];
      }
    } else {
      delete quiz.questionsDirectFeedback[q.id];
    }
  }
  quiz.directFeedbackAnswersChanged = false;
};

export const setQuizElementProperties = (quiz: Quiz, element: ElementRef) => {
  (element.nativeElement as HTMLElement).style
    .setProperty(FONT_SIZE_CSS_PROPERTY, quiz.options?.fontSize ?? QUIZ_FONT_SIZE_VALUES[QUIZ_FONT_SIZE.NORMAL]);
};

export const setQuestionProperties = (quiz: QuizQuestionSheetComponent, questionId: string, userKey: string,
                                      properties: IQuizContentAnswers) => {
  if (!quiz.learnMode) {
    return;
  }
  const qProperties = properties?.[questionId]?.[userKey] || {};
  if (!isEmpty(qProperties)) {
    quiz.quizService.qProperties$.next({
      properties: cloneDeep(qProperties),
      questionId: questionId
    });
  }
};

export const setCheckResult = (quiz: QuizQuestionSheetComponent, questionId: string) => {
  if (!quiz.learnMode) {
    return;
  }
  const qFeedbackElem = quiz.questionsDirectFeedback[questionId];
  if (qFeedbackElem) {
    const result = qFeedbackElem.result;
    if (result) {
      quiz.quizService.isAnswerCorrect$.next({
        isCorrect: result.checkResult,
        questionId: questionId
      });
    }
  }
};

export const setQuestionResults = (quiz: QuizQuestionSheetComponent, userKey: string, properties: any) => {
  if (!quiz.learnMode) {
    return;
  }
  if (quiz.hasCurrentUserAnyAnswers()) {
    const questionId = Object.keys(quiz.questionList)[0] || null;
    if (questionId) {
      quiz.quizService.isAnswerSelected$.next({ isAnswerSelected: true, questionId: questionId });
      setQuestionProperties(quiz, questionId, userKey, properties);

      if (quiz.questionList[questionId].showCorrectAnswers) {
        setCheckResult(quiz, questionId);
      }
    }
  }
};
