import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Constants, DATE_FORMAT, ILanguageParams, USER_LIST_PAGE} from '../../../../../core/constants';
import {StdComponent} from '../../../../../core/std-component';
import {ResultConferenceUser} from '../../../../../model/event-mode/ConferenceUser';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {UntypedFormBuilder} from '@angular/forms';
import {CommonService} from '../../../../../core/common.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import {EventsDataService} from '../../../../../services/events-data.service';
import {UtilsService} from '../../../../../core/utils.service';
import {ContentDetailService} from '../../../../../event-mode/event-mode/content-detail/content-detail.service';
import {TimeLineService} from '../../../../../services/time-line.service';
import {ContentService} from '../../../../../services/content.service';
import {RegistrationProcessService} from '../../../../../services/registration-process.service';
import {DomSanitizer} from '@angular/platform-browser';
import {clone, isEmpty, union} from 'lodash';
import {ANONYMOUS_ANSWERS_MODE, IQuizContentAnswersExtended, Quiz} from '../quiz-model/quiz';
import {TUser} from '../../../shared/container-interface';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {IDocumentPathParams} from '../../../../../services/event-mode-api.service';
import {EventQuestion} from '../../../../../model/EventQuestion';
import {QUESTION_TYPES_COMPONENTS} from '../quiz-components/shared/quiz-quiestion-types';

interface IFilter {
  statusFilter: number;
  userRoleFilter: number;
  sortType: number;
  searchValue: string;
}

interface IUsersMap {
  [userId: string]: TUser;
}

@Component({
  selector: 'app-quiz-question-sheet-participants-detail',
  templateUrl: './quiz-question-sheet-participants-detail.component.html',
  styleUrls: ['./quiz-question-sheet-participants-detail.component.scss']
})
export class QuizQuestionSheetParticipantsDetailComponent extends StdComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly Constants = Constants;

  @Input() set qContent(value: Quiz) {
    this._qContent = value;
    this.isAnonymousResults = this.qContent.anonymousAnswersMode !== ANONYMOUS_ANSWERS_MODE.NON_ANONYMOUS;
    this.getResultSet();
  }

  @Input() set answersByQuestions(value: IQuizContentAnswersExtended) {
    this._qContentAnswers = value ? value.answers : {};
    this.lastChangeUsersAnswers = value ? value.lastChange : {};
    this.users = Object.keys(value ? value.users : {}).reduce((acc, qId) => {
      const ul = value.users[qId];
      Object.keys(ul).forEach(uid => acc[uid] = ul[uid]);
      return acc;
    }, {});
    this.getResultSet();
  }

  @Input() set languageParams(value: ILanguageParams) {
    this._languageParams = value;
    if (this.qContent) {
      this.getResultSet();
    }
  }

  @Input() documentPathParams: IDocumentPathParams;

  @Output() showDetails = new EventEmitter<boolean>();

  _qContent: Quiz;
  _qContentAnswers = {};
  lastChangeUsersAnswers = {};
  _languageParams: ILanguageParams;
  users: IUsersMap = {};
  presenters: any[] = [];
  baseDisplayedColumns: string[] = ['check', 'userProgress', 'picture', 'userName'];
  baseDisplayedColumnsAnonymous: string[] = ['userProgress'];
  displayedColumns: string[] = this.baseDisplayedColumns.slice();
  displayedColumnsAnonymous: string[] = this.baseDisplayedColumnsAnonymous.slice();
  displayedColumnsQ: string[] = [];
  baseDisplayedMediaColumns = ['check', 'userProgress', 'picture', 'userName'];
  baseDisplayedMediaColumnsAnonymous = ['userProgress'];
  displayedMediaColumns = this.baseDisplayedMediaColumns.slice();
  displayedMediaColumnsAnonymous = this.baseDisplayedMediaColumnsAnonymous.slice();
  baseCsvColumnsHeader: string[] = ['User', 'Last change'];
  columnsTitlesTranslated: { [key: string]: string } = {};
  csvColumnsHeader: string[] = this.baseCsvColumnsHeader.slice();
  csvColumnsHeaderAnonymous: string[] = [];
  filter: IFilter = {
    statusFilter: Constants.QSTATUS_ALL_STATUS,
    userRoleFilter: Constants.QROLE_ALL_USERS,
    sortType: Constants.QSORT_NONE,
    searchValue: null
  };
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  pageLength = USER_LIST_PAGE.INIT_PAGE_LENGTH;
  paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), this.cdr);

  rowCheckAll = false;
  hasSelected = false;
  isAnonymousResults = false;
  overlayContainer: HTMLElement;
  elementHelper = document.createElement('div');
  private overlayContainerObserver = new MutationObserver(() => {
    const elements: HTMLCollection = this.overlayContainer.getElementsByClassName('mdc-tooltip__surface');
    if (elements.length > 0) {
      const tooltip = elements.item(0) as HTMLElement;
      // matTooltip does not know how to show HTML content, so we hide it.
      if (tooltip.innerText.match(/(<\/div>)|(<\/span>)/)) {
        tooltip.style.display = 'none';
      }
    }
  });

  @ViewChild(MatSort) sort: MatSort;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<QuizQuestionSheetParticipantsDetailComponent>,
              private formBuilder: UntypedFormBuilder,
              private common: CommonService,
              public dialog: MatDialog,
              private store: Store<fromRoot.State>,
              private dataService: EventsDataService,
              public utils: UtilsService,
              private cdr: ChangeDetectorRef,
              private contentDetailService: ContentDetailService,
              public timeLineService: TimeLineService,
              public contentService: ContentService,
              private regProcessService: RegistrationProcessService,
              private sanitizer: DomSanitizer,
              private elementRef: ElementRef) {
    super(injector);
    dialogRef.disableClose = true;
    dialogRef.addPanelClass('timeline');
    dialogRef.addPanelClass('questions-participants-detail');
    dialogRef.updateSize('96vw', '96vh');
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE) {
          this.dialogRef.close();
        }
      });
  }

  get qContent() {
    return this._qContent;
  }

  get qContentAnswers() {
    return this._qContentAnswers;
  }

  get languageParams(): ILanguageParams {
    return this._languageParams;
  }

  ngOnInit() {
    this.paginator.pageSize = this.pageLength;
    this.paginator.ngOnInit();
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.filterData;
    this.dataSource.filter = JSON.stringify(this.filter);
    this.dataSource.connect()
      .pipe(this.takeUntilAlive())
      .subscribe(() => {
        this.checkAllRowChange();
        this.hasSelected = this.dataSource.filteredData.some(r => r.check);
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const elements: HTMLCollection = document.getElementsByClassName('cdk-overlay-container');
    if (elements.length > 0) {
      this.overlayContainer = elements.item(0) as HTMLElement;
      this.overlayContainerObserver.observe(this.overlayContainer, {childList: true, subtree: true});
    }
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
    this.overlayContainerObserver.disconnect();
  }

  isSuperUser() {
    return this.timeLineService.isSuperAdmin(this.timeLineService.currentUser);
  }

  public getResultSet() {
    this.displayedColumnsQ = Object.keys(this.qContent.questions)
      .map(qId => this.qContent.questions[qId])
      .sort(this.utils.comparator(Constants.ORDERINDEX))
      .map(q => q.id);

    for (const column of this.displayedColumnsQ) {
      this.columnsTitlesTranslated[column] = this.getColumnCaption(this.qContent.questions[column]);
    }

    if (this.isAnonymousResults) {
      this.displayedColumnsAnonymous = union(this.baseDisplayedColumnsAnonymous, this.displayedColumnsQ);
      this.displayedMediaColumnsAnonymous = union(this.baseDisplayedMediaColumnsAnonymous, this.displayedColumnsQ);
    } else {
      this.displayedColumns = union(this.baseDisplayedColumns, this.displayedColumnsQ);
      this.displayedMediaColumns = union(this.baseDisplayedMediaColumns, this.displayedColumnsQ);
    }
    this.elementRef.nativeElement.style.setProperty('--questions-count', this.displayedColumnsQ.length);
    const qCaptions = [];
    for (const qKey of this.displayedColumnsQ) {
      qCaptions.push(this.qContent.questions[qKey].getCaptionByLanguage(this.languageParams));
    }
    if (this.isAnonymousResults) {
      this.csvColumnsHeaderAnonymous = qCaptions;
    } else {
      this.csvColumnsHeader = union(this.baseCsvColumnsHeader, qCaptions);
    }

    const initData = {};
    const deleteEmptyRow = (row: any, answers: {}) => !Object.keys(answers).length && delete initData[row.userId];
    if (this.isAnonymousResults) {
      const aUsers = this.getAnonymousUsers();
      for (const row of aUsers) {
        const procstat = this.getUserStatus(row.userId, row.email);
        initData[row.userId] = {
          userProgress: procstat[1],
          lastChange: this.utils.formatDate(this.getLastChange(row), DATE_FORMAT.DD_MM_YYYY_HH_mm),
          userId: row.userId,
          userStatus: procstat[0], userRole: row.userRole, email: row.email
        };

        const answers = this.getUserAnswers(row);
        for (const key of Object.keys(answers)) {
          initData[row.userId][key] = answers[key];
        }
        if (!Object.keys(answers).length) {
          delete initData[row.userId];
        }
        deleteEmptyRow(row, answers);
      }
    } else {
      const uIds = Object.keys(this.users);
      for (const id of uIds) {
        const row = new ResultConferenceUser({userId: id, ...this.users[id]});
        const procstat = this.getUserStatus(row.userId, row.email);
        const prev = this.dataSource.data.find(d => d.userId === row.userId);
        initData[row.userId] = {
          check: prev?.check,
          userProgress: procstat[1],
          picture: this.getUserPicture(row),
          userName: this.getFullName(row),
          lastChange: this.utils.formatDate(this.getLastChange(row), DATE_FORMAT.DD_MM_YYYY_HH_mm),
          userId: row.userId,
          userStatus: procstat[0],
          userRole: row.userRole,
          email: row.email,
          gradient: this.getSanitizedGradient({userProgress: procstat[1]})
        };
        const answers = this.getUserAnswers(row);
        for (const key of Object.keys(answers)) {
          initData[row.userId][key] = answers[key];
        }
        deleteEmptyRow(row, answers);
      }
    }
    this.dataSource.data = Object.values(initData);
  }

  getAnonymousUsers() {
    const ids = [];
    const qKeys = Object.keys(this.qContent.questions);
    for (const qKey of qKeys) {
      const q = this.qContent.questions[qKey];
      const answers = this.qContentAnswers && this.qContentAnswers[qKey] ? this.qContentAnswers[qKey] : {};
      if (answers) {
        const us = Object.keys(answers);
        for (const u of us) {
          if (ids.indexOf(u) === -1) {
            ids.push(u);
          }
        }
      }
    }
    const res = [];
    for (const id of ids) {
      res.push({userId: id});
    }
    return res;
  }

  filterData(data, filter: string) {
    const obj: IFilter = JSON.parse(filter);

    const statusFilter = () => {
      if (obj.statusFilter === Constants.QSTATUS_ALL_STATUS) {
        return true;
      }
      if (obj.statusFilter === Constants.QSTATUS_PARTLY_ANSWERED && data.userStatus === Constants.QSTATUS_PARTLY_ANSWERED) {
        return true;
      }
      if (obj.statusFilter === Constants.QSTATUS_ANSWERED && data.userStatus === Constants.QSTATUS_ANSWERED) {
        return true;
      }
      if (obj.statusFilter === Constants.QSTATUS_NOT_ANSWERED && data.userStatus === Constants.QSTATUS_NOT_ANSWERED) {
        return true;
      }
      return false;
    };

    const roleFilter = () => {
      if (obj.userRoleFilter === Constants.QROLE_ALL_USERS) {
        return true;
      }
      if (obj.userRoleFilter === Constants.QROLE_PARTICIPANTS && data.userRole === Constants.QROLE_PARTICIPANTS) {
        return true;
      }
      if (obj.userRoleFilter === Constants.QROLE_VIEWERS && data.userRole === Constants.QROLE_VIEWERS) {
        return true;
      }
      if (obj.userRoleFilter === Constants.QROLE_PRESENTERS && data.userRole === Constants.QROLE_PRESENTERS) {
        return true;
      }
      return false;
    };

    const searchFilter = () => {
      if (!obj.searchValue) {
        return true;
      }
      if ((data.userName && data.userName.toLowerCase().indexOf(obj.searchValue.toLowerCase()) > -1) ||
        (data.email && data.email.toLowerCase().indexOf(obj.searchValue.toLowerCase()) > -1)) {
        return true;
      }
      return false;
    };

    return statusFilter() && roleFilter() && searchFilter();
  }

  private getUserStatus(uId: string, email: string): number[] {
    const questions = this.qContent.getStrictQuestions();
    const ret: number[] = [Constants.QSTATUS_NOT_ANSWERED, 0];
    if (questions) {
      for (const qk of Object.keys(questions)) {
        if (questions[qk]) {
          const answers = this.qContentAnswers && this.qContentAnswers[qk] ? this.qContentAnswers[qk] : {};
          if (answers) {
            if (this.utils.getValueByIdOrEmail(answers, uId, email)) {
              ret[0]++;
            }
          }
        }
      }
      ret[1] = ret[0] * 100 / Object.keys(questions).length;
      ret[0] = ret[0] === Constants.QSTATUS_NOT_ANSWERED ?
        Constants.QSTATUS_NOT_ANSWERED : (ret[0] < Object.keys(questions).length ?
          Constants.QSTATUS_PARTLY_ANSWERED : Constants.QSTATUS_ANSWERED);
    }
    return ret;
  }

  private getUserAnswers(row: any): {} {
    const results = {};
    if (this.qContent) {
      for (const qKey of Object.keys(this.qContent.questions)) {
        if (this.qContent.questions[qKey]) {
          if (this.qContentAnswers[qKey]) {
            const answersByUser =
              this.utils.getValueByIdOrEmail(this.qContentAnswers[qKey], row.userId, row.email);
            const answersTooltip = QUESTION_TYPES_COMPONENTS[this.qContent.questions[qKey].storypoint].answersTooltip;
            const answersPreviewCls = QUESTION_TYPES_COMPONENTS[this.qContent.questions[qKey].storypoint].answersPreviewComponentClass;
            if (answersByUser && answersTooltip) {
              const tooltipHTML = answersTooltip(this.qContent.questions[qKey], answersByUser, this.languageParams, this.utils);
              this.elementHelper.innerHTML = tooltipHTML;
              const tooltipText = this.elementHelper.innerText;
              if (tooltipText.length)
                results[qKey] = {
                  showAnswerPreview: !!answersPreviewCls,
                  answers: answersByUser,
                  html: tooltipHTML,
                  text: tooltipText
                };
            }
          }
        }
      }
    }
    return results;
  }

  private getLastChange(row: any): number {
    let lastChange = 0;
    if (this.qContent.questions) {
      const keys = Object.keys(this.qContent.questions);
      for (const qKey of keys) {
        if (this.qContent.questions[qKey]) {
          const questionLastChange: number = this.lastChangeUsersAnswers[qKey]?.[row.userId] ?? 0;
          lastChange = Math.max(questionLastChange, lastChange);
        }
      }
    }
    return lastChange;
  }

  media() {
    return document.body.clientWidth <= Constants.MEDIA_MAX_WIDTH;
  }

  getUserPicture(user: ResultConferenceUser) {
    return user && user.picture ? user.picture : Constants.DEFAULT_USER_LOGO;
  }

  getFullName(user: ResultConferenceUser) {
    const email = user && user.email ? user.email : '';
    return user && user.fullName ? user.fullName : email;
  }

  onStatusFilter(status) {
    this.filter.statusFilter = status;
    this.dataSource.filter = JSON.stringify(this.filter);
  }

  onUserRoleFilter(role) {
    this.filter.userRoleFilter = role;
    this.dataSource.filter = JSON.stringify(this.filter);
  }

  searchFilter(searchValue) {
    this.filter.searchValue = searchValue;
    this.dataSource.filter = JSON.stringify(this.filter);
  }

  onCsvClick() {
    const list = [];
    const exportData = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
    exportData.forEach((row, ind) => {
      const r = clone(row);
      Object.keys(r).forEach(fieldName => {
        if (typeof r[fieldName] === 'string' || Array.isArray(r[fieldName]) || r[fieldName]?.hasOwnProperty('html')) {
          if (r[fieldName].hasOwnProperty('html')) {
            r[fieldName] = r[fieldName].text ? r[fieldName].text : r[fieldName].html;
          }
          if (Array.isArray(r[fieldName])) {
            r[fieldName] = r[fieldName].toString();
          }
          r[fieldName].replace(/"/g, '""');
          if (r[fieldName].search(/\salt="/g) >= 0) {
            r[fieldName] = r[fieldName].match(/\salt="(\d+|-)"/g).map(o => o.match(/(\d+|-)/g)[0]).toString();
          } else if (r[fieldName].search(/("|;|\n)/g) >= 0) {
            r[fieldName] = '"' + r[fieldName] + '"';
          }
        }
      });
      list.push(r);
    });
    const headNames = this.isAnonymousResults ? this.csvColumnsHeaderAnonymous : this.csvColumnsHeader;
    const fieldNames = this.isAnonymousResults ? this.displayedColumnsQ : union(['userName', 'lastChange'], this.displayedColumnsQ);
    this.utils.exportToCsvAndDownload(
      headNames,
      fieldNames,
      list
    );
  }

  copyEmailToClipboard() {
    let list = '';
    this.dataSource.filteredData.forEach((row, ind) => {
      list = list + (row.email && ind > 0 ? ';' : '') + (row.email ? row.email : '');
    });
    this.utils.copyToClipboard(list.length > 0 ? list : '');
  }

  onMoreMenuClick(event, moreMenu) {
    moreMenu.openMenu();
    event.cancelBubble = true;
  }

  getSanitizedGradient(row) {
    const value = 'linear-gradient(' + (row.userProgress < 50 ? 90 : 3.6 * row.userProgress - 270) +
      'deg, ' + (row.userProgress < 50 ? '#efefef' : Constants.PROGRESS_COLOR) + ' 51%, transparent 50%, transparent), linear-gradient(' +
      (row.userProgress < 50 ? 90 + 3.6 * row.userProgress : 270) +
      'deg, ' + Constants.PROGRESS_COLOR + ' 51%, #efefef 50%, #efefef)';
    return this.sanitizer.bypassSecurityTrustStyle(value);
  }

  onScroll(e) {
    const tableViewHeight = e.target.offsetHeight;
    const tableScrollHeight = e.target.scrollHeight;
    const scrollLocation = e.target.scrollTop;
    const buffer = USER_LIST_PAGE.BUFFER;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.pageLength += USER_LIST_PAGE.PAGE_STEP;
      this.paginator._changePageSize(this.pageLength);
    }
  }

  checkAllRowChange() {
    this.rowCheckAll = !isEmpty(this.dataSource.filteredData) && this.dataSource.filteredData.every(r => r.check);
    this.hasSelected = !isEmpty(this.dataSource.filteredData) && this.dataSource.filteredData.some(r => r.check);
  }

  changeAllRowCheck(event: MatCheckboxChange) {
    this.dataSource.filteredData.forEach(r => r.check = event.checked);
    this.hasSelected = this.dataSource.filteredData.some(r => r.check);
  }

  async deleteUsersAnswers() {
    const count = this.dataSource.filteredData.filter(r => r.check).length;
    if (await this.common.confirmation(this.common.i18n('questionnaire.delete.users.answers.confirmation', {count: count}))) {
      this.common.showProgress.next(true);
      const ids = this.dataSource.filteredData.filter(r => r.check).map(r => r.userId);
      for (const row of this.dataSource.filteredData.filter(r => r.check)) {
        await this.dataService.deleteQuizUserAnswers(row.userId, this.documentPathParams,
          Object.values(this.qContent.questions).reduce((acc, it) => {
            acc[it.id] = {
              methodName: QUESTION_TYPES_COMPONENTS[it.storypoint].questionAnswersSummaryMethodName(it),
              saveType: QUESTION_TYPES_COMPONENTS[it.storypoint].questionAnswersSummarySaveType(it),
              deleteStorageObjectsMethodName: QUESTION_TYPES_COMPONENTS[it.storypoint].questionDeleteUsersObjectFromStorageMethodName
            };
            return acc;
          }, {}));
      }
      this.dataSource.data = this.dataSource.data.filter(r => !ids.includes(r.userId));
      this.checkAllRowChange();
      this.common.showProgress.next(false);
    }
  }

  async deleteAllAnswers() {
    if (await this.common.confirmation('questionnaire.delete.all.answers.confirmation')) {
      this.common.showProgress.next(true);
      this.dataService.deleteQuizAllAnswers(this.documentPathParams)
        .finally(() => {
          this.common.showProgress.next(false);
          this.dialogRef.close();
        });
    }
  }

  getColumnCaption(q: EventQuestion) {
    const caption: string = q.getCaptionByLanguage(this.languageParams);
    return caption?.trim() ? caption?.trim() : '--';
  }

  openPreview(e, qKey, row) {
    const answersPreviewComponentClass = QUESTION_TYPES_COMPONENTS[this.qContent.questions[qKey].storypoint].answersPreviewComponentClass;
    if (answersPreviewComponentClass) {
      const buttonElement = e.target;
      const buttonRect = buttonElement.getBoundingClientRect();
      const dialogConfig = new MatDialogConfig();
      dialogConfig.position = {
        top: `${buttonRect.bottom}px`,
        left: `${buttonRect.left}px`
      };
      dialogConfig.maxWidth = 'initial';
      dialogConfig.disableClose = false;
      dialogConfig.panelClass = ['timeline', 'questions-participants-detail'];

      const dialogRef = this.dialog.open(answersPreviewComponentClass, dialogConfig);
      dialogRef.keydownEvents().pipe(this.takeUntilAlive())
        .subscribe(async event => {
          if (event.key === Constants.ESCAPE) {
            this.dialogRef.close();
          }
        });
      dialogRef.componentInstance.documentPathParams = this.documentPathParams;
      dialogRef.componentInstance.qContent = this.qContent;
      dialogRef.componentInstance.userId = row.userId;
      dialogRef.componentInstance.questionId = qKey;
      dialogRef.componentInstance.answers = row[qKey].answers;
    }
  }
}
