import {ANNOTATION_TYPE, IHotspotElementData} from '../../../../../core/constants';
import {cloneDeep, isEmpty, merge} from 'lodash';

export enum SKETCH_STATUS {
  START_INIT = 'start_init',
  READY = 'ready',
  ERROR = 'error'
}

export interface ICameraPosition {
  position: any;
  target: any;
  annotation_index: any;
}

export interface ISketchAnnotation {
  annotation_index: number;
  name?: string;
  description?: string;
  annotation_type: ANNOTATION_TYPE;
  options?: {
    duration: number;
  };
}

export interface IAnimationParams extends Pick<IHotspotElementData,
  'video_options_loop' |
  'video_options_speed' |
  'video_options_start_time' |
  'video_options_end_time'> {
  elementId;
}

interface ISketchfabParam {
  defaultValue: any;
  maxValue?: number;
  caption?: string;
  tooltip?: string;
  orderInEditDialog?: number;
}

interface ISketchfabParams {
  [param_name: string]: ISketchfabParam;
}

export const SKETCHFAB_PARAMS: ISketchfabParams = {
  ui_hint: {
    defaultValue: 0,
    maxValue: 2,
    caption: 'sketchfab.params.caption.ui_hint',
    tooltip: 'sketchfab.params.tooltip.ui_hint',
    orderInEditDialog: 10
  },
  ui_fullscreen: {
    defaultValue: 0,
    caption: 'sketchfab.params.caption.ui_fullscreen',
    tooltip: 'sketchfab.params.tooltip.ui_fullscreen',
    orderInEditDialog: 20
  },
  ui_animations: {
    defaultValue: 1,
    caption: 'sketchfab.params.caption.ui_animations',
    tooltip: 'sketchfab.params.tooltip.ui_animations',
    orderInEditDialog: 30
  },
  animation_autoplay: {
    defaultValue: 1,
    caption: 'sketchfab.params.caption.animation_autoplay',
    tooltip: 'sketchfab.params.tooltip.animation_autoplay',
    orderInEditDialog: 40
  },
  autospin: {
    defaultValue: 0,
    maxValue: Number.MAX_VALUE,
    caption: 'sketchfab.params.caption.autospin',
    tooltip: 'sketchfab.params.tooltip.autospin',
    orderInEditDialog: 50
  },
  annotation_cycle: {
    defaultValue: null,
    maxValue: Number.MAX_VALUE,
    caption: 'sketchfab.params.caption.annotation_cycle',
    tooltip: 'sketchfab.params.tooltip.annotation_cycle',
    orderInEditDialog: 60
  },
  ui_annotations: {
    defaultValue: 0,
    caption: 'sketchfab.params.caption.ui_annotations',
    tooltip: 'sketchfab.params.tooltip.ui_annotations',
    orderInEditDialog: 70
  },
  annotations_visible: {
    defaultValue: 1,
    caption: 'sketchfab.params.caption.annotations_visible',
    tooltip: 'sketchfab.params.tooltip.annotations_visible',
    orderInEditDialog: 80
  },
  annotation_tooltip_visible: {
    defaultValue: 1,
    caption: 'sketchfab.params.caption.annotation_tooltip_visible',
    tooltip: 'sketchfab.params.tooltip.annotation_tooltip_visible',
    orderInEditDialog: 90
  }
};

const SKETCHFAB_STRICT_PARAMS: ISketchfabParams = {
  autostart: {
    defaultValue: 1
  },
  ui_watermark: {
    defaultValue: 0
  },
  ui_color: {
    defaultValue: 'FB7822' // color without #
  },
  ui_start: {
    defaultValue: 0
  },
  ui_settings: {
    defaultValue: 0
  },
  ui_inspector: {
    defaultValue: 0
  },
  ui_infos: {
    defaultValue: 0
  },
  ui_help: {
    defaultValue: 0
  },
  ui_general_controls: {
    defaultValue: 0
  },
};

interface IParams {
  [name: string]: any;
}

export class TSketchfab {
  uid: string;
  params: IParams;
  constructor(data) {
    if (!isEmpty(data)) {
      if (typeof data === 'string') {
        this.uid = data;
      }
      if (data.hasOwnProperty('uid')) {
        this.uid = data.uid;
      }
      this.params = data.params ?? this.defaultParams();

      /** Ensure Updated Sketchfab Displays Progress Bar */
      this.params['ui_animations'] = 1;

      if (!this.params.hasOwnProperty('annotation_cycle')) {
        this.params.annotation_cycle = null;
      }
    } else {
      this.params = this.defaultParams();
    }
  }

  protected defaultParams() {
    const params: ISketchfabParams = cloneDeep(SKETCHFAB_PARAMS);
    return Object.keys(params).reduce((acc, name) => {
      acc[name] = params[name].defaultValue;
      return acc;
    }, {});
  }

  getInitializedParams() {
    const params: ISketchfabParams = merge(cloneDeep(SKETCHFAB_STRICT_PARAMS));
    return Object.keys(params).reduce((acc, name) => {
      acc[name] = params[name].defaultValue;
      return acc;
    }, cloneDeep(this.params) ?? this.defaultParams());
  }

  toObject() {
    const checkUnsupportedValue = (object) => {
      Object.keys(object)
        .forEach(key => {
          if (key === 'annotation_cycle' && !object[key]) {
            object[key] = null;
          } else if (SKETCHFAB_PARAMS[key] && SKETCHFAB_PARAMS[key].maxValue && !object[key]) {
            object[key] = 0;
          } else if ((object[key] === undefined || object[key] === null || typeof object[key] === 'function')) {
            delete object[key];
          } else if (typeof object[key] === 'object') {
            checkUnsupportedValue(object[key]);
          }
        });
    };
    const obj = {...this};
    checkUnsupportedValue(obj);
    return obj;
  }
}
