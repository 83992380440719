import {ServiceTasksComponent} from '../service-tasks.component';
import {isEmpty} from 'lodash';
import {firstValueFrom} from 'rxjs';
import {SectionContent} from '../../../model/content/SectionContent';

export const informationAboutEventsWithLostSections = async (thiz: ServiceTasksComponent) => {
  const runTask = async (clientId: string) => {
    let eventsLength = 0;
    let eventsCounter = 0;
    await firstValueFrom(thiz.afs.collection('client_data').doc(clientId)
      .collection('events')
      .get())
      .then(async snapshot => {
        const events = snapshot.docs.filter(d => !d.data().deleted);
        eventsLength = events.length;
        for (const event of events) {
          console.log('process ', ++eventsCounter, ' of ', eventsLength);
          await firstValueFrom(thiz.afs
            .collection('client_data').doc(clientId)
            .collection('conference').doc(event.id)
            .collection('timeline', q => q.where('title', '==', 'Lost sections')).get())
            .then(async snapshot => {
              const sections = snapshot.docs.map(d => new SectionContent({id: d.id, ...d.data()}));
              if (sections.length) {
                timelineLog[clientId][event.id] = {
                  shortLink: event.get('shortLink'),
                  eventId: event.id,
                  title: event.get('name'),
                  lost_sections_ids: sections.map(s => s.id)
                };
              }
            });
        }
      });
    let modulesLength = 0;
    let modulesCounter = 0;
    await firstValueFrom(thiz.afs.collection('client_data').doc(clientId)
      .collection('modules')
      .get())
      .then(async snapshot => {
        const events = snapshot.docs.filter(d => !d.data().deleted);
        modulesLength = events.length;
        for (const event of events) {
          console.log('process ', ++modulesCounter, ' of ', modulesLength);
          await firstValueFrom(thiz.afs
            .collection('client_data').doc(clientId)
            .collection('modules').doc(event.id)
            .collection('timeline', q => q.where('title', '==', 'Lost sections')).get())
            .then(async snapshot => {
              const sections = snapshot.docs.map(d => new SectionContent({id: d.id, ...d.data()}));
              if (sections.length) {
                modulesLog[clientId][event.id] = {
                  shortLink: event.get('shortLink'),
                  eventId: event.id,
                  title: event.get('name'),
                  lost_sections_ids: sections.map(s => s.id)
                };
              }
            });
        }
      });
  };

  let clientIds: string[];
  const timelineLog = {};
  const modulesLog = {};
  if (isEmpty(clientIds = await thiz.confirmationBeforeRun('Run: Information about events with "Lost sections"'))) {
    return;
  }
  for (const clientId of clientIds) {
    console.log(`Client ${clientId} started`);
    timelineLog[clientId] = {};
    modulesLog[clientId] = {};
    await runTask(clientId);
    console.log(`Client ${clientId} finished`);
  }
  console.log('~~~~~~~~ events task result ~~~~~~~~');
  console.log(timelineLog);
  console.log('~~~~~~~~ modules task result ~~~~~~~~');
  console.log(modulesLog);
};
