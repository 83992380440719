export interface CustomIcon {
  name: string;
  path: string;
}

const path = 'assets/images';

export const customIcons: CustomIcon[] = [
  { name: 'view_tile', path: `${path}/icons/view_tile.svg` },
  { name: 'calendar_with_clock', path: `${path}/icons/calendar_with_clock.svg` },
  { name: 'vimeo', path: `${path}/social_network/vimeo.svg` },
  { name: 'youtube', path: `${path}/social_network/youtube.svg` },
  { name: 'google-drive', path: `${path}/icons/google-drive.svg` },
  { name: 'zdf', path: `${path}/social_network/zdf.svg` },
  { name: 'autopilot', path: `${path}/icons/autopilot.svg` },
  { name: 'autopilot_disable', path: `${path}/icons/autopilot_disable.svg` },
  { name: 'zoom', path: `${path}/social_network/zoom.svg` },
  { name: 'random', path: `${path}/icons/random.svg` },
  { name: 'magic-wand', path: `${path}/icons/magic-wand.svg` },
  { name: 'conference_icon', path: `${path}/icons/event-mode/conference.svg` },
  { name: 'tl-mic-off', path: `${path}/icons/event-mode/tl-mic-off.svg` },
  { name: 'tl-mic-on', path: `${path}/icons/event-mode/tl-mic-on.svg` },
  { name: 'tl-cam-off', path: `${path}/icons/event-mode/tl-cam-off.svg` },
  { name: 'conference_icon_gray', path: `${path}/icons/event-mode/conference_gray.svg` },
  { name: 'magic-wand_gray', path: `${path}/icons/event-mode/magic-wand_gray.svg` },
  { name: 'speaker_slide_icon', path: `${path}/icons/event-mode/speaker_slide.svg` },
  { name: 'small_speaker_slide_icon', path: `${path}/icons/event-mode/small_speaker_slide.svg` },
  { name: 'drag_talk_icon', path: `${path}/icons/event-mode/drag_talk.svg` },
  { name: 'tl-microphone-off', path: `${path}/icons/tl-microphone-off.svg` },
  { name: 'tl-microphone', path: `${path}/icons/tl-microphone.svg` },
  { name: 'tl-hearing', path: `${path}/icons/tl-hearing.svg` },
  { name: 'tl-my', path: `${path}/icons/dashboard/tl-my.svg` },
  { name: 'tl-info', path: `${path}/icons/dashboard/tl-info.svg` },
  { name: 'tl-calend', path: `${path}/icons/dashboard/tl-calend.svg` },
  { name: 'tl-report', path: `${path}/icons/dashboard/tl-report.svg` },
  { name: 'tl-support', path: `${path}/icons/dashboard/tl-support.svg` },
  { name: 'tl-copy-link', path: `${path}/icons/event-mode/tl-copy-link.svg` },
  { name: 'tl-settings', path: `${path}/icons/event-mode/tl-settings.svg` },
  { name: 'tl-setting', path: `${path}/icons/event-mode/tl-setting.svg` },
  { name: 'tl-screen-share', path: `${path}/icons/event-mode/tl-screen-share.svg` },
  { name: 'tl-screen-share-on', path: `${path}/icons/event-mode/tl-screen-share-on.svg` },
  { name: 'tl-hand-up', path: `${path}/icons/event-mode/tl-hand-up.svg` },
  { name: 'tl-speakers-off', path: `${path}/icons/event-mode/tl-speakers-off.svg` },
  { name: 'tl-hand-filled', path: `${path}/icons/event-mode/tl-hand-filled.svg` },
  { name: 'tl-stop', path: `${path}/icons/event-mode/tl-stop.svg` },
  { name: 'orange_arrow_back', path: `${path}/arrow/orange_arrow_back.svg` },
  { name: 'orange_arrow_forward', path: `${path}/arrow/orange_arrow_forward.svg` },
  { name: 'orange-close', path: `${path}/icons/close-orange.svg` },
  { name: 'list-mode', path: `${path}/mode/list-mode.svg` },
  { name: 'timeline-mode', path: `${path}/mode/timeline-mode.svg` },
  { name: 'ct-text-note', path: `${path}/content_types/text-note.svg` },
  { name: 'ct-picture-gallery', path: `${path}/content_types/picture-gallery.svg` },
  { name: 'ct-video', path: `${path}/content_types/video.svg` },
  { name: 'ct-pdf', path: `${path}/content_types/adobe_pdf.svg` },
  { name: 'ct-quiz', path: `${path}/content_types/quiz.svg` },
  { name: 'ct-easyzoom', path: `${path}/content_types/easyzoom.svg` },
  { name: 'ct-zoomify', path: `${path}/content_types/zoomify.svg` },
  { name: 'ct-sketchfab', path: `${path}/content_types/sketchfab.svg` },
  { name: 'ct-task', path: `${path}/content_types/task.svg` },
  { name: 'ct-html', path: `${path}/content_types/html.svg` },
  { name: 'ct-highlight', path: `${path}/content_types/highlight.svg` },
  { name: 'ct-embedded', path: `${path}/content_types/code.svg` },
  { name: 'ct-h5p', path: `${path}/content_types/h5p.svg` },
  { name: 'ct-learning-apps', path: `${path}/content_types/learning_apps.svg` },
  { name: 'ct-learning-snacks', path: `${path}/content_types/learning_snacks.svg` },
  { name: 'ct-file', path: `${path}/content_types/file.svg` },
  { name: 'tl-timeline-event', path: `${path}/icons/event-mode/tl-timeline-event.svg` },
  { name: 'smile10', path: `${path}/smiles/smile10.svg` },
  { name: 'smile20', path: `${path}/smiles/smile20.svg` },
  { name: 'smile30', path: `${path}/smiles/smile30.svg` },
  { name: 'smile40', path: `${path}/smiles/smile40.svg` },
  { name: 'smile50', path: `${path}/smiles/smile50.svg` },
  { name: 'smile60', path: `${path}/smiles/smile60.svg` },
  { name: 'smile70', path: `${path}/smiles/smile70.svg` },
  { name: 'digit00', path: `${path}/smiles/digit00.svg` },
  { name: 'digit01', path: `${path}/smiles/digit01.svg` },
  { name: 'digit02', path: `${path}/smiles/digit02.svg` },
  { name: 'digit03', path: `${path}/smiles/digit03.svg` },
  { name: 'digit04', path: `${path}/smiles/digit04.svg` },
  { name: 'digit05', path: `${path}/smiles/digit05.svg` },
  { name: 'digit06', path: `${path}/smiles/digit06.svg` },
  { name: 'digit07', path: `${path}/smiles/digit07.svg` },
  { name: 'digit08', path: `${path}/smiles/digit08.svg` },
  { name: 'digit09', path: `${path}/smiles/digit09.svg` },
  { name: 'digit10', path: `${path}/smiles/digit10.svg` },
  { name: 'letter_a', path: `${path}/smiles/letter_a.svg` },
  { name: 'letter_b', path: `${path}/smiles/letter_b.svg` },
  { name: 'letter_c', path: `${path}/smiles/letter_c.svg` },
  { name: 'letter_d', path: `${path}/smiles/letter_d.svg` },
  { name: 'letter_e', path: `${path}/smiles/letter_e.svg` },
  { name: 'letter_f', path: `${path}/smiles/letter_f.svg` },
  { name: 'letter_g', path: `${path}/smiles/letter_g.svg` },
  { name: 'letter_h', path: `${path}/smiles/letter_h.svg` },
  { name: 'letter_i', path: `${path}/smiles/letter_i.svg` },
  { name: 'letter_j', path: `${path}/smiles/letter_j.svg` },
  { name: 'letter_k', path: `${path}/smiles/letter_k.svg` },
  { name: 'ft_aac', path: `${path}/file_types/ft-aac.svg` },
  { name: 'ft_csv', path: `${path}/file_types/ft-csv.svg` },
  { name: 'ft_docx', path: `${path}/file_types/ft-docx.svg` },
  { name: 'ft_gif', path: `${path}/file_types/ft-gif.svg` },
  { name: 'ft_heic', path: `${path}/file_types/ft-heic.svg` },
  { name: 'ft_jpeg', path: `${path}/file_types/ft-jpeg.svg` },
  { name: 'ft_jpg', path: `${path}/file_types/ft-jpg.svg` },
  { name: 'ft_mp3', path: `${path}/file_types/ft-mp3.svg` },
  { name: 'ft_m4a', path: `${path}/file_types/ft-m4a.svg` },
  { name: 'ft_mp4', path: `${path}/file_types/ft-mp4.svg` },
  { name: 'ft_pdf', path: `${path}/file_types/ft-pdf.svg` },
  { name: 'ft_png', path: `${path}/file_types/ft-png.svg` },
  { name: 'ft_pptx', path: `${path}/file_types/ft-pptx.svg` },
  { name: 'ft_svg', path: `${path}/file_types/ft-svg.svg` },
  { name: 'ft_webp', path: `${path}/file_types/ft-webp.svg` },
  { name: 'ft_xlsx', path: `${path}/file_types/ft-xlsx.svg` },
  { name: 'ft_zip', path: `${path}/file_types/ft-zip.svg` },
  { name: 'smile-cross', path: `${path}/smiles/smile-cross.svg` },
  { name: 'smile-check-mark', path: `${path}/smiles/smile-check-mark.svg` },
  { name: 'smile-wave', path: `${path}/smiles/smile-wave.svg` },
  { name: 'g-translate', path: `${path}/translate/g-translate.svg` },
  { name: 'note-pin', path: `${path}/icons/pin.svg` },
  { name: 'note-line', path: `${path}/icons/line.svg` },
  { name: 'brain', path: `${path}/icons/brain.svg` },
  { name: 'brain_transparent', path: `${path}/icons/brain_transparent.svg` },
  { name: 'tl-event', path: `${path}/icons/event.svg` },
  { name: 'csv_file', path: `${path}/icons/csv_file.svg` },
  { name: 'priority-I', path: `${path}/icons/exclamation/exclamation-black.svg` },
  { name: 'priority-II', path: `${path}/icons/exclamation/exclamation-black-2.svg` },
  { name: 'priority-III', path: `${path}/icons/exclamation/exclamation-black-3.svg` },
  { name: 'priority-red-I', path: `${path}/icons/exclamation/exclamation-red.svg` },
  { name: 'priority-red-II', path: `${path}/icons/exclamation/exclamation-red-2.svg` },
  { name: 'priority-red-III', path: `${path}/icons/exclamation/exclamation-red-3.svg` },
  { name: 'tl-filter', path: `${path}/icons/filter.svg` },
  { name: 'tl-show-before', path: `${path}/icons/show_before.svg` },
  { name: 'eye', path: `${path}/icons/eye.svg` },
  { name: 't-pin', path: `${path}/icons/t-pin.svg` },
  { name: 'tl-message-cloud', path: `${path}/icons/message-cloud.svg` },
  { name: 'tl-fullscreen', path: `${path}/full-screen.svg` },
  { name: 'tl-fullscreen-exit', path: `${path}/full-screen-close.svg` },
  { name: 'follow-me-follow-speaker', path: `${path}/follow-me-follow-speaker.svg` },
  { name: 'follow-me-dont-follow', path: `${path}/follow-me-dont-follow.svg` },
  { name: 'follow-me-act-participant', path: `${path}/follow-me-act-participant.svg` },
  { name: 'tl-update', path: `${path}/icons/update.svg` },
  { name: 'tl-list-editor', path: `${path}/icons/list-editor.svg` },
  { name: 'check-inactive', path: `${path}/icons/check-done/check-inactive.svg` },
  { name: 'check-learned', path: `${path}/icons/check-done/check-learned.svg` },
  { name: 'check-presented', path: `${path}/icons/check-done/check-presented.svg` },
  { name: 'hotspot', path: `${path}/icons/hotspot.svg` },
  { name: 'orange_plus', path: `${path}/arrow/orange_plus.svg` },
  { name: 'icon_01', path: `${path}/questions_icons/icon_01.svg` },
  { name: 'icon_02', path: `${path}/questions_icons/icon_02.svg` },
  { name: 'icon_03', path: `${path}/questions_icons/icon_03.svg` },
  { name: 'icon_04', path: `${path}/questions_icons/icon_04.svg` },
  { name: 'icon_05', path: `${path}/questions_icons/icon_05.svg` },
  { name: 'icon_06', path: `${path}/questions_icons/icon_06.svg` },
  { name: 'icon_07', path: `${path}/questions_icons/icon_07.svg` },
  { name: 'icon_08', path: `${path}/questions_icons/icon_08.svg` },
  { name: 'icon_09', path: `${path}/questions_icons/icon_09.svg` },
  { name: 'icon_10', path: `${path}/questions_icons/icon_10.svg` },
  { name: 'icon_11', path: `${path}/questions_icons/icon_11.svg` },
  { name: 'icon_12', path: `${path}/questions_icons/icon_12.svg` },
  { name: 'icon_13', path: `${path}/questions_icons/icon_13.svg` },
  { name: 'check-error', path: 'assets/images/icons/check-done/check-error.svg' },
  { name: 'check-progress', path: 'assets/images/icons/check-done/check-progress.svg' },
  { name: 'copy-check', path: 'assets/images/copy-check.svg' },
  { name: 'tl-scribble', path: 'assets/images/tl-scribble.svg' },
  { name: 'tl-dots-vertical', path: 'assets/images/tl-dots-vertical.svg' },
  { name: 'tl-eye', path: 'assets/images/tl-eye.svg' },
  { name: 'tl-eye-off', path: 'assets/images/tl-eye-off.svg' },
  { name: 'tl-play', path: 'assets/images/tl-play.svg' },
  { name: 'tl-pause', path: 'assets/images/tl-pause.svg' },
  { name: 'tl-skip-back', path: 'assets/images/tl-skip-back.svg' },
  { name: 'tl-skip-forward', path: 'assets/images/tl-skip-forward.svg' },
  { name: 'tl-playlist', path: 'assets/images/tl-playlist.svg' },
  { name: 'tl-pencil', path: 'assets/images/tl-pencil.svg' },
  { name: 'pencil-1', path: `${path}/icons/pencil-1.svg` },
  { name: 'pencil-2', path: `${path}/icons/pencil-2.svg` },
  { name: 'pencil-3', path: `${path}/icons/pencil-3.svg` },
  { name: 'insert-left', path: `${path}/insert-left.svg` },
  { name: 'insert-right', path: `${path}/insert-right.svg` }
];
