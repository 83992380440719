import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {NgIf, NgStyle} from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

type Style = Partial<CSSStyleDeclaration>;

@Component({
  standalone: true,
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  imports: [
    NgIf,
    NgStyle,
    MatTooltipModule
  ],
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnChanges {
  @Input('img') avatarSrc: string;
  @Input() size = 50;
  @Input() textSizeRatio = 3;
  @Input() round = false;
  @Input() bgColor = '#F87832';
  @Input() fontColor = '#FFF';
  @Input() cornerRadius: number = null;
  @Input() maxInitials = 2;
  @Input() name: string | null;
  @Input() value?: string;
  @Input() style: any = {};
  @Input() borderColor: string | undefined;

  @Input() initialsSize: string | number = 0;

  @Output() clickOnAvatar: EventEmitter<boolean> = new EventEmitter<boolean>();

  public avatarText: string | null = null;
  public avatarStyle: Style = {};

  colors = [
    '#1abc9c',
    '#3498db',
    '#f1c40f',
    '#8e44ad',
    '#e74c3c',
    '#d35400',
    '#2c3e50',
    '#7f8c8d'
  ];

  onClick() {
    this.clickOnAvatar.emit(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildAvatar();
  }

  onError() {
    this.buildTextAvatar(this.name);
    this.avatarSrc = null;
  }

  buildAvatar() {
    if (this.avatarSrc) {
      this.buildImageAvatar();
    } else if (this.value) {
      this.buildTextAvatar(this.value);
      this.avatarSrc = null;
    } else if (this.name) {
      this.buildTextAvatar(this.name);
      this.avatarSrc = null;
    }
  }

  buildTextAvatar(value: string) {
    this.avatarText = this.getInitials(value);
    this.avatarStyle = this.getTextStyle(value);
  }

  getTextStyle(value: string): Style {
    return {
      textAlign: 'center',
      borderRadius: this.round ? '100%' : this.cornerRadius + 'px',
      border: this.borderColor ? '1px solid ' + this.borderColor : '',
      textTransform: 'uppercase',
      color: this.fontColor,
      backgroundColor: this.bgColor
        ? this.bgColor
        : this.getRandomColor(value),
      fontSize: Math.floor(+this.size / this.textSizeRatio) + 'px',
      lineHeight: this.size + 'px',
      ...this.style
    };
  }

  buildImageAvatar() {
    this.avatarStyle = this.getImageStyle();
  }

  getImageStyle(): Style {
    return {
      maxWidth: '100%',
      borderRadius: this.round ? '50%' : this.cornerRadius + 'px',
      border: this.borderColor ? '1px solid ' + this.borderColor : '',
      width: this.size + 'px',
      height: this.size + 'px',
      ...this.style,
    };
  }

  getInitials(value: string): string {
    const size = +this.initialsSize;
    const name = value?.trim();

    if (!name) {
      return '';
    }

    const words = name.split(' ');

    if (size && size < words.length) {
      return this.constructInitials(words.slice(0, size));
    } else {
      return this.constructInitials(words);
    }
  }

  constructInitials(words: string[]): string {
    if (!words || !words.length) {
      return '';
    }

    return words
      .filter(word => word && word.length > 0)
      .map(word => word[0].toUpperCase())
      .join('');
  }

  getRandomColor(name: string): string {
    if (!name) {
      return 'transparent';
    }
    const hash = this.calculateHash(name);
    return this.colors[hash % this.colors.length];
  }

  calculateHash(value: string): number {
    return value
      .split('')
      .map(letter => letter.charCodeAt(0))
      .reduce((previous, current) => previous + current);
  }
}
