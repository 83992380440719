import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {UPLOAD_TYPE, UploadService} from '../../../../../../../core/upload.service';
import {BehaviorSubject, pairwise, take} from 'rxjs';
import {IQuestionUploadFilesOptions, IUploadFile, IUploadFileParticipantData} from '../upload-file-model/UploadFile';
import {formatSize, getExtension} from '../question-upload-files-utils';
import {StorageDataService} from '../../../../../../../services/storage-data.service';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {isEqual, isEmpty} from 'lodash';

@Component({
  selector: 'app-question-upload-files-participant',
  templateUrl: './question-upload-files-participant.component.html',
  styleUrl: './question-upload-files-participant.component.scss'
})
export class QuestionUploadFilesParticipantComponent extends AbstractQuizQuestionParticipantComponent {
  readonly DEFAULT_SIZE_LIMIT = this.Constants.FT_AUDIO_SIZE;
  readonly DEFAULT_LARGE_SIZE_LIMIT = this.Constants.FT_VIDEO_SIZE;
  readonly imageExtensions = this.Constants.FILE_TYPES[this.Constants.FT_IMAGE].extension.filter(ext => ext !== 'HEIC');

  uppy: any;
  replacementUppy: any;
  maxNumberOfFiles: number;
  itemToDelete: IUploadFile;
  fileData: IUploadFileParticipantData[] = [];
  fileData$ = new BehaviorSubject<IUploadFileParticipantData[]>([]);

  constructor(protected injector: Injector,
              protected elementRef: ElementRef,
              private uploadService: UploadService,
              private storageDataService: StorageDataService
  ) {
    super(injector, elementRef);
    this.question$
      .pipe(pairwise(), this.takeUntilAlive())
      .subscribe(([prev, curr]) => {
        if (prev && curr && this.uppy && this.replacementUppy) {
          const pnof = prev.options.numberOfFiles;
          const cnof = curr.options.numberOfFiles;
          const pst = prev.options.selectedTypes;
          const cst = curr.options.selectedTypes;

          if (pnof !== cnof || !isEqual(Object.values(pst).flat(), Object.values(cst).flat())) {
            const selectedTypes = this.getSelectedTypes(cst);
            this.uppy.setOptions({
              ...this.uppy.opts,
              restrictions: {
                ...this.uppy.opts.restrictions,
                maxNumberOfFiles: cnof,
                allowedFileTypes: selectedTypes
              }
            });

            this.replacementUppy.setOptions({
              ...this.replacementUppy.opts,
              restrictions: {
                ...this.replacementUppy.opts.restrictions,
                maxNumberOfFiles: 1,
                allowedFileTypes: selectedTypes
              }
            });
          }

          const dashboardPlugin = this.uppy.getPlugin('Dashboard');
          if (dashboardPlugin) {
            dashboardPlugin.setOptions({
              ...dashboardPlugin.opts,
              note: this.getDashboardNoteStr(cnof, cst)
            });
          }

          const reDashboardPlugin = this.replacementUppy.getPlugin('Dashboard');
          if (reDashboardPlugin) {
            reDashboardPlugin.setOptions({
              ...reDashboardPlugin.opts,
              note: this.getDashboardNoteStr(1, cst)
            });
          }
        }
      });
  }

  private getDashboardNoteStr(maxNumberOfFiles: number, selectedTypes: any) {
    return this.common.utils.i18n('questionnaire.upload.file.participants.upload.file.info',
      {maxNoOfFiles: maxNumberOfFiles, fileTypes: this.formatSelectedTypes(selectedTypes)});
  }

  initQuestionAnswersDataSource() {
    this.maxNumberOfFiles = this.questionOptions.numberOfFiles ? this.questionOptions.numberOfFiles : 3;
    const selectedTypes = this.questionOptions.selectedTypes ? this.getSelectedTypes(this.questionOptions.selectedTypes) : {};
    const baseSettings = {
      restrictions: {
        allowedFileTypes: selectedTypes,
        maxFileSize: this.DEFAULT_LARGE_SIZE_LIMIT,
        dashboardNote: this.getDashboardNote(this.maxNumberOfFiles)
      },
      onBeforeFileAdded: (currentFile, files) => {
        const validationResult = this.validateFileSize(currentFile);
        if (validationResult !== true) {
          this.uppy.info(validationResult.message, 'error', 5000);
          return false;
        }
        return true;
      }
    };
    const mainSettings = {
      ...baseSettings,
      restrictions: {
        ...baseSettings.restrictions,
        maxNumberOfFiles: this.maxNumberOfFiles
      }
    };

    const replacementSettings = {
      ...baseSettings,
      restrictions: {
        ...baseSettings.restrictions,
        maxNumberOfFiles: 1,
        dashboardNote: this.getDashboardNote(1)
      }
    };
    if (!this.uppy) {
      this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.CUSTOM, mainSettings, (result) => {
        if ((this.answersFiles.length + result.length) > this.maxNumberOfFiles) {
          this.common.showPopupError(this.common.i18n('questionnaire.upload.file.participants.upload.limit.error',
            {count: this.maxNumberOfFiles}));
          return false;
        }
        this.uploadFiles(result).then(() => {
          this.uploadService.closeModal(this.uppy);
        });
      });
      this.uppy.on('dashboard:modal-open', () => {
        this.setUppyId();
      });
    }

    if (!this.replacementUppy) {
      this.replacementUppy = this.uploadService.createFileUploader(UPLOAD_TYPE.CUSTOM, replacementSettings, (result) => {
        this.uploadFiles(result).then(() => {
          this.deleteFile(this.itemToDelete).then(() => {
            this.uploadService.closeModal(this.replacementUppy);
          });
        });
      }, () => {
        this.undoDeleteUploadedFile();
      });

      this.replacementUppy.on('dashboard:modal-open', () => {
        this.setUppyId();
      });
    }
  }

  private getDashboardNote(maxNumberOfFiles: number) {
    return this.common.utils.i18n('questionnaire.upload.file.participants.upload.file.info',
      {maxNoOfFiles: maxNumberOfFiles, fileTypes: this.formatSelectedTypes(this.questionOptions.selectedTypes)});
  }

  protected onReceiveQuestionAnswers() {
    const draftFileData = this.fileData.map(d => ({
      ...d,
      draft: true
    }));
    const answers = this.answersFiles;
    answers.forEach(async (file) => {
      const index = draftFileData.findIndex(d => d.id === file.id);
      if (index > -1) {
        draftFileData[index].draft = false;
      } else {
        const dataObj: IUploadFileParticipantData = {
          id: file.id,
          name: file.name,
          size: formatSize(file.size),
          extension: getExtension(file.name),
          metaType: file.metaType,
          isImage: this.imageExtensions.includes(file.name.split('.').pop().toUpperCase()),
          imageUrlLoaded: false,
          imageSrc: null,
          draft: false
        };
        draftFileData.push(dataObj);
      }
    });
    this.fileData = draftFileData.filter(item => !item.draft);
    this.fileData$.next(this.fileData);

    for (const file of this.fileData) {
      if (file.isImage && !file.imageUrlLoaded) {
        try {
          this.getFileUrl(file.id, (url) => {
            file.imageSrc = url;
            file.imageUrlLoaded = true;
          });
        } catch (error) {
          this.common.log.error(error);
          file.imageUrlLoaded = false;
        }
      }
    }
  }

  private setUppyId() {
    const dashboardEl = document.querySelectorAll('.uppy-Dashboard');
    if (dashboardEl) {
      dashboardEl.forEach((element, index) => {
        if (!element.id) {
          element.id = `uppy-Dashboard-${index + 1}`;
        }
      });
    }
  }

  private getFileUrl(fileId: string, success) {
    const answersUID = this.answers.uid ?? this.currentUserId;
    const eventId = this.documentPathParams.eventId;
    const contentId = this.documentPathParams.contentId;
    const containerId = this.documentPathParams.containerId;
    const path = `${eventId}/${contentId}/${containerId}/${this.question.id}/users_answers/${answersUID}/${fileId}`;
    this.storageDataService.getStorageDataURL(path)
      .then(url => {
        if (url) {
          success(url);
        }
      }).catch((err) => {
      this.common.log.error(err);
      throw err;
    });
  }

  formatSelectedTypes(selectedTypes: any): string {
    const categories = {
      Images: selectedTypes.images || [],
      Audio: selectedTypes.audios || [],
      Videos: selectedTypes.videos || [],
      Documents: selectedTypes.documents || [],
      Others: selectedTypes.others || []
    };

    return Object.entries(categories)
      .filter(([_, types]) => types.length > 0)
      .map(([category, types]) => `${category}: ${types.join(', ')}`)
      .join('\n');
  }

  private getSelectedTypes(selectedTypes: any): string[] {
    return Object.values(selectedTypes).flat()
      .map(ext => `.${ext}`);
  }

  get questionOptions(): IQuestionUploadFilesOptions {
    return this.question.options;
  }

  validateFileSize(file) {
    const fileType = file.extension;
    const sizeLimit = this.getSizeLimitForExtension(file.extension) || this.DEFAULT_SIZE_LIMIT;
    if (file.size > sizeLimit) {
      return {
        message: this.common.i18n('questionnaire.upload.file.participants.upload.file.type.size.error',
          {fileSizeLimit: formatSize(sizeLimit), fileType: fileType}),
      };
    }
    return true;
  }

  getSizeLimitForExtension(extension: string): number {
    const uppercaseExtension = extension.toUpperCase().replace('.', '');
    for (const fileType of Object.values(this.Constants.FILE_TYPES)) {
      if (fileType.extension.includes(uppercaseExtension)) {
        return fileType.sizeLimit;
      }
    }
    return null;
  }

  private async uploadFiles(list) {
    this.common.showProgress.next(true);
    const answersUID = !isEmpty(this.answers) ? (this.answers.uid ?? this.currentUserId) : this.common.utils.generateRandomString(15);
    const answers = this.answersFiles;
    for (const item of list) {
      const prefix = this.common.utils.generateRandomString(4).toLowerCase();
      const id = `${item.id.replace(/\//g, '-').replace('uppy', prefix)}.${item.extension}`;
      if (!answers.find(it => it.id.includes(id))) {
        const eventId = this.documentPathParams.eventId;
        const contentId = this.documentPathParams.contentId;
        const containerId = this.documentPathParams.containerId;
        const path = `${eventId}/${contentId}/${containerId}/${this.question.id}/users_answers/${answersUID}`;
        await this.storageDataService.uploadAnyObjectToStorageByDataPath(path, id, item.response, item.meta.type)
          .then(async () => {
            const obj: IUploadFile = {
              id: `${id}`,
              name: item.name,
              size: item.size,
              metaType: item.meta.type
            };
            answers.push(obj);
            const dataObj: IUploadFileParticipantData = {
              id: `${id}`,
              name: item.name,
              size: formatSize(item.size),
              extension: getExtension(item.name),
              metaType: item.meta.type,
              isImage: this.imageExtensions.includes(item.name.split('.').pop().toUpperCase()),
              imageUrlLoaded: false,
              imageSrc: null,
              draft: false
            };
            if (dataObj.isImage) {
              const path = `${eventId}/${contentId}/${containerId}/${this.question.id}/users_answers/${answersUID}/${id}`;
              dataObj.imageSrc = await this.storageDataService.getStorageDataURL(path);
              dataObj.imageUrlLoaded = true;
            }
            this.fileData.push(dataObj);
          }).catch((err) => {
            // todo :: delete all uploaded files and clear results after answers.push(obj);
            this.common.log.error(err);
            this.common.showProgress.next(false);
          });
      }
    }
    this.answers = {f: answers, uid: answersUID};
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
    this.fileData$.next(this.fileData);
    this.common.showProgress.next(false);
  }

  get answersFiles(): IUploadFile[] {
    return this.answers?.f ?? [];
  }

  deleteUploadFile(itemId: string) {
    this.common.confirm(this.common.i18n('questionnaire.upload.file.participants.operation.remove.confirm.header'),
      this.common.i18n('questionnaire.upload.file.participants.operation.remove.confirm.message'), false,
      this.common.i18n('action.delete'))
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          const index = this.answersFiles.findIndex(it => it.id === itemId);
          if (index > -1) {
            this.deleteFile(this.answersFiles[index]);
          }
        }
      });
  }

  private async deleteFile(item: IUploadFile) {
    const answersUID = this.answers.uid ?? this.currentUserId;
    const answers = this.answersFiles;
    this.common.showProgress.next(true);
    const index = answers.findIndex(it => it.id === item.id);
    if (index > -1) {
      const eventId = this.documentPathParams.eventId;
      const contentId = this.documentPathParams.contentId;
      const containerId = this.documentPathParams.containerId;
      const path = `${eventId}/${contentId}/${containerId}/${this.question.id}/users_answers/${answersUID}`;
      await this.storageDataService.deleteObjectFromStorageByDataPath(path, item.id)
        .then(() => {
          answers.splice(index, 1);
          this.fileData.splice(index, 1);
        }).catch((err) => {
          this.common.log.error(err);
          this.common.showProgress.next(false);
        });
      this.answers = {f: answers, uid: answersUID};
      this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
      this.fileData$.next(this.fileData);
      this.common.showProgress.next(false);
    }
  }

  replaceUploadFile(itemId: string) {
    const index = this.answersFiles.findIndex(it => it.id === itemId);
    if (index > -1) {
      this.itemToDelete = this.answersFiles[index];
    }
    this.uploadService.openUploadWindow(this.replacementUppy);
  }

  private undoDeleteUploadedFile() {
    this.itemToDelete = null;
  }

  upload() {
    this.uploadService.openUploadWindow(this.uppy);
  }

  onDestroy() {
    super.onDestroy();
    this.uppy.close();
    this.replacementUppy.close();
  }

}
