import {LANGUAGE} from './language-constants';
import {ICurriculumModulesQueryParams} from '@ninescopesoft/core/lib/services/curriculums.service';

export interface ILanguage {
  name: string;
  value: string;
}

export interface ICreator {
  userId: string;
  picture: string;
  fullName: string;
  department: string;
}

export interface ICaption {
  [language: string]: string;
}

export type TCaption = ICaption | string;

export interface ILanguageParams {
  defaultLanguage: LANGUAGE;
  currentLanguage: LANGUAGE;
  usedMultilingualContent: boolean;
  usedLanguages: string[];
}

export class Constants {
  public static FIREBASE_NAME = null;
  // overridden on login in AppComponent from ClintConfig.language
  public static TIMELINE_DEFAULT_LANGUAGE: string = LANGUAGE.EN;

  public static readonly PROVIDER_GOOGLE = 'gapi';
  public static readonly PROVIDER_HKVBS_AZURE = 'hkvbs-azure';
  public static readonly PROVIDER_HKVBS_MICROSOFT = 'microsoft';
  public static readonly PROVIDER_ANONYMOUS = 'anonymous';

  public static readonly RIGHT_ARROW = 'ArrowRight';
  public static readonly LEFT_ARROW = 'ArrowLeft';
  public static readonly UP_ARROW = 'ArrowUp';
  public static readonly DOWN_ARROW = 'ArrowDown';
  public static readonly NUMPAD_UP_ARROW = 'Numpad8';
  public static readonly NUMPAD_DOWN_ARROW = 'Numpad2';
  public static readonly FORCE_RIGHT_ARROW = 'ForceArrowRight';
  public static readonly FORCE_LEFT_ARROW = 'ForceArrowLeft';
  public static readonly CTRL_RIGHT_ARROW = 'CtrlArrowRight';
  public static readonly CTRL_LEFT_ARROW = 'CtrlArrowLeft';
  public static readonly ESCAPE = 'Escape';
  public static readonly SHIFT_LEFT = 'ShiftLeft';
  public static readonly SHIFT_RIGHT = 'ShiftRight';
  public static readonly TAB = 'Tab';
  public static readonly BACKSPACE = 'Backspace';
  public static readonly ENTER = 'Enter';
  public static readonly NUMPAD_ENTER = 'NumpadEnter';
  public static readonly DIGIT_KEY = [
    'Digit0',
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9'
  ];

  public static readonly DIGIT_NUMPAD_KEY = [
    'Numpad0',
    'Numpad1',
    'Numpad2',
    'Numpad3',
    'Numpad4',
    'Numpad5',
    'Numpad6',
    'Numpad7',
    'Numpad8',
    'Numpad9',
  ];

  public static readonly MAX_ORDER_INDEX_VALUE = 179769313486231569;
  public static readonly ROLE_SUPERADMIN = '1000';
  public static readonly ROLE_ADMIN = '1100';
  public static readonly ROLE_MODERATOR = '1200';
  public static readonly ROLE_TRAINER = '1300';
  public static readonly ROLE_SIMPLE_REGISTRATION = '1400';
  public static readonly ROLE_MANAGE = '1500';
  public static readonly ROLE_COPY_MASTER = '1600';

  public static MAX_TEST_EVENT_ID_LENGTH = 10;

  public static PROGRESS_COLOR = '#6AA84F';
  public static WORD_CLOUD_DEF_COLOR = '#0066CC';

  public static PING_INTERVAL = 150769; // 2.5+  minutes // for debug -1
  public static ONLINE_INTERVAL = 182027; // 3+ minutes // for debug Number.MAX_VALUE
  public static GET_PING_LIST_INTERVAL_GRID = 10000;
  public static EVENT_BEFORE_START_NOTIFICATION = 300000;
  public static SELF_LEARNING_SEND_TIME_INTERVAL = 60; // seconds
  public static ONE_SECOND_INTERVAL = 1000;


  public static CHANGEDETECTOR_REATTACH = 'reattach';
  public static CHANGEDETECTOR_DETACH = 'detach';

  public static readonly AI_TEXT_FUNCTION_CORRECT_GRAMMAR = 'correctGrammar';
  public static readonly AI_TEXT_FUNCTION_SHORTEN_TEXT = 'shortenText';
  public static readonly AI_TEXT_FUNCTION_EXPAND_TEXT = 'expandText';
  public static readonly AI_TEXT_FUNCTION_SUMMARIZE_ANSWERS = 'summarizeAnswers';
  public static readonly AI_TEXT_FUNCTION_CREATE_QUESTIONNAIRE = 'createQuestionnaire';
  public static readonly AI_TEXT_FUNCTION_VERIFY_QUESTION = 'verifyQuestion';
  public static readonly AI_TEXT_FUNCTION_CREATE_LYRICS = 'createLyrics';

  public static MOBILE_SCREEN_MEDIA = '(max-width: 767px)';
  public static readonly MEDIA_MAX_WIDTH = 767;
  public static readonly MIDDLE_MEDIA_MAX_WIDTH = 1000;
  public static readonly MIDDLE_SCREEN_MODE = 1598;
  public static USER_BLOCKED_SERVER_MESSAGE = 'User is blocked';
  public static EVENT_ACCESS_DENIED = 'You have no access to this event';
  public static PERMISSION_DENIED = 'Permission denied to this event';

  public static CONTENT_TYPE_TIMELINE = 'timeline';
  public static CONTENT_TYPE_SECTION = 'section';
  public static CONTENT_TYPE_TEXT = 'text';
  public static CONTENT_TYPE_LINK = 'link';
  public static CONTENT_TYPE_CLIPBOARD = 'clipboard';
  public static CONTENT_TYPE_SLIDE = 'slide';
  public static CONTENT_TYPE_SEPARATOR = 'separator';
  public static CONTENT_TYPE_QUESTIONNAIRE = 'questionnaire';
  public static CONTENT_TYPE_BREAK = 'break';
  public static CONTENT_TYPE_COMPETITION = 'competition';
  public static CONTENT_TYPE_TASK_DOCUMENT = 'task_document';
  public static CONTENT_TYPE_SCREEN_SHARING = 'screen_sharing';
  public static CONTENT_TYPE_LIVE_STREAMING = 'live_streaming';
  public static CONTENT_TYPE_MEETING = 'meeting';
  public static CONTENT_TYPE_CONTENT_CONTAINER = 'content_container';

  public static CONTENT_SUBTYPE_BREAK = 'break';
  public static CONTENT_SUBTYPE_QUESTION = 'question';
  public static CONTENT_SUBTYPE_TASK = 'task';
  public static CONTENT_SUBTYPE_SUMMARISE = 'summarise';
  public static CONTENT_SUBTYPE_QUESTIONNAIRE = 'questionnaire';

  public static CONTENT_TYPE_MODULAR = 'modular';
  public static MODULE_TYPE_TEXT = 'text';
  public static MODULE_TYPE_HTML = 'html';
  public static MODULE_TYPE_HIGHLIGHT = 'highlight';
  public static MODULE_TYPE_IMAGE = 'image';
  public static MODULE_TYPE_PDF = 'pdf';
  public static MODULE_TYPE_ZIF = 'zif';
  public static MODULE_TYPE_VIDEOS = 'videos';
  public static MODULE_TYPE_SKETCH = 'sketch';
  public static MODULE_TYPE_EASY_ZOOM = 'easy.zoom';
  public static MODULE_TYPE_PECHA_KUCHA = 'pechakucha';
  public static MODULE_TYPES = [
    Constants.MODULE_TYPE_TEXT,
    Constants.MODULE_TYPE_IMAGE,
    Constants.MODULE_TYPE_VIDEOS,
    Constants.MODULE_TYPE_SKETCH,
    Constants.MODULE_TYPE_EASY_ZOOM,
    Constants.MODULE_TYPE_PDF,
    Constants.MODULE_TYPE_ZIF,
    Constants.MODULE_TYPE_HTML,
    Constants.MODULE_TYPE_HIGHLIGHT
    ];

  public static MEETING_CONTENTS_TYPES = [
    Constants.CONTENT_TYPE_SCREEN_SHARING,
    Constants.CONTENT_TYPE_MEETING
  ];

  public static CONTENTS_OBJECTS_TYPES: ITypeChangeEvent[] = [
    {id: 1, moduleType: Constants.MODULE_TYPE_TEXT, contentType: Constants.CONTENT_TYPE_MODULAR},
    {id: 2, moduleType: Constants.MODULE_TYPE_IMAGE, contentType: Constants.CONTENT_TYPE_MODULAR},
    {id: 3, moduleType: Constants.MODULE_TYPE_VIDEOS, contentType: Constants.CONTENT_TYPE_MODULAR},
    {id: 4, moduleType: Constants.MODULE_TYPE_PDF, contentType: Constants.CONTENT_TYPE_MODULAR},
    {id: 5, moduleType: null, contentType: Constants.CONTENT_TYPE_BREAK},
    {id: 6, moduleType: Constants.MODULE_TYPE_HIGHLIGHT, contentType: Constants.CONTENT_TYPE_MODULAR},
    {id: 7, moduleType: null, contentType: Constants.CONTENT_TYPE_QUESTIONNAIRE},
    {id: 8, moduleType: null, contentType: Constants.CONTENT_TYPE_TASK_DOCUMENT},
    {id: 9, moduleType: Constants.MODULE_TYPE_SKETCH, contentType: Constants.CONTENT_TYPE_MODULAR},
    {id: 10, moduleType: Constants.MODULE_TYPE_ZIF, contentType: Constants.CONTENT_TYPE_MODULAR},
    {id: 11, moduleType: Constants.MODULE_TYPE_EASY_ZOOM, contentType: Constants.CONTENT_TYPE_MODULAR},
    {id: 12, moduleType: Constants.MODULE_TYPE_HTML, contentType: Constants.CONTENT_TYPE_MODULAR},
    {id: 13, moduleType: null, contentType: Constants.CONTENT_TYPE_CONTENT_CONTAINER}
  ];

  public static MODULE_TYPES_ICONS = {
    [Constants.MODULE_TYPE_TEXT]: 'subject',
    [Constants.MODULE_TYPE_HTML]: 'html',
    [Constants.MODULE_TYPE_HIGHLIGHT]: 'format_list_bulleted',
    [Constants.MODULE_TYPE_IMAGE]: 'photo',
    [Constants.MODULE_TYPE_PDF]: 'picture_as_pdf',
    [Constants.MODULE_TYPE_VIDEOS]: 'slideshow',
    [Constants.MODULE_TYPE_SKETCH]: 'view_in_ar',
    [Constants.MODULE_TYPE_EASY_ZOOM]: 'zoom_in',
    [Constants.MODULE_TYPE_ZIF]: 'all_out',
    [Constants.CONTENT_TYPE_BREAK]: 'playlist_add',
    [Constants.CONTENT_TYPE_QUESTIONNAIRE]: 'help',
    [Constants.CONTENT_TYPE_TASK_DOCUMENT]: 'done',
    [Constants.CONTENT_TYPE_CONTENT_CONTAINER]: 'auto_awesome_mosaic'};

  public static MODULE_TYPES_ADD_BUTTON_HINT = {
    [Constants.MODULE_TYPE_IMAGE]: 'text.editor.add.button.image.hint',
    [Constants.MODULE_TYPE_PDF]: 'text.editor.add.button.pdf.hint',
    [Constants.MODULE_TYPE_ZIF]: 'text.editor.add.button.zif.hint'};


  public static readonly MODULE_TYPES_FILE = {
    [Constants.MODULE_TYPE_IMAGE]: true,
    [Constants.MODULE_TYPE_PDF]: true,
    [Constants.MODULE_TYPE_ZIF]: true,
    [Constants.MODULE_TYPE_PECHA_KUCHA]: true
  };

  public static AUTOPLAY_TIME = [
    0,
    1000,
    2000,
    5000,
    10000,
    20000,
    60000];

  public static SECTION_STATUS_OPEN = 'open';
  public static SECTION_STATUS_PLANNED = 'planned';
  public static SECTION_STATUS_DRAFT = 'draft';

  public static readonly ORDERINDEX = 'orderIndex';

  public static EVENT_STATUS_NOT_PUBLISHED = 'event.status.not.published';
  public static EVENT_STATUS_PREP_OPENS_ON = 'event.status.prep.opens.on';
  public static EVENT_STATUS_PREP_START_ON = 'event.status.prep.event.starts.on';
  public static EVENT_STATUS_PREP_OPEN_ON = 'event.status.prep.event.open.on';
  public static EVENT_STATUS_PREP_OPEN_FROM = 'event.status.prep.event.open.from';
  public static EVENT_STATUS_EVENT_STARTS_ON = 'event.status.event.starts.on';
  public static EVENT_STATUS_EVENT_OPEN_ON = 'event.status.event.open.on';
  public static EVENT_STATUS_EVENT_OPEN_FROM = 'event.status.event.open.from';
  public static EVENT_STATUS_EVENT_OPEN_TO = 'event.status.event.open.to';
  public static EVENT_STATUS_EVENT_STARTS_IN = 'event.status.event.starts.in';
  public static EVENT_STATUS_EVENT_OPEN_IN = 'event.status.event.open.in';
  public static EVENT_STATUS_RUNNING = 'event.status.running';
  public static EVENT_STATUS_RUNNING_EVENT_FINISH_IN = 'event.status.running.event.finish.in';
  public static EVENT_STATUS_WRAP_UP_UNTIL = 'event.status.wrap.up.until';
  public static EVENT_STATUS_FINISHED = 'event.status.finished';
  public static EVENT_STATUS_DRAFT = 'event.status.draft';

  public static EVENT_FILTER_STATUS_ALL_OPEN_STATES = 'event.filter.status.all.open.states';
  public static EVENT_FILTER_STATUS_ALL_STATES = 'event.filter.status.all.states';
  public static EVENT_FILTER_STATUS_PUBLISHED = 'event.filter.status.published';
  public static EVENT_FILTER_STATUS_UNPUBLISHED = 'event.filter.status.unpublished';
  public static EVENT_FILTER_STATUS_PUBLIC = 'event.filter.status.public';
  public static EVENT_FILTER_STATUS_PUBLIC_BY_LINK = 'event.filter.status.public.by.link';
  public static EVENT_FILTER_STATUS_PRIVATE = 'event.filter.status.private';
  public static EVENT_FILTER_STATUS_PREPARATION = 'event.filter.status.preparation';
  public static EVENT_FILTER_STATUS_RUNNING = 'event.filter.status.running';
  public static EVENT_FILTER_STATUS_WRAP_UP = 'event.filter.status.wrap.up';
  public static EVENT_FILTER_STATUS_FINISHED = 'event.filter.status.finished';
  public static EVENT_FILTER_STATUS_DRAFT = 'event.filter.status.draft';
  public static EVENT_FILTER_UPCOMING = 'event.filter.upcoming';
  public static EVENT_FILTER_PAST_EVENTS = 'event.filter.past.events.i.attended';
  public static EVENT_FILTER_RECENTLY_MODIFIED_BY_ME = 'recently.modified.by.me';

  public static DAILY_CO_PARTICIPANT_ON_PAGE = 4;

  public static PERSONAL_WEBSITE =  'personal_website_home';
  public static GOOGLE_PLUS = 'google_plus';
  public static FACEBOOK = 'facebook';
  public static LINKEDIN = 'linkedin';
  public static XING = 'xing';
  public static TWITTER = 'twitter';
  public static SKYPE = 'skype';
  public static HANGOUT = 'hangout';
  public static INSTAGRAM = 'instagram';
  public static SLACK = 'slack';
  public static TEAMS = 'teams';
  public static X = 'x';


  public static DEFAULT_USER_LOGO = 'assets/images/photo.jpg';
  public static DEFAULT_MOOD = 'ic_default_48px.svg';

  /**
   * Users platform OS
   */
  public static IPHONE = 'iPhone';
  public static WINDOWS = 'Windows';
  public static MAC_OS = 'Mac OS';
  public static LINUX = 'Linux';
  public static ANDROID = 'Android';

  /**
   * Users browser
   */
  public static SAFARI = 'safari';
  public static MSIE = 'msie';

  /**
   * List of social network
   */
  public static SOCIAL_NETWORK_LIST = [
      Constants.PERSONAL_WEBSITE
    , Constants.FACEBOOK
    , Constants.LINKEDIN
    , Constants.XING
    , Constants.X
    , Constants.INSTAGRAM
    , Constants.SLACK
    , Constants.TEAMS
  ];

  public static SOCIAL_NETWORK_TABLE =
    {
      'personal_website_home' : {
        'sitename' : 'Personal website',
        'template' : 'http://myhomepage.domain'
      },
      'google_plus' : {
        'sitename' : 'Google+',
        'template' : 'https://plus.google.com/profile-page-link'
      },
      'facebook' : {
        'sitename' : 'Facebook',
        'template' : 'https://www.facebook.com/profile-page-link'
      },
      'linkedin' : {
        'sitename' : 'Linked in',
        'template' : 'https://www.linkedin.com/profile-page-link'
      },
      'xing' : {
        'sitename' : 'XING',
        'template' : 'https://www.xing.com/profile-page-link'
      },
      'twitter' : {
        'sitename' : 'Twitter',
        'template' : 'https://twitter.com/profile-page-link'
      },
      'skype' : {
        'sitename' : 'Skype',
        'template' : 'pseudonym',
        'external_link': 'skype:profile_name\?userinfo'
      },
      'hangout' : {
        'sitename' : 'Google Hangout',
        'template' : 'https://hangouts.google.com/link'
      },
      'instagram' : {
        'sitename' : 'Instagram',
        'template' : 'https://www.instagram.com/profile-page-link'
      },
      'slack' : {
        'sitename' : 'Slack',
        'template' : 'https://slack.com/profile-page-link',
      },
      'teams' : {
        'sitename' : 'Microsoft Teams',
        'template' : 'https://teams.microsoft.com'
      },
      'x' : {
        'sitename' : 'X',
        'template' : 'https://twitter.com/profile-page-link'
      },
    };

  /**
   * FB questionnaire nodes, name and question type
   */
  public static QTYPE_CHECK = 0;
  public static QTYPE_CHOICE = 1;
  public static QTYPE_TEXT = 3;
  public static QTYPE_WORD_CLOUD = 4;
  public static QTYPE_MATCHING = 5;
  public static QTYPE_SCALE = 6;
  public static QTYPE_SELECT_GAP_FILLING = 7;
  public static QTYPE_TEXT_GAP_FILLING = 8;
  public static QTYPE_CHECKBOX_GAP_FILLING = 9;
  public static QTYPE_MATCHING_MAP = 10;
  public static QTYPE_TEXT_BALLON = 11;
  public static QTYPE_FILE_UPLOAD = 12;
  public static QTYPE_SEQUENCE = 13;
  public static QTYPE_PRIORITIZATION = 14;
  public static QTYPE_CHECK_V2 = 15;
  public static QTYPE_CHOICE_V2 = 16;
  public static ALL_QTYPE = [0, 1, 6, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  // @deprecated
  public static REGISTRATION_QTYPE = [0, 1, 3];
  // @deprecated
  public static USE_CORRECT_QTYPE = [0, 1, 15, 16];
  // @deprecated
  public static GAP_QTYPE = [7, 8, 9];
  // @deprecated
  public static MATCHING_QTYPE = [5, 10];

  public static FT_IMAGE = 'image';
  public static FT_VIDEO = 'video';
  public static FT_AUDIO = 'audio';
  public static FT_DOC = 'doc';
  public static FT_OTHER = 'other';

  public static FT_IMAGE_SIZE = 1024 * 1024 * 10; // 10MB
  public static FT_VIDEO_SIZE = 1024 * 1024 * 100; // 100MB
  public static FT_AUDIO_SIZE = 1024 * 1024 * 20; // 20MB
  public static FT_DOC_SIZE = 1024 * 1024 * 20; // 20MB
  public static FT_OTHER_SIZE = 1024 * 1024 * 50; // 50MB

  public static FILE_TYPES = {
    [Constants.FT_IMAGE]: { extension: ['JPG', 'JPEG', 'PNG', 'GIF', 'SVG', 'WEBP', 'HEIC'], sizeLimit: Constants.FT_IMAGE_SIZE },
    [Constants.FT_VIDEO]: { extension: ['MP4'], sizeLimit: Constants.FT_VIDEO_SIZE },
    [Constants.FT_AUDIO]: { extension: ['MP3', 'AAC', 'M4A'], sizeLimit: Constants.FT_AUDIO_SIZE },
    [Constants.FT_DOC]: { extension: ['PDF', 'DOCX', 'XLSX', 'PPTX'], sizeLimit: Constants.FT_DOC_SIZE },
    [Constants.FT_OTHER]: { extension: ['ZIP', 'CSV'], sizeLimit: Constants.FT_OTHER_SIZE }
  };

  public static NODE_QUESTIONS = 'questions';
  public static NODE_ANSWERS = 'answers';
  public static NODE_QUESTION_ITEMTS = 'items';
  public static NODE_QUESTION_CORRECT_ITEMTS = 'correctItems';
  public static NODE_LASTCHANGE = 'lastchange';
  public static NODE_DEPENDENCY = 'dependency';

  public static QSTATUS_NOT_ANSWERED = 0;
  public static QSTATUS_PARTLY_ANSWERED = 1;
  public static QSTATUS_ANSWERED = 2;
  public static QSTATUS_ALL_STATUS = -1;

  public static SPEAKERS = 3;
  public static CONCIERGES = 5;
  public static ATTENDEES = 4;
  public static PRESENTERS = 6;
  public static SPEAKERS_ATTENDEES = -1;

  public static USTAT_HAS_NOTES = 5;
  public static USTAT_NEVER_LOGGED = 6;
  public static USTAT_ONLINE_RECENTLY_ONLINE = 7;
  public static USTAT_ACTIVE_ONLINE = 8;

  public static QROLE_ALL_USERS = -1;
  public static QROLE_PARTICIPANTS = 0;
  public static QROLE_VIEWERS = 2;
  public static QROLE_PRESENTERS = 1;

  public static QSORT_NONE = -1;
  public static QSORT_USER_NAME = 1;

  public static USORT_NOTES = 3;
  public static USORT_INVITED = 4;
  public static USORT_JOINED_EVENT = 5;
  public static USORT_LAST_ACTIVITY = 6;
  public static USORT_ONLINE_STATUS = 7;
  public static USORT_REG_DATE = 8;

  public static readonly DELETED = -1;
  public static readonly NEED_UPLOAD = 0;
  public static readonly UPLOADED = 1;
  public static readonly NEED_RELOAD = -2;
  public static readonly NEED_REUPLOAD = -3;

  public static readonly MODULE_STATUS_DELETE = -1;
  public static readonly MODULE_STATUS_NEW = 0;
  public static readonly MODULE_STATUS_SAVED = 1;

  public static ORDER_INDEX_INCREMENT = 1000000;
  public static RELEVANCY_MAX_PERCENT = 100;

  public static ONLINE_OPACITY = 1;
  public static OFFLINE_OPACITY = 0.5;

  public static EXPORT_CSV = 1;
  public static COPYTO_CLIPBOARD = 2;
  public static BLOCK = 3;
  public static DELETE_FROM_EVENT = 4;


  public static LIST_USERS_MODE_NOCLICK = 0;
  public static LIST_USERS_MODE_SPEAKERS = 1;
  public static LIST_USERS_MODE_ALLUSERS = 2;

  public static TIME_00_00 = '00:00';
  public static TIME_NULL = '--:--';

  public static BREAK_PROCESS = 'break_process';

  public static ACCESS_SET_CURRENT = 1;
  public static ACCESS_SET_ABOVE = 2;
  public static ACCESS_NOT_SET = 3;

  /**
   * For array. move down at the interface value = -V, but
   * at the porgramm array this is value = +V
   * @type {number}
   */
  public static MOVEUP = -1;
  public static MOVEDOWN = 1;

  /**
   * Action panel add content action types
   * @type {string[]}
   */
  public static CONTENT_SUBTYPE_CLASS_BREAK_ICON = 'tl-icon-break';
  public static CONTENT_SUBTYPE_CLASS_QUESTION_ICON = 'tl-icon-question';
  public static CONTENT_SUBTYPE_CLASS_TASK_ICON = 'tl-icon-task';
  public static CONTENT_SUBTYPE_CLASS_SUMMARISE_ICON = 'tl-icon-summarise';
  public static CONTENT_SUBTYPE_CLASS_QUESTIONNAIRE_ICON = 'tl-icon-questionnaire';

  public static FILTER_TYPE_ALL = 'all';

  public static SORT_BY_TIME = 'time';
  public static SORT_BY_CONTENT_UPDATE_TIME = 'updateTime';
  public static SORT_BY_RELEVANCY = 'relevancy';

  /**
   * max date 01.01.2200
   */
  public static MAX_DATE_MILLIS_01_01_2200 = 7258107600000;

  /**
   * Time increment constants in milliseconds
   */
  public static ONE_MINUTE_MS = 60000;
  public static ONE_HOUR = 3600000;
  public static ONE_DAY = 86400000;
  public static FOUR_HOUR = 14400000;
  public static ONE_WEEK = 604800000;
  public static ONE_DAY_2359 = 86340000;
  public static ONE_WEEK_2359 = 604740000;

  /**
   * Event type default icon
   */
  public static readonly EVENT_TYPE_DEFAULT_ICON = 'assets/images/event_types/event.png';
  public static readonly EVENT_TYPE_TEMPLATE_ID = 1000000;

  /**
   * Section type icon
   */
  public static SECTION_TYPE_DEFAULT_ICON = 'brightness_1';
  public static SECTION_TYPE_BREAK_ICON = 'local_cafe';
  public static SECTION_TYPE_MEAL_ICON = 'restaurant';
  public static SECTION_TYPE_ACCOMMODATION_ICON = 'hotel';
  public static SECTION_TYPE_ACTIVITY_ICON = 'flash_on';
  public static SECTION_TYPE_EVENT_ICON = 'event';
  public static SECTION_TYPE_TRANSFER_ICON = 'commute';
  public static SECTION_TYPE_PREPARATION_ICON = 'local_library';
  public static SECTION_TYPE_QA_ICON = 'forum';

  public static SECTION_TYPE_CUSTOM_ICON1 = 'extension';
  public static SECTION_TYPE_CUSTOM_ICON2 = 'hourglass_empty';
  public static SECTION_TYPE_CUSTOM_ICON3 = 'grade';
  public static SECTION_TYPE_CUSTOM_ICON4 = 'help';
  public static SECTION_TYPE_CUSTOM_ICON5 = 'info';
  public static SECTION_TYPE_CUSTOM_ICON6 = 'http';
  public static SECTION_TYPE_CUSTOM_ICON7 = 'feedback';
  public static SECTION_TYPE_CUSTOM_ICON8 = 'toys';
  public static SECTION_TYPE_CUSTOM_ICON9 = 'grain';
  public static SECTION_TYPE_CUSTOM_ICON10 = 'directions_bike';
  public static SECTION_TYPE_CUSTOM_ICON11 = 'directions_boat';
  public static SECTION_TYPE_CUSTOM_ICON12 = 'flight';
  public static SECTION_TYPE_CUSTOM_ICON13 = 'fitness_center';
  public static SECTION_TYPE_CUSTOM_ICON14 = 'pool';
  public static SECTION_TYPE_CUSTOM_ICON15 = 'play_circle_outline';

  public static SECTION_TYPE_PREDEFINED_ICONS = [
    Constants.SECTION_TYPE_DEFAULT_ICON,
    Constants.SECTION_TYPE_BREAK_ICON,
    Constants.SECTION_TYPE_MEAL_ICON,
    Constants.SECTION_TYPE_ACCOMMODATION_ICON,
    Constants.SECTION_TYPE_ACTIVITY_ICON,
    Constants.SECTION_TYPE_EVENT_ICON,
    Constants.SECTION_TYPE_TRANSFER_ICON,
    Constants.SECTION_TYPE_PREPARATION_ICON,
    Constants.SECTION_TYPE_QA_ICON,
    Constants.SECTION_TYPE_CUSTOM_ICON1,
    Constants.SECTION_TYPE_CUSTOM_ICON2,
    Constants.SECTION_TYPE_CUSTOM_ICON3,
    Constants.SECTION_TYPE_CUSTOM_ICON4,
    Constants.SECTION_TYPE_CUSTOM_ICON5,
    Constants.SECTION_TYPE_CUSTOM_ICON6,
    Constants.SECTION_TYPE_CUSTOM_ICON7,
    Constants.SECTION_TYPE_CUSTOM_ICON8,
    Constants.SECTION_TYPE_CUSTOM_ICON9,
    Constants.SECTION_TYPE_CUSTOM_ICON10,
    Constants.SECTION_TYPE_CUSTOM_ICON11,
    Constants.SECTION_TYPE_CUSTOM_ICON12,
    Constants.SECTION_TYPE_CUSTOM_ICON13,
    Constants.SECTION_TYPE_CUSTOM_ICON14,
    Constants.SECTION_TYPE_CUSTOM_ICON15
  ];

  public static SECTION_TYPE_BREAK = 'break';
  public static SECTION_TYPE_MEAL = 'meal';
  public static SECTION_TYPE_ACCOMMODATION = 'accommodation';
  public static SECTION_TYPE_ACTIVITY = 'activity';
  public static SECTION_TYPE_EVENT = 'day';
  public static SECTION_TYPE_TRANSFER = 'transfer';
  public static SECTION_TYPE_PREPARATION = 'preparation';
  public static SECTION_TYPE_QA = 'q_a';

  public static EVENT_SECTION_TYPE_DEFAULT = [
    {name: Constants.SECTION_TYPE_BREAK, icon: Constants.SECTION_TYPE_BREAK_ICON},
    {name: Constants.SECTION_TYPE_MEAL, icon: Constants.SECTION_TYPE_MEAL_ICON},
    {name: Constants.SECTION_TYPE_ACCOMMODATION, icon: Constants.SECTION_TYPE_ACCOMMODATION_ICON},
    {name: Constants.SECTION_TYPE_ACTIVITY, icon: Constants.SECTION_TYPE_ACTIVITY_ICON},
    {name: Constants.SECTION_TYPE_EVENT, icon: Constants.SECTION_TYPE_EVENT_ICON},
    {name: Constants.SECTION_TYPE_TRANSFER, icon: Constants.SECTION_TYPE_TRANSFER_ICON},
    {name: Constants.SECTION_TYPE_PREPARATION, icon: Constants.SECTION_TYPE_PREPARATION_ICON},
    {name: Constants.SECTION_TYPE_QA, icon: Constants.SECTION_TYPE_QA_ICON}
  ];

  /**
   * Type copy section/content
   */
  public static CONTENT_CLIPBOARD_CUT = 'cut';
  public static CONTENT_CLIPBOARD_COPY = 'copy';

  /**
   * Registration mode
   */
  public static REGISTRATION_MODE = 'registration';
  public static UNREGISTERED_MODE = 'unregistered';
  /**
   * Constants of return proc tree methods for analyze in external calls.
   */
  public static SHOW_QUESTIONNAIRE = 'show.questionnaire';
  public static SUCCESS = 'success';
  public static SKIP = 'skip';
  public static ERROR = 'error';
  public static WARNING = 'warning';
  public static REVERT = 'revert';
  public static OPEN_TIMELINE = 'open.timeline';
  public static JOIN_TO_CONTAINER = 'join_to_container';
  /**
   * Exchange message selected types
   */
  public static SELECT_TYPE_REGISTERED = 'registered';
  public static SELECT_TYPE_NOT_REGISTERED = 'not_registered';
  /**
   * Action constants for use in external calls.
   */
  public static readonly ACTION_FINISH_REGISTRATION = 'action.complete.registration.finished';
  public static readonly ACTION_ADD_TO_NOT_REGISTERED = 'action.add.not.registered';
  public static readonly ACTION_UNREGISTERED_WITH_DELETE_REGINFO = 'action.unregister.with.delete.reginfo';
  public static readonly ACTION_REGISTRATION_CLOSE = 'action.registration.close';
  public static readonly ACTION_UNREGISTER = 'action.unregister';
  public static readonly ACTION_COMPLETE_SPECIAL_REGISTRATION = 'action.complete.special.registration';
  public static readonly ACTION_NOT_ATTENDING = 'action.not.attending';

  /**
   * Registration tree statuses list (for use in tree)
   */
  public static readonly REGISTRATION_STATUS_NOT_REGISTERED = 'not.registered';
  public static readonly REGISTRATION_STATUS_WAITING_FOR_APPROVAL = 'waiting.for.approval';
  public static readonly REGISTRATION_STATUS_ON_WAITING_LIST = 'on.waiting.list';
  public static readonly REGISTRATION_STATUS_REGISTRATION_CONFIRMED = 'registration.confirmed';
  public static readonly REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE = 'register.continue.incomplete';
  public static readonly REGISTRATION_STATUS_REGISTRATION_COMPLETED = 'registration.completed';
  public static readonly REGISTRATION_STATUS_NEW = 'registration.new';
  public static readonly REGISTRATION_STATUS_NOT_ATTENDING = 'not.attending';
  public static readonly REGISTRATION_STATUS_SPECIAL_REGISTRATION = 'special.registration';
  public static readonly REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL = 'waiting.for.event.approval';

  /**
   * User registration status short name (for use in code).
   */
  public static UNREGISTERED_STATUS = {
    [Constants.REGISTRATION_STATUS_NOT_REGISTERED]: true,
    [Constants.REGISTRATION_STATUS_NEW]: true,
    [Constants.REGISTRATION_STATUS_NOT_ATTENDING]: true
  };

  public static REGISTERED_STATUS_CAN_BE_CONTINUE = {
    [Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE]: true,
    [Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED]: true
  };

  public static WAITING_REGISTERED_STATUS = {
    [Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL]: true,
    [Constants.REGISTRATION_STATUS_ON_WAITING_LIST]: true,
    [Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL]: true
  };

  public static REGISTER_STATUS = {
    [Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED]: true,
    [Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION]: true
  };

  public static REGISTRATION_STATUS = {
    [Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED]: true,
    [Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE]: true,
    [Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED]: true,
    [Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL]: true,
    [Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL]: true
  };

  public static REGISTRATION_STATUS_LIST = [
      Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED
    , Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION
    , Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE
    , Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED
    , Constants.REGISTRATION_STATUS_ON_WAITING_LIST
    , Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL
    , Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL
    , Constants.REGISTRATION_STATUS_NOT_ATTENDING
    , Constants.REGISTRATION_STATUS_NOT_REGISTERED
    , Constants.REGISTRATION_STATUS_NEW
  ];

  public static readonly WORD_CLOUD_ID_DEFAULT_PREFIX = 'DEFAULT-';

  public static readonly LEARNING_RESULT_COMPLETE = -1;
  public static readonly LEARNING_RESULT_NOT_COMPLETE = -2;
  public static readonly LEARNING_RESULT_NOT_USED = -3;
  public static readonly LEARNING_RESULT_EMPTY = -100;

  public static readonly EXTERNAL_CLOSE_DIALOG_OK = 1;

  public static ERROR_REQUEST = 'ERROR_REQUEST';
  public static ERROR_BACKEND_GET_TOKEN = 'Error getting token';

  public static ALL_STATUS = 'table_filter_option.allStatus';
  public static PDF_VIEW_MODE_ALL_PAGE = 'pdfAllPage';
  public static PDF_VIEW_FIT_TO_SCREEN = 'pdfFitToScreen';

  public static readonly VIDEO_SITE_TEMPLATES = {
    YOUTUBE: {
      urlTemplate: [/(youtube.com)\/\w+.\w+=([-\w\d]+)/, /.('youtube.com')\/embed\/([-\w\d]+)"/,
        /(youtu.be)\/([-\w\d]+)/, /(youtube.com)\/embed\/([-\w\d]+)/],
      urlPlayer: 'https://www.youtube.com/embed/{{id}}'
    },
    VIMEO: {
      urlTemplate: [/(player.vimeo.com)\/video\/(\d+)\?/, /(player.vimeo.com)\/video\/(\d+)/, /(vimeo.com)\/(\d+)/],
      urlPlayer: 'https://player.vimeo.com/video/{{id}}'
    },
    ROCHE: {
      urlTemplate: [/.+(streamingmedia.roche.com).+/],
      urlPlayer: ''
    },
    KALTURA: {
      urlTemplate: [/.+(cdnapisec.kaltura.com).+/],
      urlPlayer: ''
    },
    GOOGLE_DRIVE: {
      urlTemplate: [/.+(drive.google.com)\/open\?id=(.+)/, /.+(drive.google.com)\/file\/d\/(.+)\/preview".+/,
        /.+(drive.google.com)\/file\/d\/(.+)\/.+/],
      urlPlayer: 'https://drive.google.com/file/d/{{id}}/preview'
    },
    ZDF: {
      urlTemplate: [/(ngp.zdf.de)\/(miniplayer)\/(embed)\/(\?mediaID=)([%\w-]+)/],
      urlPlayer: 'https://ngp.zdf.de/miniplayer/embed/?mediaID={{id}}'
    },
    SRF: {
      urlTemplate: [/(srf.ch)\/(play)\/(embed)\?(urn=urn:srf:video:)([\w-]+)/, /(srf.ch)\/(play)([\/\w]+)\/([\w-]+)/],
      urlPlayer: 'https://www.srf.ch/play/embed?urn=urn:srf:video:{{id}}&subdivisions=false'
    }
  };

  public static readonly SKETCH_SITE_TEMPLATES = {
    SKETCH: {
      urlTemplate: [/(.+)(sketchfab.com)\/models\/([-0-9a-zA-Z]+)\/embed(.+)/,
        /(.+)(sketchfab.com)\/3d-models\/(.+)-([-0-9a-zA-Z]+)/,
        /([-0-9a-zA-Z]+)/],
      urlPlayer: '{{id}}'
    }
  };

  public static readonly EASY_ZOOM_SITE_TEMPLATES = {
    EASY_ZOOM: {
      urlTemplate: [/(.+)(easyzoom.com)\/imageaccess\/([-0-9a-zA-Z]+)/, /(.+)(easyzoom.com)\/embed\/([-0-9a-zA-Z]+)/,
        /(.+)(easyzoom.com)\/image\/([-0-9a-zA-Z]+)/],
      urlPlayer: 'https://www.easyzoom.com/embed/{{id}}'
    }
  };

  public static readonly VIDEO_SITE_RECORDED_STREAMING = ['YOUTUBE', 'KALTURA', 'ROCHE'];

  public static readonly SCREEN_SHARING_RUN = '(SCREEN SHARING)';

  public static readonly INFO_REGISTRATION_ELEMENTS_ORDER_ARR = [
    'sectionTypeId', 'location', 'countQuestions', 'plannedTime'
  ];

  public static FEEDBACK_RATE = [
    'feedback.rate.value.poor',
    'feedback.rate.value.average',
    'feedback.rate.value.good',
    'feedback.rate.value.very.good',
    'feedback.rate.value.wow'];

  public static LANGUAGE_LIST: ILanguage[] = [
    {name: 'language.english', value: 'en'},
    {name: 'language.deutsch', value: 'de'},
    {name: 'language.portuguese', value: 'pt'},
    {name: 'language.french', value: 'fr'},
    {name: 'language.spanish', value: 'es'},
    {name: 'language.simplified.chinese', value: 'zh'},
    {name: 'language.italian', value: 'it'},
    {name: 'language.turkish', value: 'tr'},
    {name: 'language.japanese', value: 'ja'},
    {name: 'language.russian', value: 'ru'},
    {name: 'language.portuguese-br', value: 'pt-BR'}
  ];

  private static userKey = null;
  public static getUserKey() {
    let userKey = Constants.userKey;
    if (!userKey) {
      userKey = window.name;
    }
    if (!userKey) {
      userKey = localStorage.getItem('last-user-key');
    }
    localStorage.removeItem('last-user-key');
    if (!userKey || !(userKey.lastIndexOf('user-key', 0) === 0)) {
      userKey = 'user-key-' + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        /* tslint:disable:no-bitwise */
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 );
        /* tslint:enable:no-bitwise */
        return v.toString(16);
      });
    }
    window.name = userKey;
    Constants.userKey = userKey;
    return userKey;
  }

  public static initFirebaseName() {
    if (!Constants.FIREBASE_NAME) {
      Constants.FIREBASE_NAME = 'ITC Firebase';
    }
    return Constants.FIREBASE_NAME;
  }

  public static readonly PENCIL_COLORS = [
    '#69B1FF', '#4096FF', '#1677FF', '#0958D9', '#B37FEB', '#9254DE', '#722ED1', '#531DAB', '#5CDBD3',
    '#36CFC9', '#13C2C2', '#08979C', '#95DE64', '#73D13D', '#52C41A', '#389E0D', '#FF85C0', '#F759AB',
    '#EB2F96', '#C41D7F', '#FF7875', '#FF4D4F', '#F5222D', '#CF1322', '#FFC069', '#FFA940', '#FA8C16',
    '#D46B08', '#FFF566', '#FFEC3D', '#FADB14', '#D4B106', '#FF9C6E', '#FF7A45', '#FA541C', '#D4380D',
    '#FFD666', '#FFC53D', '#FAAD14', '#D48806',
  ];
}

export enum COPY_TYPE {
  QUESTION_TEMPLATE = 'question_template',
  QUESTION = 'question',
  IMAGE = 'image',
  PDF = 'pdf',
  ZIF = 'zif',
  CLIPBOARD = 'clipboard',
  TASK_FILE = 'task_file'
}

export enum CLOCK_TYPE {
  HOURS = 1,
  MINUTES = 2
}

export enum PHASE_ID_SUFFIX {
  PREP = '-PREP',
  START = '-START',
  END = '-END',
  WRAP_UP = '-WRAP_UP',
  WEEK_DAY = '-WEEK_DAY',
  EASY_QA = '-EASY_QA',
  BEFORE = '-BEFORE',
  AFTER = '-AFTER'
}

export enum DATE_FORMAT {
  DD_MM_YYYY_HH_mm = 'dd.MM.y, HH:mm',
  DD_MM_YY_HH_mm = 'dd.MM.yy, HH:mm',
  HH_mm = 'HH:mm',
  HH_mm_ss = 'HH:mm:ss',
  mm_ss = 'mm:ss',
  DD_MM_YYYY = 'dd.MM.y',
  DD_MM_YY = 'dd.MM.yy',
  DD_MM_YY_HH_mm_ss = 'dd.MM.yy, HH:mm:ss',
  DD_MM_YYYY_HH_mm_ss = 'dd.MM.yyyy, HH:mm:ss',
  WEEK_DAY_DD_MM = 'EEEEEE dd.MM',
  WEEK_DAY_MM_YY_HH_mm = 'EEEEEE dd.MM.yy, HH:mm',
  WEEK_DAY_FULL_HH_mm = 'EEEE, HH:mm',
  WEEK_DAY_FULL_DD_MM = 'EEEE, dd.MM',
  WEEK_DAY_FULL_DD_MM_YY_HH_mm = 'EEEE, dd.MM.yy, HH:mm',
  WEEK_DAY_FULL_DD_MM_YY = 'EEEE, dd.MM.yy',
  DD_MM_HH_mm = 'dd.MM, HH:mm',
  yyyy_MM_dd_HH_mm_ss = 'yyyyMMddTHHmmss',
  DD_MM = 'dd.MM',
  SSS = 'SSS',
  MOMENT_JS_DD_MM = 'DD.MM',
  MOMENT_JS_DD_MM_YYYY = 'DD.MM.YYYY',
  MOMENT_JS_DD_MM_YYYY_HH_mm = 'DD.MM.YYYY, HH:mm '
}

export enum PROCESS_STATUS {
  STOP = 'STOP',
  START = 'START',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR'
}

export enum CONTENT_FROM_AUDIENCE_MODE {
  NONE = 'none',
  EASY_QA = 'easy_qa',
  EASY_CONTENT = 'easy_content'
}

export enum ANONYMOUS_OPTION {
  ANONYMOUS = 'anonymously',
  ANONYMOUS_ON_CHOICE = 'anonymously_on_choice',
  NOT_ANONYMOUS = 'not_anonymously'
}

export enum FIXED_SECTION_TYPE {
  EASY_NEW = 'easy_new',
  EASY_DONE = 'easy_done',
  EASY_SUGGESTED = 'easy_suggested'
}

export enum FIXED_SECTION_TYPE_ORDER_INDEX {
  EASY_SUGGESTED_ORDER_INDEX = Constants.MAX_ORDER_INDEX_VALUE - 6000000,
  EASY_NEW_ORDER_INDEX = Constants.MAX_ORDER_INDEX_VALUE - 4000000,
  EASY_DONE_ORDER_INDEX = Constants.MAX_ORDER_INDEX_VALUE - 2000000
}

export enum CONTENT_SPECIFICATION {
  EASY_CONTENT = 'easy_content',
}

export enum SECTION_EVENT_PHASE {
  PREP = 'prep',
  WRAP_UP = 'wrap.up',
}

export enum MANAGE_TIME_ROW_TYPE {
  START = 'start',
  PREP = 'prep',
  WRAP_UP = 'wrap.up',
  SECTION = 'section',
  END = 'end',
  DAY = 'day',
  TOOLS = 'tools'
}

export enum EMULATE_RESULT {
  STOP = 'stop',
  AS_IS = 'as_is',
  AS_IS_WITH_FIXED_START_BASE_ON_PREV_SECTION = 'as_is_with_fixed_start_base_on_prev_section',
  WITHOUT_START_END = 'without_start_end',
  WITHOUT_START_END_WITH_EXTEND = 'without_start_end_with_extend',
  WITHOUT_DURATION = 'without_duration',
  WITHOUT_DURATION_WITH_START_END = 'without_duration_with_start_end',
  WITH_START_WITHOUT_DURATION_WITHOUT_END = 'with_start_without_duration_without_end',
  WITH_FIXED_START_AND_DURATION_BASE_ON_PREV_SECTION = 'with_fixed_start_and_duration_base_on_prev_section',
  WITH_FIXED_START_BASE_ON_PREV_SECTION = 'with_fixed_start_base_on_prev_section'
}

export enum TRIPLE {
  YES,
  CANCEL,
  OTHER
}

export enum USER_LIST_PAGE {
  BUFFER = 200,
  INIT_PAGE_LENGTH = 30,
  PAGE_STEP = 10
}

export enum SECTION_USER_LIST_PAGE {
  BUFFER = 200,
  INIT_PAGE_LENGTH = 10,
  PAGE_STEP = 10
}

export enum EVENTS_LIST_PAGE {
  BUFFER = 200,
  INIT_PAGE_LENGTH = 25,
  PAGE_STEP = 10
}

export enum IG_USER_LIST_PAGE {
  BUFFER = 200,
  INIT_PAGE_LENGTH = 30,
  PAGE_STEP = 10
}

export enum EVENT_DOP_STATE {
  PUBLIC_BY_LINK = 1
}

export enum EVENT_STATE {
  DRAFT,
  PRIVATE,
  PUBLIC_ON_DASHBOARD,
  PUBLIC_BY_LINK
}

export enum SECTION_LEARNING_TYPE {
  NOT_IN_LEARNING = 'not.in.learning',
  LEARNING_NOT_COMPLETE = 'in.learning',
  LEARNING_COMPLETE = 'complete',
  NOT_OPEN = 'not.started'
}

export enum TEMPLATE_TAGS {
  USER_FULL_NAME = '${UserFullName}',
  COMPLETE_DATE = '${CompleteDate}'
}

export enum EVENT_DATE_MODE {
  SPECIFIC_DATE_TIME = 0,
  PUBLISHED_WITH_TIME_RANGE = 1,
  ANYTIME = 2
}

export enum EVENT_DATE_MODE_FILTER {
  LESS_THAN_5_HOURS = 'less.than.5.hours',
  MORE_THAN_1_DAY= 'more.than.1.day'
}

export enum EVENT_TIMELINE_MODE {
  NO = 0,
  VISIBLE = 1,
  HIDDEN = 2
}

export enum SELF_LEARNING_MODE {
  ONE_TIME_VIEW = 'one.time.view',
  MIN_TIME_SPENT= 'min.time.spent',
  READING = 'reading',
  ALL_QUESTIONNAIRES_COMPLETE = 'all.questionnaires.complete'
}

export enum SCROLL_TO {
  LEFT,
  RIGHT,
  STAY,
  NOT_MOVE
}

/**
 * Current content actions
 */
export enum CURRENT_CONTENT_ACTIONS {
  ACTION_NEW = 'new',
  ACTION_EDIT = 'edit',
  ACTION_NONE = 'none',
  ACTION_LOAD = 'load',
  ACTION_CHANGE_TYPE = 'change_type'
}

export enum PAGES {
  NONE = 0,
  SETTINGS = 1,
  DATE = 2,
  REGISTRATION = 3,
  INTERACTION = 4,
  MAILS = 5,
  DASHBOARD = 6,
  SECTIONS = 7
}

export enum COUNTDOWN_TIMER {
  NO = 'countdown.timer.no',
  INVISIBLE = 'countdown.timer.invisible',
  TEXT = 'countdown.timer.text',
  VISUAL = 'countdown.timer.visual',
}

export enum CLOCK_TICKING {
  FULL = 0,
  S30 = 30000,
  MIN1 = 60000,
  MIN2 = 120000,
  MIN5 = 300000,
  NO_CLOCK_TICKING = -1
}

export enum BEFORE_CHANGE_TICKING {
  NO = 0,
  SEC5 = 5000,
  SEC10 = 10000,
  SEC20 = 20000,
  SEC30 = 30000,
}

export enum AUTOPILOT_SAMPLE {
  GONG = '/assets/sound/gong.mp3',
  TICK = '/assets/sound/clock_ticking_1s.mp3',
  BEFORE_COUNTDOWN = '/assets/sound/inflicted.mp3'
}

export enum AUTOPILOT_PRESTART_TIME {
  OFF = 0,
  MIN2 = 120000,
  MIN5 = 300000,
  MIN15 = 900000
}

export enum REPORT_BUG_TYPE {
  REPORT_BUG = 'report.bug',
  REQUEST_FEATURE = 'request.feature',
  GET_SUPPORT = 'get.support'
}

export enum DEPENDENCY {
  NONE = 0,
  SHOW_IF = 1,
  HIDE_IF = 2
}

export enum UNION_BY {
  AND = 0,
  OR = 1
}

export enum EVENT_REGISTRATION_MODE {
  NONE = 0,
  REGISTRATION = 1,
  ONLY_SECTION_REGISTRATION = 2
}

export enum BANNER_TYPE {
  VERTICAL = 'verticalBanner',
  HORIZONTAL = 'horizontalBanner',
  BACKGROUND = 'background',
  MOBILE_BACKGROUND = 'mobileBackground'
}

export enum LINK_STATE {
  NEED_UPLOAD = 1,
  UPLOADED = 2,
  DELETED = -1,
}

export enum USER_TASK_STATUS {
  NEW = 'user.task.status.new',
  IN_PROGRESS = 'user.task.status.in.progress', // set when press start button
  DONE = 'user.task.status.done', // set when press submit button and answers set is readonly
  FEEDBACK = 'user.task.status.feedback', // status can set only speaker
  APPROVED = 'user.task.status.approved', // status can set only speaker
}

export enum USER_TASK_FIELDS {
  FEEDBACK = 'feedback',
  USER_STATUS = 'userStatus',
  POINTS = 'points',
  GRADE = 'grade'
}

export enum MEETING_STATE {
  PLAY = 'play',
  STOP = 'stop'
}

export enum MEETING_VIEW_MODE {
  MINIMIZED = -1,
  INTERNAL = 1, // small
  CENTER = 2, // medium
  FULL_SCREEN = 3, // big
  FREE = 4, // right
  HIDDEN = -2,
  SMALL = 6,
  BREAKOUT_ROOMS = 8,
  CONFERENCE_GRID = 9,
  LIVE_STREAMING = 10
}

export enum VIRTUAL_CONFERENCE_EVENT {
  NO_CONFERENCE = 'no.conference',
  INTEGRATED_LIVE_STREAMING = 'integrated.live.streaming',
  INTEGRATED_LIVE_STREAMING_THIRD_PARTY = 'integrated.live.streaming.third.party',
  INTEGRATED_LIVE_CONFERENCE = 'integrated.live.conference',
  EXTERNAL_VIDEO_STREAMING = 'external.video.streaming',
  RECORDED_VIDEO_STREAMING = 'recorded.video.streaming'
}

/**
 * Used for VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE
 * @param ZOOM - deprecated
 * @param DAILY_CO
 * @param JITSI - deprecated
 * @param INTEGRATED_LIVE_STREAMING - possible deprecated option
 * @param EXTERNAL_LIVE_STREAMING - possible deprecated option
 * @param RECORDED_STREAMING - possible deprecated option
 */
export enum VIRTUAL_CONFERENCE_TYPE {
  ZOOM = 'zoom',
  DAILY_CO = 'daily_co',
  JITSI = 'jitsi',
  INTEGRATED_LIVE_STREAMING = 'integrated_live_streaming',
  EXTERNAL_LIVE_STREAMING = 'external_live_streaming',
  RECORDED_STREAMING = 'recorded_streaming',
  INTEGRATED_LIVE_CONFERENCE = 'integrated_live_conference',
}

export enum FOLLOW_ME_MODE {
  DONT_FOLLOW = 'follow.me.mode.dont.follow',
  FOLLOW_ME = 'follow.me.mode.follow.me',
  FOLLOW_OTHER = 'follow.me.mode.over'
}

export enum FOLLOW_ME_ACTION_TYPE {
  IMAGE_INDEX = 'imageIndex',
  PAGE_NUMBER = 'pageNumber',
  QUESTION_ID = 'questionId',
  SECTION_ID = 'sectionId',
  CONTENT_ID = 'contentId',
  CONTAINER_DATA_CHANGE = 'container_data_change',
  CAMERA_POSITION = 'camera_position',
  ZOOM_POSITION = 'zoom_position',
  MEETING_SPEAKER = 'meeting_speaker'
}

export enum HTML_VIEW_MODE {
  DEFAULT = '1/1',
  VM_50_50 = '50/50',
  VM_1_3 = '1/3',
  VM_2_3 = '2/3'
}

export enum REMINDER_TYPE {
  INDEPENDENT_STATUS = 1,
  REGISTERED_TO_MAIN_EVENT = 2,
  REGISTERED_TO_THIS_SECTION = 3
}

// Autopilot status control constants
export enum BEFORE_RUNNING {
  NO_ACTION = 'no_action',
  SET_STATUS_OPEN = 'open'
}

// Autopilot status control constants
export enum AFTER_RUNNING {
  NO_ACTION = 'no_action',
  SET_STATUS_HIDDEN = 'draft',
  SET_STATUS_PLANNED = 'planned'
}

export enum SHAPE_3D_WORD_CLOUD {
  SPHERE = 'sphere',
  HORIZONTAL_CYLINDER = 'vcylinder',
  VERTICAL_CYLINDER = 'hcynlinder',
  HORIZONTAL_RING = 'vring(0.5)',
  VERTICAL_RING = 'hring'
}

export interface I3DWordCloudOptions {
  noSelect?: boolean;
  noMouse?: boolean;
  textColour?: string;
  initial: number[];
  noTagsMessage?: boolean;
  shape: SHAPE_3D_WORD_CLOUD;
  direction: DIRECTION;
  textHeight?: number;
  lock?: null | 'x' | 'y' | 'xy';
  outlineColour?: string;
  outlineRadius?: number;
  outlineThickness?: number;
}

export enum DIRECTION {
  MOVE_UP = 'move_up',
  MOVE_DOWN = 'move_down',
  MOVE_LEFT = 'move_left',
  MOVE_RIGHT = 'move_right',
  MOVE_LEFT_TO_RIGHT = 'move_left_to_right',
}

export enum REGISTRATION_ACTION {
  REGISTER = 'register',
  UNREGISTER = 'unregister',
  DELETE_UNREGISTER = 'delete.unregister'
}

export enum YOUTUBE_PLAYER_STATE {
  NOT_STARTED = -1,
  FINISHED = 0,
  PLAY = 1,
  PAUSE = 2,
  BUFFERING = 3,
  IN_QUEUE = 5
}

export enum KALTURA_PLAYER_STATE {
  UNINITIALIZED = 'uninitialized',
  LOADING = 'loading',
  READY = 'ready',
  PLAYING = 'playing',
  PAUSED = 'paused',
  BUFFERING = 'buffering',
  PLAYBACK_ERROR = 'playbackError'
}

export enum VIDEO_SITE_RECORDED_STREAMING_TYPES {
  YOUTUBE = 'youtube',
  KALTURA = 'kaltura',
  ROCHE = 'roche'
}

export enum FOLLOW_ME_USER_PARAMS {
  DONT_FOLLOW_ANYBODY = 'dont.follow.anybody',
  FOLLOW_AS_PRESENTER = 'follow.as.presenter',
  FOLLOW_AS_PARTICIPANT = 'follow.as.participant'
}

export enum FOLLOW_ME_COMMAND {
  ASK_FEEDBACK_RATING = 'askFeedbackRating',
  CLOSE_FEEDBACK_WINDOW = 'closeFeedbackWindow'
}

export enum INFO_REGISTRATION_ELEMENTS {
  SECTION_TYPE_ID = 'sectionTypeId',
  LOCATION = 'location',
  COUNT_QUESTIONS = 'countQuestions',
  PLANNED_TIME = 'plannedTime'
}

export enum INFO_REGISTRATION_ELEMENTS_ORDER {
  'sectionTypeId' = 1,
  'location' = 2,
  'countQuestions' = 3,
  'plannedTime' = 4
}

export enum REGISTRATION_ELEMENTS_ALIGN_LEN {
  ADD_TO_ALIGN_LEN = 300,
  DELETE_FROM_ALIGN_LEN = 310
}

export interface ITypeChangeEvent {
  id?: number;
  contentType?: string;
  moduleType?: string;
}

export enum MEETING_SETTING_QUESTION1 {
  YES = 'answer.yes',
  NO = 'answer.no',
}

export enum MEETING_SETTING_QUESTION2 {
  YES = 'answer.yes',
  NO = 'answer.no',
}

export interface ISectionUserMeetingState {
  availability?: MEETING_SETTING_QUESTION1;
  iCanHear?: MEETING_SETTING_QUESTION2;
}

export interface IUserMediaDevicesState {
  microphoneAccess: boolean | null;
  cameraAccess: boolean | null;
}

export enum AVAILABLE_MEETING_VIEW_MODE {
  CONFERENCE_AND_ROOMS = 'conference.and.breakout.rooms',
  CONFERENCE_ONLY = 'conference.only',
  BREAKOUT_ROOMS_ONLY = 'breakout.rooms.only'
}

export enum ASSIGN_FREE_SLOT_MODE {
  REFERENCE = 'reference',
  DIALOG = 'dialog',
  MENU = 'menu'
}

export enum ASSIGN_TO_SLOT_MODE {
  BELOW = 'below',
  ABOVE = 'above',
  WITHIN = 'within'
}

export enum ADD_NEW_SLOT_MODE {
  BELOW = 'below',
  ABOVE = 'above',
  WITHIN = 'within'
}

export enum DIRECTION_COPY_CONTENT {
  TIMELINE_TO_MODULE = 'timeline_to_module',
  MODULE_TO_TIMELINE = 'module_to_timeline',
  TIMELINE_TO_MY_NOTES = 'timeline_to_my_notes',
  NO_CHANGE = 'no_change'
}

export enum SMILE_RATING {
  R10 = 'smile10',
  R20 = 'smile20',
  R30 = 'smile30',
  R40 = 'smile40',
  R50 = 'smile50',
  R60 = 'smile60',
  R70 = 'smile70',
  R_BAD = 'smile-cross',
  R_NORMAL = 'smile-wave',
  R_EXCELLENT = 'smile-check-mark',
  D00 = 'digit00',
  D01 = 'digit01',
  D02 = 'digit02',
  D03 = 'digit03',
  D04 = 'digit04',
  D05 = 'digit05',
  D06 = 'digit06',
  D07 = 'digit07',
  D08 = 'digit08',
  D09 = 'digit09',
  D10 = 'digit10',
  LETTER_A = 'letter_a',
  LETTER_B = 'letter_b',
  LETTER_C = 'letter_c',
  LETTER_D = 'letter_d',
  LETTER_E = 'letter_e',
  LETTER_F = 'letter_f',
  LETTER_G = 'letter_g',
  LETTER_H = 'letter_h',
  LETTER_I = 'letter_i',
  LETTER_J = 'letter_j',
  LETTER_K = 'letter_k',
}

export enum SMILES_RATING_SETS_NAMES {
  SET2 = 'SET2',
  SET3 = 'SET3',
  SET4 = 'SET4',
  SET5 = 'SET5',
  SET6 = 'SET6',
  SET7 = 'SET7',
  SET8 = 'SET8',
  SET9 = 'SET9',
  SET10 = 'SET10',
  SET11 = 'SET11',
  SET12 = 'SET12',
  SET13 = 'SET13',
  SET14 = 'SET14',
  SET15 = 'SET15',
  SET16 = 'SET16',
  SET17 = 'SET17',
  SET18 = 'SET18',
  SET19 = 'SET19',
  SET20 = 'SET20',
  SET21 = 'SET21',
  SET22 = 'SET22',
  SET23 = 'SET23',
  SET24 = 'SET24',
  SET25 = 'SET25',
  SET26 = 'SET26',
  SET27 = 'SET27',
  SET28 = 'SET28',
  SET29 = 'SET29'
}

export const SMILES_RATING_SETS = {
  [SMILES_RATING_SETS_NAMES.SET2]:
    [SMILE_RATING.R10, SMILE_RATING.R70],
  [SMILES_RATING_SETS_NAMES.SET3]:
    [SMILE_RATING.R10, SMILE_RATING.R40, SMILE_RATING.R70],
  [SMILES_RATING_SETS_NAMES.SET4]:
    [SMILE_RATING.R10, SMILE_RATING.R30, SMILE_RATING.R50, SMILE_RATING.R70],
  [SMILES_RATING_SETS_NAMES.SET5]:
    [SMILE_RATING.R10, SMILE_RATING.R30, SMILE_RATING.R40, SMILE_RATING.R50, SMILE_RATING.R70],
  [SMILES_RATING_SETS_NAMES.SET6]:
    [SMILE_RATING.R10, SMILE_RATING.R20, SMILE_RATING.R30, SMILE_RATING.R50, SMILE_RATING.R60, SMILE_RATING.R70],
  [SMILES_RATING_SETS_NAMES.SET7]:
    [SMILE_RATING.R10, SMILE_RATING.R20, SMILE_RATING.R30, SMILE_RATING.R40, SMILE_RATING.R50, SMILE_RATING.R60, SMILE_RATING.R70],
  [SMILES_RATING_SETS_NAMES.SET8]:
    [SMILE_RATING.R_BAD, SMILE_RATING.R_EXCELLENT],
  [SMILES_RATING_SETS_NAMES.SET9]:
    [SMILE_RATING.R_BAD, SMILE_RATING.R_NORMAL, SMILE_RATING.R_EXCELLENT],
  [SMILES_RATING_SETS_NAMES.SET10]:
    [SMILE_RATING.D00, SMILE_RATING.D01],
  [SMILES_RATING_SETS_NAMES.SET11]:
    [SMILE_RATING.D00, SMILE_RATING.D01, SMILE_RATING.D02],
  [SMILES_RATING_SETS_NAMES.SET12]:
    [SMILE_RATING.D00, SMILE_RATING.D01, SMILE_RATING.D02, SMILE_RATING.D03],
  [SMILES_RATING_SETS_NAMES.SET13]:
    [SMILE_RATING.D00, SMILE_RATING.D01, SMILE_RATING.D02, SMILE_RATING.D03, SMILE_RATING.D04],
  [SMILES_RATING_SETS_NAMES.SET14]:
    [SMILE_RATING.D00, SMILE_RATING.D01, SMILE_RATING.D02, SMILE_RATING.D03, SMILE_RATING.D04, SMILE_RATING.D05],
  [SMILES_RATING_SETS_NAMES.SET15]:
    [SMILE_RATING.D00, SMILE_RATING.D01, SMILE_RATING.D02, SMILE_RATING.D03, SMILE_RATING.D04, SMILE_RATING.D05, SMILE_RATING.D06],
  [SMILES_RATING_SETS_NAMES.SET16]:
    [SMILE_RATING.D00, SMILE_RATING.D01, SMILE_RATING.D02, SMILE_RATING.D03, SMILE_RATING.D04, SMILE_RATING.D05,
      SMILE_RATING.D06, SMILE_RATING.D07],
  [SMILES_RATING_SETS_NAMES.SET17]:
    [SMILE_RATING.D00, SMILE_RATING.D01, SMILE_RATING.D02, SMILE_RATING.D03, SMILE_RATING.D04, SMILE_RATING.D05,
      SMILE_RATING.D06, SMILE_RATING.D07, SMILE_RATING.D08],
  [SMILES_RATING_SETS_NAMES.SET18]:
    [SMILE_RATING.D00, SMILE_RATING.D01, SMILE_RATING.D02, SMILE_RATING.D03, SMILE_RATING.D04, SMILE_RATING.D05,
      SMILE_RATING.D06, SMILE_RATING.D07, SMILE_RATING.D08, SMILE_RATING.D09],
  [SMILES_RATING_SETS_NAMES.SET19]:
    [SMILE_RATING.D00, SMILE_RATING.D01, SMILE_RATING.D02, SMILE_RATING.D03, SMILE_RATING.D04, SMILE_RATING.D05,
      SMILE_RATING.D06, SMILE_RATING.D07, SMILE_RATING.D08, SMILE_RATING.D09, SMILE_RATING.D10],
  [SMILES_RATING_SETS_NAMES.SET20]:
    [SMILE_RATING.LETTER_A, SMILE_RATING.LETTER_B],
  [SMILES_RATING_SETS_NAMES.SET21]:
    [SMILE_RATING.LETTER_A, SMILE_RATING.LETTER_B, SMILE_RATING.LETTER_C],
  [SMILES_RATING_SETS_NAMES.SET22]:
    [SMILE_RATING.LETTER_A, SMILE_RATING.LETTER_B, SMILE_RATING.LETTER_C, SMILE_RATING.LETTER_D],
  [SMILES_RATING_SETS_NAMES.SET23]:
    [SMILE_RATING.LETTER_A, SMILE_RATING.LETTER_B, SMILE_RATING.LETTER_C, SMILE_RATING.LETTER_D, SMILE_RATING.LETTER_E],
  [SMILES_RATING_SETS_NAMES.SET24]:
    [SMILE_RATING.LETTER_A, SMILE_RATING.LETTER_B, SMILE_RATING.LETTER_C, SMILE_RATING.LETTER_D, SMILE_RATING.LETTER_E,
      SMILE_RATING.LETTER_F],
  [SMILES_RATING_SETS_NAMES.SET25]:
    [SMILE_RATING.LETTER_A, SMILE_RATING.LETTER_B, SMILE_RATING.LETTER_C, SMILE_RATING.LETTER_D, SMILE_RATING.LETTER_E,
      SMILE_RATING.LETTER_F, SMILE_RATING.LETTER_G],
  [SMILES_RATING_SETS_NAMES.SET26]:
    [SMILE_RATING.LETTER_A, SMILE_RATING.LETTER_B, SMILE_RATING.LETTER_C, SMILE_RATING.LETTER_D, SMILE_RATING.LETTER_E,
      SMILE_RATING.LETTER_F, SMILE_RATING.LETTER_G, SMILE_RATING.LETTER_H],
  [SMILES_RATING_SETS_NAMES.SET27]:
    [SMILE_RATING.LETTER_A, SMILE_RATING.LETTER_B, SMILE_RATING.LETTER_C, SMILE_RATING.LETTER_D, SMILE_RATING.LETTER_E,
      SMILE_RATING.LETTER_F, SMILE_RATING.LETTER_G, SMILE_RATING.LETTER_H, SMILE_RATING.LETTER_I],
  [SMILES_RATING_SETS_NAMES.SET28]:
    [SMILE_RATING.LETTER_A, SMILE_RATING.LETTER_B, SMILE_RATING.LETTER_C, SMILE_RATING.LETTER_D, SMILE_RATING.LETTER_E,
      SMILE_RATING.LETTER_F, SMILE_RATING.LETTER_G, SMILE_RATING.LETTER_H, SMILE_RATING.LETTER_I, SMILE_RATING.LETTER_J],
  [SMILES_RATING_SETS_NAMES.SET29]:
    [SMILE_RATING.LETTER_A, SMILE_RATING.LETTER_B, SMILE_RATING.LETTER_C, SMILE_RATING.LETTER_D, SMILE_RATING.LETTER_E,
      SMILE_RATING.LETTER_F, SMILE_RATING.LETTER_G, SMILE_RATING.LETTER_H,
      SMILE_RATING.LETTER_I, SMILE_RATING.LETTER_J, SMILE_RATING.LETTER_K]
};

export enum LOAD_STATE {
  NOT_LOADED = 'not_loaded',
  LOADING = 'loading',
  LOADED = 'loaded'
}

export interface ILoadData<T> {
  loaded: LOAD_STATE;
  value: T;
}

export enum LESSON_DURATION {
  M30 = 30,
  M45 = 45,
  M50 = 50,
  M60 = 60
}

export enum ENTITY_LINK_TYPE {
  MODULE = 'module',
  CURRICULUM = 'curriculum',
  COMPETENCY = 'competency',
  SECTION = 'section',
  CONTENT = 'content'
}

export interface IEventEntityLink {
  entityId: string; // moduleId | curriculumId
  entityType: ENTITY_LINK_TYPE;
  params?: ICurriculumModulesQueryParams;
  straightawayTimeline?: boolean;
}

export interface IReleaseVersion {
  version: string;
  date: number;
  archive?: boolean;
}

export enum TEXT_MARK_TAGS {
  HOTSPOT = 'hotspot',
}

export enum ATTR_IS_PLAYING {
  AUDIO = 'audio_is_playing',
  VIDEO = 'video_is_playing'
}

export enum ANNOTATION_TYPE {
  TEXT = 'text',
  VIDEO = 'video'
}

export const MEDIA_SPEED: number[] = [0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2];
export const YT_MEDIA_SPEED: number[] = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

export enum MEDIA_LOOP {
  LOOP_ONE = 'loopOne', // loops the current animation
  ONE = 'one' // plays the current animation and stops
}

export interface IHotspotElementData {
  element_id?: string;
  audio_id?: string;
  order_number?: string;
  container_id?: string;
  text_annotation_id?: string;
  video_annotation_id?: string;
  video_options_loop?: string;
  video_options_speed?: string;
  video_options_start_time?: string;
  video_options_end_time?: string;
  audio_options_loop?: string;
  audio_options_speed?: string;
  audio_options_start_time?: string;
  audio_options_end_time?: string;
}

export enum HOTSPOT_DATA_ATTRIBUTES {
  ELEMENT_ID = 'element_id',
  AUDIO_ID = 'audio_id',
  ORDER_NUMBER = 'order_number',
  CONTAINER_ID = 'container_id',
  TEXT_ANNOTATION_ID = 'text_annotation_id',
  VIDEO_ANNOTATION_ID = 'video_annotation_id',
  VIDEO_OPTIONS_LOOP = 'video_options_loop',
  VIDEO_OPTIONS_SPEED = 'video_options_speed',
  VIDEO_OPTIONS_START_TIME = 'video_options_start_time',
  VIDEO_OPTIONS_END_TIME = 'video_options_end_time',
  AUDIO_OPTIONS_LOOP = 'audio_options_loop',
  AUDIO_OPTIONS_SPEED = 'audio_options_speed',
  AUDIO_OPTIONS_START_TIME = 'audio_options_start_time',
  AUDIO_OPTIONS_END_TIME = 'audio_options_end_time'
}

export enum TEMPLATE_TYPE {
  PUBLISHED_COMPANY = 'published.company',
  USER_TEMPLATE = 'user.template'
}

export enum DIFFICULTY_LEVEL {
  VERY_EASY = 'very_easy',
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard'
}

export enum TIMELINE_MODE {
  TIMELINE = 'timeline-mode',
  AVAILABLE_CONTENTS = 'available-contents-mode'
}

export enum CALENDAR_RANGE {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export interface IStatisticsValue {
  caption: string;
  value: number;
}

export interface ISimpleTreeItem {
  id: string;
  parentId: string;
}
