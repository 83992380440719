import {ServiceTasksComponent} from '../service-tasks.component';
import {QueryFn} from '@angular/fire/compat/firestore';
import {Constants} from '../../../core/constants';
import {firstValueFrom} from 'rxjs';
import {CONTAINER_ITEM_TYPE, ContentContainer} from '../../../model/content/ContentContainer';
import {Quiz} from '../../../modules/content-container/components/quiz/quiz-model/quiz';
import {EventQuestion} from '../../../model/EventQuestion';
import {isEmpty} from 'lodash';

export const informationAboutEventsWithQuizContainsQuestionsTypeFileUpload = async (thiz: ServiceTasksComponent) => {
  const request = (collection: string, size: number, startAt) => {
    let queryFn: QueryFn = q => q.where('itemsTypes', 'array-contains', CONTAINER_ITEM_TYPE.QUIZ).limit(size);
    if (startAt) {
      queryFn = q => q.where('itemsTypes', 'array-contains', CONTAINER_ITEM_TYPE.QUIZ).limit(size).startAfter(startAt);
    }
    return thiz.afs.collectionGroup(collection, queryFn).get();
  };

  if (!await thiz.commonService.confirmation(
    'Run: Information about events with quiz contains questions of type "FileUpload"')) {
    return;
  }

  console.log('Start process');
  const taskLog = {};
  const chunkSize = 500;

  console.log('query contents');
  let contentsCount = 0;
  let contents_ = await firstValueFrom(request('contents', chunkSize, null));
  while (contents_.docs.length > 0) {
    contentsCount += contents_.docs.length;
    console.log('loaded', contentsCount);
    for (const doc of contents_.docs) {
      const cc = doc.data() as ContentContainer;
      if (cc.hasOwnProperty('items')) {
        for (let i = 0; i < cc.items.length; i++) {
          const item = cc.items[i];
          if (item.type !== CONTAINER_ITEM_TYPE.QUIZ) {
            continue;
          }
          const quiz = new Quiz(item.data);
          const questions = Object.values(quiz.questions) as EventQuestion[];
          for (const question of questions) {
            if (question.storypoint === Constants.QTYPE_FILE_UPLOAD) {
              if (isEmpty(taskLog[cc.eventId])) {
                taskLog[cc.eventId] = [];
              }
              console.log('info: ', doc.ref.path);
              taskLog[cc.eventId].push({content_path: doc.ref.path});
            }
          }
        }
      }
    }
    contents_ = await firstValueFrom(request('contents', chunkSize, contents_.docs[contents_.docs.length - 1]));
  }
  console.log('~~~~~~~~ task result ~~~~~~~~');
  console.log(taskLog);
};
