import {ServiceTasksComponent} from '../service-tasks.component';
import {isEmpty, merge} from 'lodash';
import {firstValueFrom} from 'rxjs';
import {UPLOAD_TYPE} from '../../../core/upload.service';

const getLocationCollection = (location: 'timeline' | 'modules') => {
  switch (location) {
    case 'timeline':
      return 'conference';
    case 'modules':
      return 'modules';
  }
};

export const jsonExport = async (thiz: ServiceTasksComponent, location: 'timeline' | 'modules',  eventId: string) => {
  const exportToJSON = async (clientId: string) => {
    const sectionsDocs = await firstValueFrom(thiz.afs.collection('client_data').doc(clientId)
      .collection(getLocationCollection(location))
      .doc(eventId).collection('timeline').get()).then(snap => snap.docs);
    const sectionsObject = sectionsDocs.filter(o => !!o.data()['type'])
      .reduce((acc, doc, index) => {
        console.log(`export ${index + 1} of ${sectionsDocs.length}`);
        return merge(acc, {[doc.id]: doc.data()});
      }, {});
    thiz.utils.downloadCsvReport(JSON.stringify(sectionsObject), `${location}-${eventId}.json`, 'application/json;encoding:utf-8');
  };

  let clientIds: string[];
  if (isEmpty(clientIds = await thiz.confirmationBeforeRun('Run: Export to JSON.', thiz.prevChoice))) {
    return;
  }
  for (const clientId of clientIds) {
    console.log(`Client ${clientId} started`);
    await exportToJSON(clientId);
    console.log(`Client ${clientId} finished`);
  }
  console.log('End process.');
};

export const jsonImport = async (thiz: ServiceTasksComponent, location: 'timeline' | 'modules',  eventId: string) => {

  const importFromJSON = async (clientId: string, json: string) => {
    const sections = JSON.parse(json);
    const count = Object.keys(sections).length;
    let counter = 0;
    for (const sectionId of Object.keys(sections)) {
      console.log(`added ${++counter} of ${count}`);
      await thiz.afs.collection('client_data').doc(clientId)
        .collection(getLocationCollection(location))
        .doc(eventId).collection('timeline')
        .doc(sectionId).set(sections[sectionId], {merge: false});
    }
  };

  const afterUpload = async (json: string) => {
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await importFromJSON(clientId, json);
      console.log(`Client ${clientId} finished`);
    }
    console.log('End process.');
  };

  let clientIds: string[];
  if (isEmpty(clientIds = await thiz.confirmationBeforeRun('Run: Import from JSON', thiz.prevChoice)) || clientIds.length > 1) {
    return;
  }
  if (!(await thiz.commonService.confirmation(
    `<div style="color: red; font-weight: bold;">All data will be deleted and overwritten with new values!!!</div>
     <div>Are you sure you want to create an import?</div>`))) {
    return;
  }
  thiz.uppy = thiz.uploadService.createFileUploader(UPLOAD_TYPE.CUSTOM,
    {restrictions: {maxNumberOfFiles: 1, allowedFileTypes: ['.json']}},
    (result) => {
      let json: string = result[0].response;
      json = atob(json.substring(json.indexOf(';base64,') + ';base64,'.length));
      thiz.uploadService.closeModal(thiz.uppy);
      afterUpload(json);
    });
  thiz.uploadService.openUploadWindow(thiz.uppy);
};
